import useUploadFileManager from "../../../../constants/controller_templates/post_upload_file_controller";

export const UploadStockManager = () => {
  const { uploadCaller, data, isLoading, isSuccess, error } = useUploadFileManager(
    '/stock/uploads/',
    ["stocks"]
  );

  const uploadStock = async (file) => {
    try {
      await uploadCaller(file);
    } catch (error) {
      console.error("error:", error);
    }
  };

  return {
    uploadStock,
    data,
    isLoading,
    isSuccess,
    error,
  };
};
