import useUploadFileManager from "../../../../constants/controller_templates/post_upload_file_controller";

export const UploadDisposalManager = () => {
  const { uploadCaller, data, isLoading, isSuccess, error } = useUploadFileManager(
    '/stock/disposals/uploads/',
    ["disposals"]
  );

  const uploadDisposal = async (file) => {
    try {
      await uploadCaller(file);
    } catch (error) {
      console.error("error:", error);
    }
  };

  return {
    uploadDisposal,
    data,
    isLoading,
    isSuccess,
    error,
  };
};
