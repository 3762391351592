import useDeleteManager from "../../../constants/controller_templates/delete_controller_template";

export const DeleteAuctionManager = ({ id }) => {
  const { deleteCaller, isLoading, isSuccess, error, data } = useDeleteManager(
    `/auctions/${id}/`,
    ["auction"]
  );
  const deleteAuction = async () => {
    try {
      await deleteCaller();
    } catch (error) {
      console.error("error:", error);
    }
  };
  return {
    deleteAuction,
    data,
    isLoading,
    isSuccess,
    error,
  };
};
