const getStoredPermissions = () => {
  const storedPermissions = localStorage.getItem("userPermissions");
  if (!storedPermissions) return [];
  try {
    const parsedPermissions = JSON.parse(storedPermissions);
    // Flatten all permissions from all modules into a single array of codenames
    return parsedPermissions.reduce((allPermissions, module) => {
      const codenames = module.permissions.map((p) => p.codename);
      return [...allPermissions, ...codenames];
    }, []);
  } catch {
    return [];
  }
};
export const hasPermissions = (requiredPermissions) => {
  if (!requiredPermissions || requiredPermissions.length === 0) {
    // Allow unrestricted access if no permissions are required
    return true;
  }
  const userPermissions = getStoredPermissions();
  const result = requiredPermissions.some((permission) =>
    userPermissions.includes(permission)
  );
  return result;
};
