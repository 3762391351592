import { useQuery } from "react-query";
import AxiosWithToken from "../../../constants/api_management/MyHttpHelperWithToken";

const useGetWarehouseManager = ({
  page = "",
  searchQuery = "",
  enabled = true,
  pageSize = "15",
}) => {
  return useQuery(["warehouses", page, searchQuery], async () => {
    try {
      const [response] = [await AxiosWithToken.get(`/clients/warehouses/?page=${page}&size=${pageSize}${searchQuery}`)];
      //console.log(`i am checking this ${response.status}`);
      return response.data;
    } catch (error) {
      //console.log(error.response.data);
      throw new Error(`Sorry: ${error.response.data.message}`);
    }
  });
};

export default useGetWarehouseManager;
