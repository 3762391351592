import useDeleteManager from "../../../../constants/controller_templates/delete_controller_template";

export const DeleteStockItemManager = ({ id }) => {
  const { deleteCaller, isLoading, isSuccess, error, data } = useDeleteManager(
    `/stocks/${id}`,
    ["stocks"]
  );
  const deleteStockItem = async () => {
    try {
      await deleteCaller();
    } catch (error) {
      console.error("error:", error);
    }
  };
  return {
    deleteStockItem,
    data,
    isLoading,
    isSuccess,
    error,
  };
};
