import React from "react";
import BaseDashboardNavigation from "../../../../generalComponents/BaseDashboardNavigation";

import { useLocation, useParams } from "react-router-dom";
import { getLastPathSegment } from "../../../../utils/getLastPath";
import Breadcrumb from "../../../../generalComponents/BreadCrumb";
import { noImage } from "../../../../assets/images";
import AssetDetailsSection from "./AssetDetailsSection";
import Tabs from "../../../../generalComponents/Tabs";
import useGetDisposalItemByIdManager from "../controllers/getDisposalItemByIdController";

const DisposalItemDetailsComponent = () => {
  const { id } = useParams();
  const location = useLocation();
  const lastSegment = getLastPathSegment(location.pathname);

  const { data: itemDetails } = useGetDisposalItemByIdManager(id);

  const item = itemDetails?.data?.stock;
  const disposal = itemDetails?.data;

  const items = [
    { name: "Disposal", path: "/disposal/" },
    {
      name: item?.asset_name,
      path: `/disposal/${lastSegment}`,
    },
  ];

  const assetDetailsData = [
    { label: "Asset Code", value: disposal?.asset_code},
    { label: "Material", value: disposal?.material},
    { label: "Quantity", value: disposal?.quantity},
    { label: "Unit", value: item?.unit},
    { label: "Unit Cost Naira", value: item?.unit_cost_naira},
    { label: "Unit Cost USD", value: item?.unit_cost_dollar},
    { label: "Manufacturer", value: item?.manufacturer},
    { label: "Warehouse", value: item?.warehouse},
    { label: "Storage Location", value: item?.storage_location},
    { label: "Row", value: item?.row},
    { label: "Slot", value: item?.slot},
    { label: "Dimension", value: item?.dimension},
    { label: "Component", value: item?.component},
    { label: "Classification", value: item?.classification},
    { label: "Connection Type", value: item?.connection_type},
    { label: "Capacity", value: item?.capacity},
    { label: "ERP Number", value: item?.erp_number},
    { label: "Serial Number", value: item?.serial_number},
    { label: "Model Number", value: item?.model_number},
    { label: "Bin Number", value: item?.bin_number},
    { label: "Bactch Number", value: item?.batch_number},
    { label: "Total Quantity", value: disposal?.total_quantity},
    { label: "Operator Quantity", value: disposal?.operator_quantity},
    { label: "NUIMS Quantity", value: disposal?.nuims_quantity},
    { label: "Asset Class", value: disposal?.asset_class},
    { label: "Asset Type", value: disposal?.asset_type},
    { label: "Ownership", value: disposal?.ownership},
    { label: "Original Purchase Cost", value: disposal?.original_purchase_cost},
    { label: "Book Value", value: disposal?.book_value},
    { label: "Tax Stated Value", value: disposal?.tax_stated_value},
    { label: "Material Condition", value: disposal?.material_condition},
    { label: "Disposal Reason", value: disposal?.disposal_reason},
    { label: "Disposal Method", value: disposal?.disposal_method},
    { label: "Declaration Type", value: disposal?.declaration_type},
    { label: "Estimated Salvage Value", value: disposal?.estimated_sale_value},
    { label: "Purchase Date", value: disposal?.purchase_date},
  ];

  const tabsData = [
    {
      label: "Asset Details",
      component: <AssetDetailsSection data={assetDetailsData} />,
    },
  ];

  return (
    <BaseDashboardNavigation>
      <div className="md:mt-4 flex flex-col md:flex-row mt-2 justify-between gap-y-5">
        <div>
          <Breadcrumb items={items} />
        </div>
      </div>
      <div className="">
        <div className="flex md:flex-row flex-col relative items-center mb-4 gap-5 w-full h-[450px] mt-5">
          {/* Left Card with Image */}
          <div className="flex flex-[0.5] items-center bg-white p-2 rounded-lg md:w-full h-full">
            <img
              src={item?.image || noImage}
              className=" overflow-hidden rounded-lg shadow-md w-full h-full object-cover"
              alt="Item"
            />
          </div>

          {/* Right Card */}
          <div className="flex flex-[0.5] flex-col justify-center  gap-4 h-full w-full  bg-white rounded-lg border border-gray-200 p-6">
            <div className="flex flex-col gap-2 py-2 items-start border-b border-gray-200">
              <h1 className="text-20px md:text-[28px] font-semibold my-2 leading-[20px]">
                {item?.asset_name}
              </h1>
              <p className="text-gray-500 text-sm">{item?.description}</p>
            </div>
            <div className="w-full flex flex-col gap-3 items-start border-b border-gray-200">
              <div className="flex flex-col my-1.5">
                <p className="text-gray-500 text-sm">Operator</p>
                <h2 className="text-16px md:text-[24px] font-semibold ">OLA</h2>
              </div>
              <div className="flex flex-col my-1.5">
                <p className="text-gray-500 text-sm">Storage Location</p>
                <h2 className="text-16px md:text-[24px] font-semibold">
                  {item?.storage_location || "N/A"}
                </h2>
              </div>
            </div>
          </div>
        </div>

        <Tabs tabsData={tabsData} />
      </div>
    </BaseDashboardNavigation>
  );
};

export default DisposalItemDetailsComponent;
