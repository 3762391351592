import useFormDataPostManager from "../../../../constants/controller_templates/post_formdata_controller_template";

export const CreateStockItemManager = () => {
  const { postCaller, isLoading, isSuccess, error, data } = useFormDataPostManager(
    "/stocks/",
    ["stocks"],
    true
  );
  
  const createStockItem = async (details) => {
    try {
      await postCaller(details);
    } catch (error) {
      console.error("error:", error);
    }
  };
  
  return {
    createStockItem,
    data,
    isLoading,
    isSuccess,
    error,
  };
};