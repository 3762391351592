import React, { useState } from "react";
import ButtonWithIcon from "../../../../generalComponents/ButtonWithIcon";
import TablesComponent from "../../../../generalComponents/TablesComponent";
import PaginationRounded from "../../../../generalComponents/Pagination";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import useDebounce from "../../../../utils/UseDebounce";
import { noImage } from "../../../../assets/images";
import SearchField from "../../../../generalComponents/SearchField";
import { calculatePaginationRange } from "../../../../utils/calculatePaginationRange";
import { DeclareDisposalManager } from "../controllers/declareDisposalController";
import StatusButton from "../../../../generalComponents/StatusButton";
import EditRequestIdModal from "../components/EditRequestIdModal";
import ScheduleInspection from "../components/DisposalInspectionModal";
import LetterUploadModal from "../components/LetterUploadModal";
import { UpdateQuantityManager } from "../controllers/updateQuantityController";
import useGetDisposalsManager from "../../disposalUploads/controllers/getDisposalsController";
import Dropdown from "../../../../generalComponents/Dropdown";
import { ApproveDisposalManager } from "../controllers/approveDisposalController";
import { RejectDisposalManager } from "../controllers/rejectDisposalController";
import { hasPermissions } from "../../../../constants/permissions";
import truncateWithTooltip from "../../../../utils/truncateText";

const ItemsByRequestId = ({ requestLetter, requestStatus }) => {
  const { request_id } = useParams();
  const [currentPage, setCurrentPage] = useState(1);
  const navigate = useNavigate();
  const location = useLocation();
  const itemId = location.state?.itemId;
  const [activeLetterType, setActiveLetterType] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [editedRow, setEditedRow] = useState(null);
  const [newOperatorQuantity, setNewOperatorQuantity] = useState(null);
  const [newNuimsQuantity, setNewNuimsQuantity] = useState(null);
  const [totalQuantity, setTotalQuantity] = useState(null);
  const debouncedSearchValue = useDebounce(`&search=${searchValue}`, 1000);

  const { data, isLoading } = useGetDisposalsManager({
    request_id: request_id,
    enabled: true,
    searchQuery: debouncedSearchValue,
    page: currentPage,
  });

  const { startItem, endItem } = calculatePaginationRange(
    data?.data?.pagination
  );

  const { declareDisposal } = DeclareDisposalManager(itemId);

  const { updateQuantity } = UpdateQuantityManager({ id: editedRow });

  const handleDeclaration = () => {
    declareDisposal({ id: itemId });
  };

  const headers = [
    "Image",
    "Asset Name",
    "Asset Code",
    // "Material",
    "NUIMS Quantity",
    "Operator Quantity",
    "Total Quantity",
    "Request Status",
    "Approval Status",
  ];

  const handleQuantityChange = (e, totalQuantity) => {
    const operatorValue = parseInt(e.target.value, 10) || 0;
    // Calculate new NUIMS quantity
    const newNuimsValue = totalQuantity - operatorValue;

    // Ensure the new NUIMS quantity is not negative
    if (newNuimsValue >= 0) {
      setNewOperatorQuantity(operatorValue);
      setNewNuimsQuantity(newNuimsValue);
    }
  };

  const handleQuantitySubmit = () => {
    const payload = {
      stock_item: editedRow,
      operator_quantity: newOperatorQuantity,
      total_quantity: totalQuantity,
    };
    updateQuantity(payload);
  };

  const getFormattedValue = (el, index) => {
    return [
      <span className="w-14 rounded-full">
        <img
          src={el?.stock?.image || noImage}
          className="rounded-full w-12 h-12"
          alt={el?.stock?.asset_name}
        />
      </span>,
      truncateWithTooltip(el?.stock?.asset_name),
      el?.stock?.asset_code,
      // el?.stock?.material,
      editedRow === el.id ? (
        <span>{newNuimsQuantity}</span>
      ) : (
        el?.nuims_quantity
      ),
      editedRow === el.id ? (
        <input
          type="number"
          value={newOperatorQuantity}
          onChange={(e) =>
            handleQuantityChange(e, el.total_quantity, el.nuims_quantity)
          }
          onBlur={() => handleQuantitySubmit(el?.id)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleQuantitySubmit(el?.id);
            }
          }}
          className="border p-2 rounded"
          autoFocus
        />
      ) : (
        <span
          onClick={() => {
            setEditedRow(el?.id);
            setNewOperatorQuantity(el?.operator_quantity);
            setNewNuimsQuantity(el?.nuims_quantity);
            setTotalQuantity(el?.total_quantity);
          }}
          className="cursor-pointer hover:border hover:border-gray-400 hover:rounded-md p-2"
        >
          {el?.operator_quantity}
        </span>
      ),
      el?.total_quantity,
      <StatusButton status={requestStatus} />,
      <StatusButton status={el?.status} />,
    ];
  };

  const { approveDisposal } = ApproveDisposalManager(itemId);
  const { rejectDisposal } = RejectDisposalManager(itemId);

  const handleApproval = () => {
    const payload = {
      items: selectedItems,
    };
    approveDisposal(payload);
  };

  const handleDeclineRequest = () => rejectDisposal();

  const dropdownOptions = [
    "Approve Selected Assets",
    "Decline Selected Assets",
  ];

  const handleDropdownChange = (event) => {
    const selectedOption = event.target.value;

    switch (selectedOption) {
      case "Approve Selected Assets":
        handleApproval();
        break;
      case "Decline Selected Assets":
        handleDeclineRequest();
        break;
      default:
        break;
    }
  };

  const toggleRowFunction = (_, val) => {
    const itemId = val?.id;
    setSelectedItems((prevSelectedItems) => {
      if (prevSelectedItems.includes(itemId)) {
        return prevSelectedItems.filter((selectedId) => selectedId !== itemId);
      } else {
        return [...prevSelectedItems, itemId];
      }
    });
  };

  return (
    <div>
      <div className="w-full flex flex-col gap-5"></div>
      <div className="mt-2 md:mt-6 flex flex-col w-full gap-4">
        <div className="flex flex-col md:flex-row justify-between items-center">
          <div className="">
            <SearchField
              onChange={(e) => setSearchValue(e.target.value)}
              value={searchValue}
            />{" "}
          </div>
          <div className="flex space-x-[10px] mt-3 md:mt-0">
            {selectedRows.length > 0 && (
              <>
                <Dropdown
                  placeholder={"Select Action"}
                  options={dropdownOptions}
                  onChange={handleDropdownChange}
                  buttonClassName="p-2 hover:bg-gray-100 rounded-md flex items-center space-x-2"
                />
              </>
            )}
            <ButtonWithIcon
              buttonText={
                requestLetter ? "View Request Letter" : "Upload Request Letter"
              }
              className={
                "bg-transparent text-brandGreen border border-brandGreen"
              }
              textColor={"#358619"}
              radius={"md"}
              onClick={() => {
                if (requestLetter) {
                  window.open(requestLetter, "_blank");
                } else {
                  setActiveLetterType("request_letter");
                  setTimeout(() => {
                    document.getElementById("request_letter").showModal();
                  }, 0);
                }
              }}
            />
            {requestLetter && (
              <div
                className={`${
                  hasPermissions(["declare_stockdisposal"])
                    ? "flex items-center space-x-[10px] mt-1 md:mt-0"
                    : "hidden"
                } `}
              >
                <ButtonWithIcon
                  buttonText={"Declare for Disposal"}
                  radius={"md"}
                  onClick={handleDeclaration}
                />
              </div>
            )}
          </div>
        </div>
        <div className="h-[67vh] w-full relative">
          {
            <TablesComponent
              isLoading={isLoading}
              data={data?.data?.results}
              getFormattedValue={getFormattedValue}
              setSelectedRows={setSelectedRows}
              selectedRows={selectedRows}
              toggleRowFunction={(index, val) => toggleRowFunction(index, val)}
              toggleSelectAllFunction={() => {}}
              headers={headers}
              buttonFunction={(val) => {
                console.log(val);
                navigate(`/disposal/${val?.id}`);
              }}
              hideActionButton
            />
          }
        </div>
        <div className="flex items-center justify-between mt-4">
          <p className="text-14px text-brandBlack">
            {" "}
            {startItem} - {endItem} of {data?.data?.pagination?.count} Items
          </p>
          <PaginationRounded
            defaultPage={data?.data?.pagination?.page}
            count={data?.data?.pagination?.total_pages}
            onChange={(page) => {
              setCurrentPage(page);
            }}
          />
        </div>
      </div>
      <EditRequestIdModal request_id={request_id} itemId={itemId} />
      {activeLetterType && (
        <LetterUploadModal itemId={itemId} letterType={activeLetterType} />
      )}
      <ScheduleInspection />
    </div>
  );
};

export default ItemsByRequestId;
