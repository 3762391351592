import React, { useEffect, useState } from "react";
import useGetNotificationManager from "../controllers/getNotificationController";
import { MarkAllReadManager } from "../controllers/markAllReadController";
import { MarkAsReadManager } from "../controllers/markAsReadController";
import { formatDateAgo } from "../../../utils/timeAgo";
import { noData } from "../../../assets/images";

const NotificationDrawer = () => {
  const [selectedNotification, setSelectedNotification] = useState("");
  const { data, refetch } = useGetNotificationManager({
    enabled: true,
    page: 1,
    pageSize: 7,
  });
  const { mark } = MarkAllReadManager();
  const { mark: markOne } = MarkAsReadManager({
    id: selectedNotification,
  });

  useEffect(() => {
    if (selectedNotification) {
      markOne();
    }
  }, [selectedNotification, markOne]);

  return (
    <div className="drawer drawer-end z-10">
      <input
        id="user_notifications"
        type="checkbox"
        className="drawer-toggle"
      />
      <div className="drawer-side">
        <label
          htmlFor="user_notifications"
          aria-label="close sidebar"
          className="drawer-overlay"
        ></label>

        <ul className="w-[90%] md:w-[30vw] min-h-full bg-base-200 text-base-content relative flex flex-col justify-between">
          <div className="flex flex-col w-full gap-5">
            <div className=" mb-1 pl-4 inset-0 bg-base-200  top-0 sticky  pt-5 pb-1.5 z-10 gap-3 flex justify-between items-center">
              <p className="text-16px font-semibold text-brandBlack  drawer-open">
                Notifications
              </p>
              <button
                onClick={async () => {
                  await mark();
                  refetch();
                }}
                className="text-12px font-medium text-brandGreen w-full text-right pr-8"
              >
                Mark all as read
              </button>
            </div>

            {data?.data?.results?.length > 0 ? (
              <div className="w-full h-full relative  overflow-y-auto scrollbar-hide">
                {data?.data?.results?.map((notification, index) => (
                  <div
                    onClick={() => {
                      setSelectedNotification(notification?.id);

                      if (selectedNotification === notification?.id) {
                        markOne();
                      }
                    }}
                    key={notification?.id}
                    className={`flex flex-col items-start justify-between p-4 md:h-[78px] h-100px cursor-pointer ${
                      index < notification.length - 1
                        ? "border-b border-textGrey"
                        : ""
                    }`}
                  >
                    <div className="flex items-start">
                      <div
                        className={`md:w-2 w-4 md:h-2 h-4 rounded-full ${
                          notification?.is_read
                            ? "bg-textGrey/50"
                            : "bg-brandGreen"
                        } flex items-center justify-center mr-4 mt-3`}
                      ></div>
                      <div className="flex flex-col items-start">
                        <div className="max-w-max flex flex-wrap">
                          <p className="text-[14px]">{notification?.content}</p>
                        </div>
                        <div className="flex items-center space-x-2 relative">
                          <p className="text-[12px] text-[#92929D]">
                            {formatDateAgo(notification?.created_datetime)}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div className="flex flex-col w-full h-[90vh] items-center justify-center relative">
                <img src={noData} alt="No Data" className="h-[30%] " />
              </div>
            )}
          </div>

          <div
            onClick={() => window.open("/notifications", "_self")}
            className="w-full items-center justify-center flex mb-10"
          >
            <p className="text-center text-brandGreen underline">
              View all Notifications
            </p>
          </div>
        </ul>
      </div>
    </div>
  );
};

export default NotificationDrawer;
