import React, { useState } from "react";

import PaginationRounded from "../../../generalComponents/Pagination";
import { operatorsData } from "../../../data/operatorsData";
import TablesComponent from "../../../generalComponents/TablesComponent";
import { useNavigate } from "react-router-dom";
import ButtonWithIcon from "../../../generalComponents/ButtonWithIcon";
import AvatarWithName from "../../../generalComponents/AvatarWithName";
import StatusButton from "../../../generalComponents/StatusButton";
import SearchField from "../../../generalComponents/SearchField";
import PageHeading from "../../../generalComponents/PageHeading";
import CustomDropdown from "../../../generalComponents/CustomDropDown";
import BaseDashboardNavigation from "../../../generalComponents/BaseDashboardNavigation";
import { IoMdAddCircleOutline } from "react-icons/io";
import EventsModal from "../components/EventsModal";
const EventAuctionPage = () => {
  const [ setCurrentPage] = useState(1);
  const navigate = useNavigate();

  const headers = [
    "S/N",
    "Event Name",
    "Event Code",
    "Start Date",
    "End Date",
    "Status",
    "Entry Type",
    "Action",
  ];

  const getFormattedValue = (el, index) => {
    return [
      `${index + 1}`,
      <AvatarWithName title={"AG LOT"} />,
      "AF454",
      "Jan 22, 2024 11:35 AM",
      "Jan 22, 2024 11:35 AM",
      <StatusButton status={"active"} />,
      "Premium",
    ];
  };

  const options = ["View Event", "View Live Bid", "Share", "Edit", "Delete"];

  return (
    <BaseDashboardNavigation>
      {" "}
      <div className="flex flex-col w-full gap-5">
        <PageHeading title={"Events"} />
        <div className="flex md:flex-row flex-col justify-between items-center ">
          <div className="flex items-center gap-3 md:my-0 my-3 ">
            <SearchField placeholder={"Search"} />
          </div>
          <div className="flex items-center space-x-[10px]">
            <ButtonWithIcon
              buttonText={"Add Event"}
              icon={IoMdAddCircleOutline}
              radius={"md"}
              onClick={() => {
                document.getElementById("add_event").showModal();
              }}

            />
            <CustomDropdown placeholder={"Filter by"} />
            <ButtonWithIcon buttonText={"Download Report"} radius={"md"} />
          </div>
        </div>
        <div className="h-[67vh] w-full relative">
          <TablesComponent
            // isLoading={isLoading}
            showCheckBox={false}
            data={operatorsData}
            getFormattedValue={getFormattedValue}
            headers={headers}
            buttonFunction={(val) => {
              console.log(val);
            }}
            options={options}
            popUpFunction={(option, index, value) => {
              console.log(option, index, value);
              if (index === 0) {
                document.getElementById("/lots/lot/item").showModal();
              }
              if (index === 1) {
                navigate('/lots/lot/item');
              }
              if (index === 2) {
                document.getElementById("add_event").showModal();
              }
            }}
          />
        </div>
        <div className="flex items-center justify-between mt-4">
          <p className="text-14px text-brandBlack">1-10 of 195 items</p>
          <PaginationRounded
            defaultPage={1}
            count={100}
            onChange={(page) => {
              setCurrentPage(page);
            }}
          />
        </div>
      </div>
      <EventsModal />
    </BaseDashboardNavigation>
  );
};
export default EventAuctionPage;
