import useDeleteManager from "../../../constants/controller_templates/delete_controller_template";

export const DeleteLotsManager = ({ id }) => {
  const { deleteCaller, isLoading, isSuccess, error, data } = useDeleteManager(
    `/lots/${id}`,
    ["lots"]
  );
  const deleteLot = async () => {
    try {
      await deleteCaller();
    } catch (error) {
      console.error("error:", error);
    }
  };
  return {
    deleteLot,
    data,
    isLoading,
    isSuccess,
    error,
  };
};
