import usePostManager from "../../../constants/controller_templates/post_controller_template";

export const CreateConversationManager = (conversation_pk) => {
  const { postCaller, isLoading, isSuccess, error, data } = usePostManager(
    `/conversations/${conversation_pk}/messages/`,
    ["conversations"],
    true
  );
  const createConversation = async (details) => {
    try {
      await postCaller(details);
    } catch (error) {
      console.error("error:", error);
    }
  };
  return {
    createConversation,
    data,
    isLoading,
    isSuccess,
    error,
  };
};
