import React, { useEffect, useRef, useState } from "react";
import ModalManagement from "../../../../generalComponents/ModalManagement";
import CustomButton from "../../../../generalComponents/Button";
import { uploadIcon } from "../../../../assets/icons";
import { DisposalDocumentsUpdate } from "../controllers/DisposalDocumentsUpdateController";
import InputWithFullBoarder from "../../../../generalComponents/InputWithFullBoarder";

const DocumentsUploadModal = ({ itemId, letterType }) => {
  const fileInputRef = useRef(null);
  const [file, setFile] = useState(null);
  const [dragging, setDragging] = useState(false);
  const [documentName, setDocumentName] = useState("");

  const {
    uploadDocuments,
    isSuccess,
  } = DisposalDocumentsUpdate(itemId);

  const handleFileChange = (event) => {
    setFile(event?.target?.files[0]);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    setDragging(true);
  };

  const handleDragLeave = () => {
    setDragging(false);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    setDragging(false);
    setFile(event?.dataTransfer?.files[0]);
  };

  const handleUpload = async () => {
    if (file) {
      try {
        await uploadDocuments(file, letterType);
        setFile(null);
      } catch (uploadError) {
        console.error("Upload error:", uploadError);
      }
    } else {
      alert("Please select a file first.");
    }
  };

  useEffect(() => {
    if (isSuccess) {
      document.getElementById("document_upload").close();
    }
  }, [isSuccess]);

  return (
    <ModalManagement id={"document_upload"} title={"Documents Upload"}>
      <div
        className={`h-auto rounded-[6px] relative bg-white flex flex-col pt-6 ${
          dragging ? "bg-gray-100" : ""
        }`}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
      >
        <div className="flex flex-col items-center justify-center h-auto">
          <div className="w-[473px] h-[264px] text-center border border-dashed border-gray-400 mb-4 rounded-md flex flex-col items-center justify-center">
          <div className="mt-4">
              <InputWithFullBoarder
                label={"Enter Document Name"}
                placeholder="Enter Document Name"
                className={"border border-gray-600"}
                type={"text"}
                value={documentName}
                onChange={(e) => setDocumentName(e.target.value)}
                required
              />             
            <div className="border border-gray-200 my-3" />
            </div>
            <img src={uploadIcon} className="mb-4" alt="Upload Icon" />
            <input
              type="file"
              id="fileInput"
              className="hidden"
              onChange={handleFileChange}
              accept=".pdf, .png, .jpg, .jpeg"
            />
            <label
              htmlFor="fileInput"
              className="cursor-pointer flex flex-col items-center"
            >
              <p className="text-[14px]">
                <span className="font-medium text-brandGreen">
                  Click to Upload
                </span>{" "}
                or drag and drop
              </p>
              <p className="text-[12px] text-[#98A2B3]">File format: .png, .jpeg, .jpg, .pdf</p>
            </label>
            {file && (
              <p className="mt-2 text-[14px]">Selected file: {file?.name}</p>
            )}
            <div className="w-[90%] text-center border-t border-[#F0F2F5] relative my-6"></div>
            <div className="">
              <CustomButton
                buttonText={"Browse Files"}
                onClick={() => fileInputRef?.current?.click()}
              />
              <input
                ref={fileInputRef}
                type="file"
                id="fileInput"
                className="hidden"
                onChange={handleFileChange}
                accept=".pdf, .png, .jpg, .jpeg"
              />{" "}
            </div>
          </div>
        </div>
        <div className="flex justify-end space-x-2 mt-4 w-full">
          <CustomButton
            buttonText={"Cancel"}
            textColor={"#344054"}
            className="bg-white border border-gray-600"
            onClick={() => {
              document.getElementById("document_upload").close();
            }}
          />
          <CustomButton buttonText={"Upload"} onClick={handleUpload} />
        </div>
      </div>
    </ModalManagement>
  );
};

export default DocumentsUploadModal;
