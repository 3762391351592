import { useQuery } from "react-query";
import AxiosWithToken from "../../../constants/api_management/MyHttpHelperWithToken";

const useGetBorrowRequestsManager = ({
  page,
  searchQuery = "",
  enabled = true,
  pageSize = "15",
  is_lender_client = false,
  is_borrower_client = false,
  stock_id = "",
  status="",
  is_returned=""
}) => {
  return useQuery(
    ["borrow", is_lender_client, stock_id, is_borrower_client, page, status, is_returned, searchQuery],
    async () => {
      try {
        const response = await AxiosWithToken.get(
          `/stock/borrows/?status=${status}&is_lender_client=${is_lender_client}&is_borrower_client=${is_borrower_client}&stock_id=${stock_id}&is_returned=${is_returned}&page=${page}&size=${pageSize}${searchQuery}`
        );
        //console.log(`i am checking this ${response.status}`);
        return response.data;
      } catch (error) {
        //console.log(error.response.data);
        throw new Error(`Sorry: ${error.response.data.message}`);
      }
    }
  );
};

export default useGetBorrowRequestsManager;
