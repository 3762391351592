import React from "react";
import BaseDashboardNavigation from "../../../generalComponents/BaseDashboardNavigation";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getLastPathSegment } from "../../../utils/getLastPath";
import Breadcrumb from "../../../generalComponents/BreadCrumb";
import { noImage } from "../../../assets/images";
import ButtonWithIcon from "../../../generalComponents/ButtonWithIcon";
import StatusButton from "../../../generalComponents/StatusButton";
import { MdChatBubbleOutline } from "react-icons/md";
import { BiCalendar, BiCheckCircle } from "react-icons/bi";
import { CgDanger } from "react-icons/cg";
import Tabs from "../../../generalComponents/Tabs";
import useGetMarkeplaceRequestByIdManager from "../controllers/getMarkeplaceRequestByIdController";
import AssetDetailsSection from "../../Requests/components/AssetDetailsSection";
import ScheduleModal from "../../Requests/components/ScheduleModal";
import { ApproveExchangeManager } from "../controllers/approveExchangeController";
import { RejectExchangeManager } from "../controllers/rejectExchangeController";

// Inside RequestDetailsPage component
const ExchangeRequestDetailsPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const lastSegment = getLastPathSegment(location.pathname);
  const fromIncomingRequests = location.state?.fromIncomingRequests;
  const fromOutgoingRequests = location.state?.fromOutgoingRequests;

  const { data: itemDetails } = useGetMarkeplaceRequestByIdManager(id);

  const { approveExchange, isLoading: approving } = ApproveExchangeManager(id);

  const { rejectExchange, isLoading: rejecting } = RejectExchangeManager(id);

  const item = itemDetails?.data?.stock;
  const approvalStatus = itemDetails?.data?.status;

  const items = [
    { name: "Marketplace", path: "/marketplace/" },
    {
      name: item?.asset_name,
      path: `/marketplace/request/${lastSegment}`,
    },
  ];

  const assetDetailsData = [
    { label: "Asset Code", value: item?.asset_code || "N/A" },
    { label: "Material", value: item?.material || "N/A" },
    { label: "Quantity", value: item?.quantity || "N/A" },
    { label: "Unit", value: item?.unit || "N/A" },
    { label: "Unit Cost Naira", value: item?.unit_cost_naira || "N/A" },
    { label: "Unit Cost USD", value: item?.unit_cost_dollar || "N/A" },
    // { label: "Project Name", value: item?.project || "N/A" },
    { label: "Manufacturer", value: item?.manufacturer || "N/A" },
    { label: "Warehouse", value: item?.warehouse || "N/A" },
    { label: "Storage Location", value: item?.storage_location || "N/A" },
    { label: "Row", value: item?.row || "N/A" },
    { label: "Slot", value: item?.slot || "N/A" },
    { label: "Dimension", value: item?.dimension || "N/A" },
    { label: "Component", value: item?.component || "N/A" },
    { label: "Classification", value: item?.classification || "N/A" },
    { label: "Connection Type", value: item?.connection_type || "N/A" },
    { label: "Capacity", value: item?.capacity || "N/A" },
    { label: "ERP Number", value: item?.erp_number || "N/A" },
    { label: "Serial Number", value: item?.serial_number || "N/A" },
    { label: "Model Number", value: item?.model_number || "N/A" },
    { label: "Bin Number", value: item?.bin_number || "N/A" },
    { label: "Bactch Number", value: item?.batch_number || "N/A" },
  ];

  const tabsData = [
    {
      label: "Asset Details",
      component: <AssetDetailsSection data={assetDetailsData} />,
    },
  ];

  const handleApproval = (id) => {
    approveExchange({ id });
  };

  const handleRejection = (id) => {
    rejectExchange({ id });
  };

  return (
    <BaseDashboardNavigation>
      <div className="md:mt-4 flex flex-col md:flex-row mt-2 justify-between gap-y-5">
        <Breadcrumb items={items} />
      </div>
      <div className="">
        <div className="flex md:flex-row flex-col w-full relative items-center mb-4 gap-5 h-[450px] mt-5">
          {/* Left Card with Image */}
          <div className="flex flex-[0.5] items-center bg-white p-2 rounded-lg md:w-full h-full">
            <img
              src={item?.image || noImage}
              className=" overflow-hidden rounded-lg shadow-md w-full h-full object-cover"
              alt="Item"
            />
          </div>

          {/* Right Card */}
          <div className="flex flex-[0.5] flex-col justify-center  gap-4 h-full w-full  bg-white rounded-lg border border-gray-200 p-6">
            <div className="flex flex-col gap-2 py-2 items-start border-b border-gray-200">
              <h1 className="text-20px md:text-[28px] font-semibold my-2 leading-[20px]">
                {item?.asset_name}
              </h1>
              <p className="text-gray-500 text-sm">{item?.client?.name}</p>
              <StatusButton status={itemDetails?.data?.status} />
            </div>
            <div className="w-full flex flex-col gap-3 items-start border-b border-gray-200">
              <div className="flex flex-col my-1.5">
                <p className="text-gray-500 text-sm">Operator</p>
                <h2 className="text-16px md:text-[24px] font-semibold ">OLA</h2>
              </div>
              <div className="flex flex-col my-1.5">
                <p className="text-gray-500 text-sm">Storage Location</p>
                <h2 className="text-16px md:text-[24px] font-semibold">
                  {item?.storage_location || "N/A"}
                </h2>
              </div>
            </div>
            <div className="flex w-full gap-x-4">
              <ButtonWithIcon
                buttonText={`Send Message`}
                textColor={"brandGreen"}
                className={
                  "max-w-max bg-white border border-brandGreen text-brandGreen"
                }
                icon={MdChatBubbleOutline}
                onClick={() => {
                  navigate(`/requests/chat/${id}`);
                }}
              />
              {fromOutgoingRequests && approvalStatus === "approved" && (
                <ButtonWithIcon
                  buttonText="Schedule Inspection"
                  icon={BiCalendar}
                  onClick={() => {
                    document.getElementById("schedule_inspection").showModal();
                  }}
                  className={"max-w-max"}
                />
              )}
            </div>

            {fromIncomingRequests && approvalStatus === "pending" ? (
              <div className="flex space-x-2 mt-4 w-full">
                <ButtonWithIcon
                  buttonText={`Reject`}
                  className={"max-w-max bg-red-500 border"}
                  icon={CgDanger}
                  isLoading={rejecting}
                  onClick={() => handleRejection(id)} // Pass the id explicitly here
                />
                <ButtonWithIcon
                  buttonText={`Accept Request`}
                  className={
                    "max-w-max border border-brandGreen text-brandGreen"
                  }
                  icon={BiCheckCircle}
                  isLoading={approving}
                  onClick={() => handleApproval(id)} // Pass the id explicitly here
                />{" "}
              </div>
            ) : (
              ""
            )}
          </div>
        </div>

        <Tabs tabsData={tabsData} />
      </div>

      <ScheduleModal stockId={id} type={"exchange"} />
    </BaseDashboardNavigation>
  );
};

export default ExchangeRequestDetailsPage;
