import useFormDataPostManager from "../../../../constants/controller_templates/post_formdata_controller_template";

export const DisposalDocumentsUpdate = (id) => {
  const { postCaller, isLoading, isSuccess, error, data } =
    useFormDataPostManager(
      `/stock/disposals/${id}/documents/`,
      ["disposals"],
      true
    );

    const uploadDocuments = async (file) => {
      const formData = { document: file }; // Use the correct key expected by your backend
      await postCaller(formData);
    };
    

  return {
    uploadDocuments,
    data,
    isLoading,
    isSuccess,
    error,
  };
};
