import React from "react";
import HomeHero from "../components/HomeHero";
import KeyFeatures from "../components/KeyFeatures";
import WhyEMMS from "../components/WhyEMMS";
import Subscribe from "../components/Subscribe";
import HeaderFooter from "../../../generalComponents/HeaderFooter";
import HomeModules from "../components/HomeModules";
import Testimonials from "../components/Testimonials";

const HomePage = () => {
  // const {
  //   data: posts,
  //   isLoading,
  //   isError,
  //   isSuccess,
  //   error,
  // } = useGetPosts({ enabled: true });

  // if (isSuccess) {
  //   toast.success(`Hello this is successful`);
  // }
  return (
    <HeaderFooter>
      <div className="max-w-screen flex flex-col w-full mx-auto relative bg-white">
        <HomeHero />
        <HomeModules />
        <KeyFeatures />
        <WhyEMMS />
        <Testimonials />
      </div>
      <Subscribe />
    </HeaderFooter>
  );
};

export default HomePage;
