import React from "react";

const TabsManagement = ({
  setCurrentView,
  currentView,
  options,
  showDivider = true,
  divisionWidth,
}) => {
  return (
    <div className="w-full overflow-x-scroll scrollbar-hide">
    <div className="flex items-center w-full justify-start relative gap-0 my-3">
      {options.map((el, i) => (
         <p
         key={i}
         role="button"
         onClick={() => setCurrentView(i)}
         className={`text-13px pb-1 px-4 md:px-10 whitespace-nowrap ${
           currentView === i
             ? "font-medium text-brandBlack border-b-2 border-b-brandGreen text-brandGreen"
             : "text-textGrey2"
         }`}
       >
          {el}
        </p>
      ))}
      {showDivider && (
        <div
          className={`divider divider-[#E4E7EC] inset-y-0 absolute top-1.5 ${
            divisionWidth ?? "w-full"
          }`}
        ></div>
      )}
    </div>
    </div>
  );
};

export default TabsManagement;
