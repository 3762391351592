"use client";

import { useState } from "react";
import { defaultProfilePicture } from "../../../constants/GlobalVariables";
import ModalManagement from "../../../generalComponents/ModalManagement";

const ItemsTable = ({
  filter,
  debouncedSearchValue,
  pagination,
  users,
  isLoading,
  isClientView = false,
  currentPage,
}) => {
  const [ setSelectedUserDetails] = useState(null);
  const [showOptions, setShowOptions] = useState(false);
  const [currentIndex] = useState(null);

  const options = ["Edit Role", "Delete Role"];

  const tableHeading = [
    { title: "Item", type: "text" },
    { title: "Qty", type: "text" },
    { title: "Action", type: "button" },
  ];

  return (
    <div className=" bg-white  w-full relative h-full overflow-y-auto scrollbar-hide">
      <div className="w-full relative h-full">
        <table className="lg:divide-gray-200 lg:divide-y w-full h-full relative">
          <thead className="lg:table-header-group sticky top-0 bg-[#F5F6F8] z-10 w-full ">
            <tr>
              {tableHeading.map((el, i) => (
                <th
                  key={i}
                  className="py-3.5 px-4 text-left  font-medium text-black text-[16px] "
                >
                  <h3 className="">{el.title}</h3>
                </th>
              ))}
            </tr>
          </thead>

          <tbody>
            {[...Array.from({ length: 10 }, (_, index) => index + 1)].map(
              (user, index) => (
                <tr
                  key={index}
                  className={`bg-white ${
                    index === 9
                      ? ""
                      : "border border-transparent border-b-lightGrey pb-2.5"
                  }`}
                >
                  <td className="px-4 py-5 text-[14px]  text-gray-900 align-top lg:align-middle whitespace-nowrap">
                    <div className="flex items-center h-[40px] relative gap-3">
                      <img
                        src={defaultProfilePicture}
                        className="h-[55.35px] w-[65px] rounded-[5px] object-cover"
                        alt="Item"
                      />
                      <div className="flex flex-col items-start">
                        <h3 className="text-black font-semibold text-14px">{`THREE IN ONE PRINTERS`}</h3>
                        <h3 className="text-textGrey font-medium text-12px">{`NIUMS`}</h3>
                      </div>
                    </div>
                    <div className="mt-1 space-y-2 pl-11 lg:hidden">
                      <div className="flex items-center font-medium ">2</div>

                      <div className="flex items-center pt-3 space-x-4">
                        <button
                          type="button"
                          onClick={() => {
                            setSelectedUserDetails(user);
                          }}
                          className="underline text-14px font-medium text-brandGreen"
                        >
                          {/* <img src={moreMore.src} alt="Item" /> */}
                          View
                        </button>
                      </div>
                    </div>
                  </td>

                  <td className="hidden px-4 py-5 text-sm font-medium text-gray-900 lg:table-cell whitespace-nowrap">
                    <div className="flex items-center">2</div>
                  </td>

                  <td className="hidden px-4 py-5 lg:table-cell whitespace-nowrap">
                    <div className="flex items-center space-x-4">
                      <button
                        type="button"
                        onClick={() => {
                          setSelectedUserDetails(user);
                          document.getElementById("show_item").showModal();
                          // setShowOptions(
                          //   index === currentIndex ? !showOptions : true
                          // );
                          // setCurrentIndex(index);
                        }}
                        className="underline text-14px font-medium text-brandGreen"
                      >
                        View
                        {/* <img src={moreMore.src} alt="Item" /> */}
                      </button>
                      <div className="relative">
                        {currentIndex === index && showOptions && (
                          <div
                            className={`w-[171px] inset-x-[-120px] absolute z-10 shadow-lg top-4 right-6 rounded-[4px] bg-whiteColor p-2 flex flex-col`}
                          >
                            {options.map((option, inx) => (
                              <p
                                role="button"
                                onClick={() => {
                                  setShowOptions(false);
                                  if (option === "Edit Role") {
                                  } else {
                                  }
                                }}
                                key={inx}
                                className={`text-14px p-2 hover:bg-mainLightGrey w-full flex items-start justify-start text-start text-brandBlack`}
                              >
                                {option}
                              </p>
                            ))}
                          </div>
                        )}
                      </div>
                    </div>
                  </td>
                </tr>
              )
            )}
          </tbody>
        </table>
      </div>
      <ModalManagement id={`show_item`}>
        <div className="w-[755px] h-[668.67px] rounded-[6px] relative">
          <div className="w-full h-[95] backdrop-blur-lg bg-blackColor opacity-50 inset-x-0 bottom-0 absolute flex flex-col items-start gap-1.5 text-whiteColor p-3">
            <h3 className="text-28px font-semibold">Conductor Pipes</h3>
            <h3 className="text-12px">Qty: 12</h3>
          </div>
          <img
            src={defaultProfilePicture}
            className="object-cover h-full w-full rounded-[6px]"
            alt="Item"
          />
        </div>
      </ModalManagement>
    </div>
  );
};

export default ItemsTable;
