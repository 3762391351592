import React from "react";
import BaseDashboardNavigation from "../../../generalComponents/BaseDashboardNavigation";
import GoBackButton from "../../../generalComponents/GoBackButton";
import {
  attachmentIcon,
  sendIcon,
  userIcon,
} from "../../../assets/icons";
import ButtonWithIcon from "../../../generalComponents/ButtonWithIcon";
import InputWithFullBoarder from "../../../generalComponents/InputWithFullBoarder";

const TicketDetailsPage = () => {

  return (
    <BaseDashboardNavigation>
      <div
        className={`max-w-[1240px] w-full flex flex-col text-blackColor bg-[#f7f9fc]  mx-auto relative`}
      >
        <div
          className={`justify-between items-center flex flex-col bg-[#f7f9fc] w-full top-0 z-10 pt-4`}
        >
          <GoBackButton />
        </div>

        <div className="w-[789px] h-[618px]">
          <div className="flex md:justify-end justify-start pb-3 pt-8">
            <ButtonWithIcon buttonText={"Mark as Resolved"} />
          </div>
          <div className="md:w-[789px] w-1/2 h-[618px] bg-white border border-[#E5E7EB] rounded-sm shadow-sm p-8">
            <div className="flex justify-between pb-3">
              <p className="text-[12px] text-[#344054]">Subject</p>
              <p className="text-[14px] text-[#344054] font-medium">
                Issues with transactions
              </p>
            </div>
            <div className="flex justify-between pb-3">
              <p className="text-[12px] text-[#344054]">Name</p>
              <p className="text-[14px] text-[#344054] font-medium">OLA</p>
            </div>
            <div className="flex justify-between pb-3">
              <p className="text-[12px] text-[#344054]">Email</p>
              <p className="text-[14px] text-[#344054] font-medium">
                ola1234@gmail.com
              </p>
            </div>
            <div className="flex justify-between pb-3">
              <p className="text-[12px] text-[#344054]">Date Created</p>
              <p className="text-[14px] text-[#344054] font-medium">
                Jan 11, 2024
              </p>
            </div>

            <div>
              <p className="text-[12px] text-[#344054] mb-2 mt-8">Message</p>
              <p className="text-[12px] text-[#344054]">
                Lorem ipsum dolor sit amet consectetur. Neque urna venenatis
                mattis turpis. Cum sollicitudin arcu dui vulputate vel. Pulvinar
                ut euismod risus aliquam sapien tincidunt sed. Vulputate mattis
                rhoncus nec pellentesque penatibus cum. Amet est diam ultrices
                non turpis phasellus. Pharetra amet odio amet viverra urna
                facilisis. Faucibus amet feugiat elit et. Fermentum eget eu
                tristique auctor sit semper enim. Vestibulum vulputate pharetra
                elit non sit. Enim consectetur sit id erat ullamcorper. Sed et
                arcu cras donec vulputate. Consectetur dui viverra mi adipiscing
                fringilla. Malesuada bibendum at non elementum maecenas tortor.
                Diam curabitur massa amet diam lectus risus sit. Hendrerit et
                odio sed in et ac odio mi at. Malesuada ultricies risus ipsum
                consectetur.
              </p>
            </div>

            <div className="border border-[#E5E7EB] my-4 "></div>
            <div className="mb-2 mt-8">
              <p className="text-[12px] text-[#344054] font-medium">Replies</p>
              <div className="flex mt-4">
                <img src={userIcon} alt="Profile" />
                <span className="ml-2">
                  <p className="text-[12px] text-[#344054] font-medium">
                    Admin
                  </p>
                  <p className="text-[10px] text-[#828282]">Jan 11, 2023</p>
                </span>
              </div>
              <p className="text-[12px] text-[#344054] py-3">
                Lorem ipsum dolor sit amet consectetur. Neque urna venenatis
                mattis turpis. Cum sollicitudin arcu dui vulputate vel. Pulvinar
                ut euismod risus aliquam sapien tincidunt sed. Vulputate mattis
                rhoncus nec pellentesque penatibus cum. Amet est diam ultrices
                non turpis phasellus. Pharetra amet odio amet viverra urna
                facilisis. Faucibus amet feugiat elit et. Fermentum eget eu
                tristique auctor sit semper enim. Vestibulum vulputate pharetra
                elit non sit. Enim consectetur sit id erat ullamcorper. Sed et
                arcu cras donec vulputate. Consectetur dui viverra mi adipiscing
                fringilla. Malesuada bibendum at non elementum maecenas tortor.
                Diam curabitur massa amet diam lectus risus sit. Hendrerit et
                odio sed in et ac odio mi at. Malesuada ultricies risus ipsum
                consectetur.
              </p>
            </div>
          </div>
          <div className="flex w-[789px] h-[109px] bg-white p-8 border border-greyBackground">
            <div className="relative flex-grow">
              <InputWithFullBoarder placeholder={"          Enter your Message"} />
              <img
                src={attachmentIcon}
                className="absolute left-2 top-1/2 transform -translate-y-1/3"
                alt="Attachment"
              />
            </div>
            <img src={sendIcon} alt="Send" className="ml-4" />
          </div>
        </div>
      </div>
    </BaseDashboardNavigation>
  );
};

export default TicketDetailsPage;
