import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";

const OperatorStatistics = () => {
  const data = [
    { name: "Decagon", incoming: 8, outgoing: 4 },
    { name: "Ola", incoming: 6, outgoing: 3 },
    { name: "First E&P", incoming: 4, outgoing: 5 },
    { name: "Seplat", incoming: 9, outgoing: 7 },
    { name: "Pentagon", incoming: 7, outgoing: 6 },
    { name: "Mayowa", incoming: 5, outgoing: 4 },
  ];

  return (
    <div>
      <div className="w-full bg-[#F7F8F9] items-center justify-center p-4 rounded-t-lg border border-gray-200">
        <h3 className="text-lg font-semibold">Operators Statistics</h3>
      </div>
      <div className="h-[380px] bg-white rounded-b-lg p-2 border border-gray-200">
        <div className="w-full">
          <LineChart
            width={600}
            height={350}
            data={data}
            margin={{ top: 5, right: 20, left: 20, bottom: 5 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" tick={{ fontSize: 12 }} />
            <YAxis />
            <Tooltip />
            <Legend />
            <Line
              type="monotone"
              dataKey="incoming"
              stroke="#358619"
              strokeWidth={2}
              dot={{ r: 4 }}
            />
            <Line
              type="monotone"
              dataKey="outgoing"
              stroke="#ff0000"
              strokeWidth={2}
              dot={{ r: 4 }}
            />
          </LineChart>
        </div>
      </div>
    </div>
  );
};

export default OperatorStatistics;
