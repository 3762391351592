import React, { useEffect } from "react";
import ModalManagement from "../../../../generalComponents/ModalManagement";
import ButtonWithIcon from "../../../../generalComponents/ButtonWithIcon";
import { DeclareDisposalManager } from "../controllers/declareDisposalController";
import useGetItemsByRequestIdManager from "../../disposalBatches/controllers/getItemsByBatchController";
import TablesComponent from "../../../../generalComponents/TablesComponent";
import truncateWithTooltip from "../../../../utils/truncateText";

const BulkDisposalModal = ({ request_id }) => {
  const { data, isLoading } = useGetItemsByRequestIdManager({
    request_id: request_id,
    enabled: true,
  });

  const { declareDisposal, isSuccess } = DeclareDisposalManager();

  const handleDeclaration = () => {
    const payload = {
      request_id: request_id,
    };

    declareDisposal(payload);
  };

  useEffect(() => {
    if (isSuccess) {
      document.getElementById("bulk_disposal").close();
    }
  }, [isSuccess]);

  const headers = [
    "S/N",
    "Asset Name",
    "Asset Code",
    "NUIMS Qty",
    "Operator Qty",
  ];

  const getFormattedValue = (el, index) => {
    return [
      index + 1,
      truncateWithTooltip(el?.stock?.asset_name),
      el?.asset_code,
      el?.nuims_quantity,
      el?.operator_quantity,
    ];
  };

  return (
    <div>
      <ModalManagement id={"bulk_disposal"} title={"Confirm Disposal"}>
        <div className="w-auto h-auto rounded-[6px] relative bg-white flex flex-col pt-3">
          <p>
            You are about to declare the following items with request ID:{" "}
            <span className="text-red-500 font-semibold">{request_id}</span> for
            disposal
          </p>
          <div className="flex flex-col md:flex-row md:space-x-2 my-3 ">
            <div className="flex-1">
              {
                <TablesComponent
                  isLoading={isLoading}
                  data={data?.data?.results}
                  getFormattedValue={getFormattedValue}
                  headers={headers}
                  toggleRowFunction={() => {}}
                  toggleSelectAllFunction={() => {}}
                  showCheckBox={false}
                  hideActionButton
                />
              }
            </div>
          </div>

          <div className="flex flex-col md:flex-row md:space-x-2 my-2 justify-end">
            <ButtonWithIcon
              buttonText={"Cancel"}
              textColor={"#344054"}
              className={"bg-white border border-gray-600 h-[30px] w-auto"}
              onClick={() => {
                document.getElementById("bulk_disposal").close();
              }}
            />
            <ButtonWithIcon
              buttonText={"Confirm"}
              onClick={handleDeclaration}
              className={"bg-red-500"}
            />
          </div>
        </div>
      </ModalManagement>
    </div>
  );
};

export default BulkDisposalModal;
