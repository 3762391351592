import { useQuery } from "react-query";
import AxiosWithToken from "../../../../constants/api_management/MyHttpHelperWithToken";

const useGetUtilizationLogsManager = ({
  page,
  searchQuery = "",
  enabled = true,
  pageSize = "15",
  stock_id = ""
}) => {
  return useQuery(['utilization', page, stock_id, searchQuery], async () => {
    try {
      const response = await AxiosWithToken.get(`/stock/utilizations/?stock_id=${stock_id}&page=${page}&size=${pageSize}${searchQuery}`);
      return response.data;
    } catch (error) {
      throw new Error(`Sorry: ${error.response?.data?.message || 'An error occurred'}`);
    }
  });
};

export default useGetUtilizationLogsManager;