import React, { useEffect, useState } from "react";
import ModalManagement from "../../../../generalComponents/ModalManagement";
import InputWithFullBoarder from "../../../../generalComponents/InputWithFullBoarder";
import SelectWithFullBorder from "../../../../generalComponents/SelectWithFullBorder";
import useGetProjectsManager from "../../../projects/controllers/getProjectsController";
import ButtonWithIcon from "../../../../generalComponents/ButtonWithIcon";
import { TransferStockManager } from "../controllers/transferStockController";

const TransferItemModal = ({ selectedStock }) => {
  const [transferQuantity, setTransferQuantity] = useState(0);
  const [selectedTransferOption, setSelectedTransferOption] = useState("");
  const [selectedProject, setSelectedProject] = useState("");

  const { transferStock, isSuccess } = TransferStockManager(selectedStock);

  const { data: projects } = useGetProjectsManager({
    enabled: true,
  });

    const transferOptions = [
    { value: "STOCK", label: "Stock" },
    { value: "PROJECT", label: "Project" },
    { value: "SURPLUS", label: "Market" },
  ];


  const handleTransferAsset = () => {
    const payload = {
      allocation: selectedTransferOption,
      quantity: transferQuantity,
      project: selectedProject,
    };

    transferStock(payload);
  };

  useEffect(() => {
    if (isSuccess) {
      document.getElementById("transfer_asset").close();
    }
  }, [isSuccess]);

  return (
    <div>
      <ModalManagement id={"transfer_asset"} title={"Transfer Asset"}>
        <div className="md:w-[599px] h-auto rounded-[6px] relative bg-white flex flex-col pt-6">
          <div className="flex flex-col md:flex-row md:space-x-2">
            <div className="flex-1">
              <SelectWithFullBorder
                label={"Transfer to:"}
                selectOptions={transferOptions}
                value={selectedTransferOption}
                onChange={(e) => setSelectedTransferOption(e.target.value)}
                placeholder="Select Transfer Location"
              />
            </div>
          </div>
          {selectedTransferOption === "PROJECT" ? (
            <div className="flex-1">
              <SelectWithFullBorder
                label={"Select Project"}
                selectOptions={[
                  ...projects?.data?.results?.map((project) => ({
                    label: project.name,
                    value: project.id,
                  })),
                ]}
                value={selectedProject}
                onChange={(e) => setSelectedProject(e.target.value)}
                placeholder="Project to transfer to"
              />
            </div>
          ) : (
            ""
          )}
          <div className="flex flex-col md:flex-row md:space-x-2">
            <div className="flex-1">
              <InputWithFullBoarder
                label={"Quantity"}
                placeholder={"Enter Quantity"}
                type={"number"}
                value={transferQuantity}
                onChange={(e) => setTransferQuantity(e.target.value)}
                required
              />
            </div>
          </div>
          <div className="flex flex-col md:flex-row md:space-x-2 justify-end">
            <ButtonWithIcon
              buttonText={"Cancel"}
              textColor={"#344054"}
              className={"bg-white border border-gray-600 h-[30px] w-auto"}
              onClick={() => {
                document.getElementById("transfer_asset").close();
              }}
            />
            <ButtonWithIcon
              buttonText={"Transfer Asset"}
              onClick={handleTransferAsset}
            />
          </div>
        </div>
      </ModalManagement>
    </div>
  );
};

export default TransferItemModal;
