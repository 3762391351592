import { useQuery } from "react-query";
import AxiosWithToken from "../../../../constants/api_management/MyHttpHelperWithToken";

const useGetDisposalBatchByIdManager = (id) => {
  return useQuery(['disposals', id], async () => {
    try {
      const [response] = [await AxiosWithToken.get(`/stock/disposals/${id}/`)];
      //console.log(`i am checking this ${response.status}`);
      return response.data;
    } catch (error) {
      //console.log(error.response.data);
      throw new Error(`Sorry: ${error.response.data.message}`);
    }
  }, {
    enabled: !!id, // Ensures the query only runs when id is available
  });
};

export default useGetDisposalBatchByIdManager;
