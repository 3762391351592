import { useQuery } from "react-query";
import AxiosWithToken from "../../../constants/api_management/MyHttpHelperWithToken";

const useGetExchangeRequestsManager = ({
  page,
  searchQuery = "",
  enabled = true,
  pageSize = "15",
  is_seller_client = false,
  is_buyer_client = false,
  stock_id = ""
}) => {
  return useQuery(['exchange', is_seller_client, page, stock_id, searchQuery], async () => {
    try {
      const response = await AxiosWithToken.get(`/stock/exchange/?is_seller_client=${is_seller_client}&is_buyer_client=${is_buyer_client}&stock_id=${stock_id}&page=${page}&size=${pageSize}${searchQuery}`,);
      //console.log(`i am checking this ${response.status}`);
      return response.data;
    } catch (error) {
      //console.log(error.response.data);
      throw new Error(`Sorry: ${error.response.data.message}`);
    }
  });
};

export default useGetExchangeRequestsManager;
