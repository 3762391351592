import React, { useState } from "react";
import { logo } from "../../../assets/images";
import LeftSideComponent from "../components/LeftSideComponent";
import InputWithFullBoarder from "../../../generalComponents/InputWithFullBoarder";
import CustomButton from "../../../generalComponents/Button";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import useLoginManager from "../controller/loginController";
import { ToastContainer } from "react-toastify";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [viewPassword, setViewPassword] = useState();

  const { login, isLoading, isSuccess } = useLoginManager(email);

  const handleSubmission = async (e) => {
    e.preventDefault();
    const details = {
      email: email,
      password: password,
    };

    await login(details);

    if (isSuccess) {
      setEmail("");
      setPassword("");
    }
  };

  return (
    <div className="flex flex-col md:flex-row sm:h-screen">
      <ToastContainer />
      <LeftSideComponent />

      <div className="w-full md:w-1/2 flex flex-col justify-center items-center bg-white p-8">
        <div className="w-full max-w-md">
          <img
            src={logo}
            alt="EMMS Logo"
            className="mb-[105px] mx-auto h-[62px] w-auto object-contain"
          />
          <h2 className="text-2xl md:text-3xl font-bold mb-2 mt-3 text-center">
            Welcome!
          </h2>
          <p className="text-14px text-[#645D5D] mb-6 text-center">
            Enter your credentials to access your account{" "}
          </p>

          <form onSubmit={handleSubmission}>
            <InputWithFullBoarder
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="h-[56px]"
              required
            />
            <InputWithFullBoarder
              hasSuffix={true}
              type={viewPassword ? `text` : `password`}
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="h-[56px]"
              required
              icon={
                viewPassword ? (
                  <AiOutlineEyeInvisible
                    onClick={() => setViewPassword(!viewPassword)}
                  />
                ) : (
                  <AiOutlineEye
                    onClick={() => setViewPassword(!viewPassword)}
                  />
                )
              }
            />
            <div className="flex items-center justify-between mb-6">
              {/* <CustomCheckBox
                text={"Remember me"}
                checked={rememberMe}
                onChange={(e) => setRememberMe(e.target.checked)}
              /> */}
              <a
                className="inline-block align-baseline text-sm text-brandGreen"
                href="/forgot-password"
              >
                Forgot password?
              </a>
            </div>
            <CustomButton
              buttonText={"Login"}
              isLoading={isLoading}
              radius={"md"}
              className="w-full"
              type="submit"
            />
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
