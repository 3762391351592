import usePostManager from "../../../../constants/controller_templates/post_controller_template";

export const CreateDisposalInspectionManager = (id) => {
  const { postCaller, isLoading, isSuccess, error, data } = usePostManager(
    `/stock/disposals/${id}/inspections/`,
    ["disposals"],
    true
  );
  const createDisposalInspection = async (details) => {
    try {
      await postCaller(details);
    } catch (error) {
      console.error("error:", error);
    }
  };
  return {
    createDisposalInspection,
    data,
    isLoading,
    isSuccess,
    error,
  };
};

