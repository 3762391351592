import React, { useEffect, useState } from "react";
import ModalManagement from "../../../../generalComponents/ModalManagement";
import InputWithFullBoarder from "../../../../generalComponents/InputWithFullBoarder";
import ButtonWithIcon from "../../../../generalComponents/ButtonWithIcon";
import { BiPlus } from "react-icons/bi";
import { DisposalDetailsUpdate } from "../controllers/DisposalDetailsUpdateController";

const ApprovalIdModal = ({ request_id, itemId }) => {
  const [approvalId, setApprovalId] = useState("");

  const { updateDisposal, isSuccess } = DisposalDetailsUpdate(itemId);

  const handleCreateApprovalId = () => {
    const payload = {
      approval_id: approvalId,
    };

    updateDisposal(null, null, payload);
  };

  useEffect(() => {
    if (isSuccess) {
      document.getElementById("approval_id").close();
    }
  }, [isSuccess]);

  return (
    <div>
      <ModalManagement id={"approval_id"} title={"Enter Approval ID"}>
        <p>Enter Approval ID here</p>
        <div className="md:w-[599px] h-auto rounded-[6px] relative bg-white flex flex-col pt-6">
          
          <div className="flex flex-col md:flex-row md:space-x-2">
            <div className="flex-1">
              <InputWithFullBoarder
                label={"Approval ID"}
                placeholder={"Enter Approval ID"}
                type={"text"}
                value={approvalId}
                onChange={(e) => setApprovalId(e.target.value)}
                required
              />
            </div>
          </div>
          <div className="flex flex-col md:flex-row md:space-x-2 justify-end">
            <ButtonWithIcon
              icon={BiPlus}
              buttonText={"Cancel"}
              textColor={"#344054"}
              className={"bg-white border border-gray-600 h-[30px] w-auto"}
              onClick={() => {
                document.getElementById("approval_id").close();
              }}
            />
            <ButtonWithIcon
              icon={BiPlus}
              buttonText={"Save Approval ID"}
              onClick={handleCreateApprovalId}
            />
          </div>
        </div>
      </ModalManagement>
    </div>
  );
};

export default ApprovalIdModal;
