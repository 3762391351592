export const operatorsData = [
  {
    operator: "Operator A",
    totalItems: 100,
    uploadDate: "2024-06-01",
    uploadId: "NEPL0001",
    projectId: "DDV2091",
    materialName: "Offcut, Conductor Pipe",
    quantity: 100,
    totalValue: {
      NGN: "₦53,202",
      USD: "$53,202",
    },
    totalFreightValue: {
      NGN: "₦53,202",
      USD: "$53,202",
    },
    component: "CMPT5443",
    batchNumber: "BT1223",
    dimension: "DM544",
    classification: "CL544",
    connectionType: "CL544",
    material: "MT544",
    capacity: "CP544",
    location: "Lagos",
    purchaseDate: "April 10, 2023"
  },
  {
    operator: "Operator B",
    totalItems: 150,
    uploadDate: "2024-06-02",
    uploadId: "NEPL0002",
    projectId: "DDV2092",
    materialName: "BEARING,PB",
    quantity: 100,
    totalValue: {
      NGN: "₦53,202",
      USD: "$53,202",
    },
    totalFreightValue: {
      NGN: "₦53,202",
      USD: "$53,202",
    },
    component: "CMPT5444",
    batchNumber: "BT1224",
    dimension: "DM545",
    classification: "CL545",
    connectionType: "CL545",
    material: "MT545",
    capacity: "CP545",
    location: "Lagos",
    purchaseDate: "April 10, 2023"
  },
  {
    operator: "Operator C",
    totalItems: 200,
    uploadDate: "2024-06-03",
    uploadId: "NEPL0003",
    projectId: "DDV2093",
    materialName: "Hanger, Casing",
    quantity: 100,
    totalValue: {
      NGN: "₦53,202",
      USD: "$53,202",
    },
    totalFreightValue: {
      NGN: "₦53,202",
      USD: "$53,202",
    },
    component: "CMPT5445",
    batchNumber: "BT1225",
    dimension: "DM546",
    classification: "CL546",
    connectionType: "CL546",
    material: "MT546",
    capacity: "CP546",
    location: "Lagos",
    purchaseDate: "April 10, 2023"
  },
  {
    operator: "Operator A",
    totalItems: 100,
    uploadDate: "2024-06-04",
    uploadId: "NEPL0004",
    projectId: "DDV2094",
    materialName: "Offcut, Conductor Pipe",
    quantity: 100,
    totalValue: {
      NGN: "₦53,202",
      USD: "$53,202",
    },
    totalFreightValue: {
      NGN: "₦53,202",
      USD: "$53,202",
    },
    component: "CMPT5443",
    batchNumber: "BT1223",
    dimension: "DM544",
    classification: "CL544",
    connectionType: "CL544",
    material: "MT544",
    capacity: "CP544",
    location: "Lagos",
    purchaseDate: "April 10, 2023"
  },
  {
    operator: "Operator B",
    totalItems: 150,
    uploadDate: "2024-06-05",
    uploadId: "NEPL0005",
    projectId: "DDV2095",
    materialName: "BEARING,PB",
    quantity: 100,
    totalValue: {
      NGN: "₦53,202",
      USD: "$53,202",
    },
    totalFreightValue: {
      NGN: "₦53,202",
      USD: "$53,202",
    },
    component: "CMPT5444",
    batchNumber: "BT1224",
    dimension: "DM545",
    classification: "CL545",
    connectionType: "CL545",
    material: "MT545",
    capacity: "CP545",
    location: "Lagos",
    purchaseDate: "April 10, 2023"

  },
  {
    operator: "Operator C",
    totalItems: 200,
    uploadDate: "2024-06-06",
    uploadId: "NEPL0006",
    projectId: "DDV2096",
    materialName: "Hanger, Casing",
    quantity: 100,
    totalValue: {
      NGN: "₦53,202",
      USD: "$53,202",
    },
    totalFreightValue: {
      NGN: "₦53,202",
      USD: "$53,202",
    },
    component: "CMPT5445",
    batchNumber: "BT1225",
    dimension: "DM546",
    classification: "CL546",
    connectionType: "CL546",
    material: "MT546",
    capacity: "CP546",
    location: "Lagos",
    purchaseDate: "April 10, 2023"
  },
  {
    operator: "Operator A",
    totalItems: 100,
    uploadDate: "2024-06-07",
    uploadId: "NEPL0007",
    projectId: "DDV2097",
    materialName: "Offcut, Conductor Pipe",
    quantity: 100,
    totalValue: {
      NGN: "₦53,202",
      USD: "$53,202",
    },
    totalFreightValue: {
      NGN: "₦53,202",
      USD: "$53,202",
    },
    component: "CMPT5443",
    batchNumber: "BT1223",
    dimension: "DM544",
    classification: "CL544",
    connectionType: "CL544",
    material: "MT544",
    capacity: "CP544",
    location: "Lagos",
    purchaseDate: "April 10, 2023"
  },
  {
    operator: "Operator B",
    totalItems: 150,
    uploadDate: "2024-06-08",
    uploadId: "NEPL0008",
    projectId: "DDV2098",
    materialName: "BEARING,PB",
    quantity: 100,
    totalValue: {
      NGN: "₦53,202",
      USD: "$53,202",
    },
    totalFreightValue: {
      NGN: "₦53,202",
      USD: "$53,202",
    },
    component: "CMPT5444",
    batchNumber: "BT1224",
    dimension: "DM545",
    classification: "CL545",
    connectionType: "CL545",
    material: "MT545",
    capacity: "CP545",
    location: "Lagos",
    purchaseDate: "April 10, 2023"

  },
  {
    operator: "Operator C",
    totalItems: 200,
    uploadDate: "2024-06-09",
    uploadId: "NEPL0009",
    projectId: "DDV2099",
    materialName: "Hanger, Casing",
    quantity: 100,
    totalValue: {
      NGN: "₦53,202",
      USD: "$53,202",
    },
    totalFreightValue: {
      NGN: "₦53,202",
      USD: "$53,202",
    },
    component: "CMPT5445",
    batchNumber: "BT1225",
    dimension: "DM546",
    classification: "CL546",
    connectionType: "CL546",
    material: "MT546",
    capacity: "CP546",
    location: "Lagos",
    purchaseDate: "April 10, 2023"
  },
  {
    operator: "Operator A",
    totalItems: 100,
    uploadDate: "2024-06-10",
    uploadId: "NEPL0010",
    projectId: "DDV2100",
    materialName: "Offcut, Conductor Pipe",
    quantity: 100,
    totalValue: {
      NGN: "₦53,202",
      USD: "$53,202",
    },
    totalFreightValue: {
      NGN: "₦53,202",
      USD: "$53,202",
    },
    component: "CMPT5443",
    batchNumber: "BT1223",
    dimension: "DM544",
    classification: "CL544",
    connectionType: "CL544",
    material: "MT544",
    capacity: "CP544",
    location: "Lagos",
    purchaseDate: "April 10, 2023"
  },
  {
    operator: "Operator B",
    totalItems: 150,
    uploadDate: "2024-06-11",
    uploadId: "NEPL0011",
    projectId: "DDV2101",
    materialName: "BEARING,PB",
    quantity: 100,
    totalValue: {
      NGN: "₦53,202",
      USD: "$53,202",
    },
    totalFreightValue: {
      NGN: "₦53,202",
      USD: "$53,202",
    },
    component: "CMPT5444",
    batchNumber: "BT1224",
    dimension: "DM545",
    classification: "CL545",
    connectionType: "CL545",
    material: "MT545",
    capacity: "CP545",
    location: "Lagos",
    purchaseDate: "April 10, 2023"

  },
  {
    operator: "Operator C",
    totalItems: 200,
    uploadDate: "2024-06-12",
    uploadId: "NEPL0012",
    projectId: "DDV2102",
    materialName: "Hanger, Casing",
    quantity: 100,
    totalValue: {
      NGN: "₦53,202",
      USD: "$53,202",
    },
    totalFreightValue: {
      NGN: "₦53,202",
      USD: "$53,202",
    },
    component: "CMPT5445",
    batchNumber: "BT1225",
    dimension: "DM546",
    classification: "CL546",
    connectionType: "CL546",
    material: "MT546",
    capacity: "CP546",
    location: "Lagos",
    purchaseDate: "April 10, 2023"
  },
  {
    operator: "Operator A",
    totalItems: 100,
    uploadDate: "2024-06-13",
    uploadId: "NEPL0013",
    projectId: "DDV2103",
    materialName: "Offcut, Conductor Pipe",
    quantity: 100,
    totalValue: {
      NGN: "₦53,202",
      USD: "$53,202",
    },
    totalFreightValue: {
      NGN: "₦53,202",
      USD: "$53,202",
    },
    component: "CMPT5443",
    batchNumber: "BT1223",
    dimension: "DM544",
    classification: "CL544",
    connectionType: "CL544",
    material: "MT544",
    capacity: "CP544",
    location: "Lagos",
    purchaseDate: "April 10, 2023"
  },
  {
    operator: "Operator B",
    totalItems: 150,
    uploadDate: "2024-06-14",
    uploadId: "NEPL0014",
    projectId: "DDV2104",
    materialName: "BEARING,PB",
    quantity: 100,
    totalValue: {
      NGN: "₦53,202",
      USD: "$53,202",
    },
    totalFreightValue: {
      NGN: "₦53,202",
      USD: "$53,202",
    },
    component: "CMPT5444",
    batchNumber: "BT1224",
    dimension: "DM545",
    classification: "CL545",
    connectionType: "CL545",
    material: "MT545",
    capacity: "CP545",
    location: "Lagos",
    purchaseDate: "April 10, 2023"

  },
  {
    operator: "Operator C",
    totalItems: 200,
    uploadDate: "2024-06-15",
    uploadId: "NEPL0015",
    projectId: "DDV2105",
    materialName: "Hanger, Casing",
    quantity: 100,
    totalValue: {
      NGN: "₦53,202",
      USD: "$53,202",
    },
    totalFreightValue: {
      NGN: "₦53,202",
      USD: "$53,202",
    },
    component: "CMPT5445",
    batchNumber: "BT1225",
    dimension: "DM546",
    classification: "CL546",
    connectionType: "CL546",
    material: "MT546",
    capacity: "CP546",
    location: "Lagos",
    purchaseDate: "April 10, 2023"
  },
  {
    operator: "Operator A",
    totalItems: 100,
    uploadDate: "2024-06-16",
    uploadId: "NEPL0016",
    projectId: "DDV2106",
    materialName: "Offcut, Conductor Pipe",
    quantity: 100,
    totalValue: {
      NGN: "₦53,202",
      USD: "$53,202",
    },
    totalFreightValue: {
      NGN: "₦53,202",
      USD: "$53,202",
    },
    component: "CMPT5443",
    batchNumber: "BT1223",
    dimension: "DM544",
    classification: "CL544",
    connectionType: "CL544",
    material: "MT544",
    capacity: "CP544",
    location: "Lagos",
    purchaseDate: "April 10, 2023"
  },
  {
    operator: "Operator B",
    totalItems: 150,
    uploadDate: "2024-06-17",
    uploadId: "NEPL0017",
    projectId: "DDV2107",
    materialName: "BEARING,PB",
    quantity: 100,
    totalValue: {
      NGN: "₦53,202",
      USD: "$53,202",
    },
    totalFreightValue: {
      NGN: "₦53,202",
      USD: "$53,202",
    },
    component: "CMPT5444",
    batchNumber: "BT1224",
    dimension: "DM545",
    classification: "CL545",
    connectionType: "CL545",
    material: "MT545",
    capacity: "CP545",
    location: "Lagos",
    purchaseDate: "April 10, 2023"

  },
  {
    operator: "Operator C",
    totalItems: 200,
    uploadDate: "2024-06-18",
    uploadId: "NEPL0018",
    projectId: "DDV2108",
    materialName: "Hanger, Casing",
    quantity: 100,
    totalValue: {
      NGN: "₦53,202",
      USD: "$53,202",
    },
    totalFreightValue: {
      NGN: "₦53,202",
      USD: "$53,202",
    },
    component: "CMPT5445",
    batchNumber: "BT1225",
    dimension: "DM546",
    classification: "CL546",
    connectionType: "CL546",
    material: "MT546",
    capacity: "CP546",
    location: "Lagos",
    purchaseDate: "April 10, 2023"
  },
  {
    operator: "Operator A",
    totalItems: 100,
    uploadDate: "2024-06-19",
    uploadId: "NEPL0019",
    projectId: "DDV2109",
    materialName: "Offcut, Conductor Pipe",
    quantity: 100,
    totalValue: {
      NGN: "₦53,202",
      USD: "$53,202",
    },
    totalFreightValue: {
      NGN: "₦53,202",
      USD: "$53,202",
    },
    component: "CMPT5443",
    batchNumber: "BT1223",
    dimension: "DM544",
    classification: "CL544",
    connectionType: "CL544",
    material: "MT544",
    capacity: "CP544",
    location: "Lagos",
    purchaseDate: "April 10, 2023"
  },
  {
    operator: "Operator B",
    totalItems: 150,
    uploadDate: "2024-06-20",
    uploadId: "NEPL0020",
    projectId: "DDV2110",
    materialName: "BEARING,PB",
    quantity: 100,
    totalValue: {
      NGN: "₦53,202",
      USD: "$53,202",
    },
    totalFreightValue: {
      NGN: "₦53,202",
      USD: "$53,202",
    },
    component: "CMPT5444",
    batchNumber: "BT1224",
    dimension: "DM545",
    classification: "CL545",
    connectionType: "CL545",
    material: "MT545",
    capacity: "CP545",
    location: "Lagos",
    purchaseDate: "April 10, 2023"
  },
];
