import React, { useState } from "react";
import GoBackButton from "../../../generalComponents/GoBackButton";
import ItemsTable from "../../events/components/ItemsTable";
import CountdownTimer from "../../../generalComponents/CountDownTimer";
import { info } from "../../../assets/icons";
import CustomButton from "../../../generalComponents/Button";
import BaseDashboardNavigation from "../../../generalComponents/BaseDashboardNavigation";

const LotItemDetails = ({ background }) => {
  const [currentView, setCurrentView] = useState(0);
  return (
    <BaseDashboardNavigation>
    <div
      className={`max-w-[1240px] w-full flex flex-col text-blackColor ${background}   md:mx-auto  mx-4  relative`}
    >
      <div
        className={`justify-between items-center flex flex-col mt-10 ${background} py-6 w-full sticky top-0 z-10`}
      >
        <GoBackButton />
        <h3 className="text-28px font-semibold w-full text-left leading-tight mt-8">
          NIPEX Disposal
        </h3>
        <h3 className="text-14px text-[#667085] w-full text-left  ">
          Sale Type: Online Auction
        </h3>
      </div>
      <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-20 mb-20">
        <div className="flex flex-col">
          <ItemsTable />
          <div className="flex items-center w-full justify-start relative gap-0 mt-5">
            {["Description", "Payment Information", "Other Information"].map(
              (el, i) => (
                <p
                  key={i}
                  role="button"
                  onClick={() => setCurrentView(i)}
                  className={`text-13px pb-2 px-10 ${
                    currentView === i
                      ? "font-medium text-brandGreen border border-transparent border-b-2 border-b-brandGreen"
                      : "text-textGrey2"
                  }`}
                >
                  {el}
                </p>
              )
            )}
            <div className="divider divider-[#E4E7EC] inset-y-0 absolute top-1.5 w-full"></div>
          </div>
          <h3 className="text-14px mt-4">
            Lorem ipsum dolor sit amet consectetur. Est elementum mattis quam
            fringilla. Commodo purus cras purus elementum ultrices dictum.
            Tellus mollis augue enim metus tortor enim lorem cras. Orci tellus
            pellentesque est mauris sed orci. Eu et pellentesque et id eget.
            Lorem dapibus neque enim diam viverra ut morbi nam. Tincidunt sit
            nec sed semper. Libero est sed euismod dolor eros mattis quam diam
            vestibulum. Imperdiet aliquam eget lorem metus. Pharetra sed et amet
            vitae. Elementum amet at accumsan massa massa varius habitant
            ultrices. Penatibus euismod mattis sit orci. Egestas vitae platea
            amet nisi aliquam ut quis. Porta felis augue lectus blandit
            tristique. Nulla ultrices pellentesque sapien ac diam purus. Aenean
            gravida tincidunt morbi velit scelerisque cras ultrices sit libero.
            Dui cursus aliquam dictumst porttitor eget mauris quis. Id eleifend
            feugiat leo urna augue netus eget quam enim. Viverra at eros
            maecenas ac orci varius. Tortor erat mattis sit quis adipiscing
            nulla ullamcorper. Euismod iaculis euismod risus sit porta at sit
            lectus velit. Faucibus in ante nam aliquam. Eget egestas ultrices
            scelerisque enim porta vel nunc neque. Condimentum dignissim semper
            viverra hendrerit. Massa mattis bibendum iaculis nunc amet quis.
            Suspendisse nisi nibh pulvinar amet nec velit risus ut vel. Bibendum
            pharetra rhoncus magna in. Ultrices adipiscing at id morbi
            vestibulum dictum. Augue orci aliquet vitae ultrices. Sit in
            facilisis dignissim pellentesque sed cursus. Diam vitae commodo nisl
            massa nullam morbi pellentesque pulvinar. Fusce eu cursus sed tortor
            congue semper neque. Vivamus nulla pellentesque eu pellentesque
            aliquam. Sed nunc purus volutpat sem nibh turpis. Volutpat lacus sit
            laoreet cras.
          </h3>
        </div>
        {/* the countdown stuff */}
        <div className="sticky top-36 max-w-[446px] rounded-[6px] h-fit min-h-[537px] border border-greyBackground flex flex-col items-center justify-center gap-8 px-10">
          <div className="flex flex-col w-full items-center justify-center">
            <h3 className="text-[30px] font-semibold text-brandGreen">
              ₦48,000
            </h3>
            <h3 className="text-16px font-semibold">
              Current highest bid (3 bids)
            </h3>
          </div>
          <div className="flex flex-col w-full items-center justify-center">
            <div className="h-[76px] bg-[#F5F6F8] rounded-[6px] flex gap-6 max-w-[357px] w-full items-center justify-center">
              <CountdownTimer />
            </div>
            <h3 className="text-16px font-medium text-[#667085]">
              Ending on: January 12, 2024. 3:45 pm
            </h3>
          </div>
          <div className="w-full max-w-[357px] h-[41px] rounded-[6px] bg-[#EEF7E7] flex items-center justify-center gap-2">
            <img src={info} alt="Item" />
            <h3 className="text-16px font-medium text-textGrey">
              Auction Fee:
            </h3>
            <h3 className="text-16px font-se,o text-black">₦1000</h3>
          </div>
          <div className="h-[77px] max-w-[357px] w-full p-3 items-start justify-between border border-[#D0D5DD] rounded-[6px] flex flex-col">
            <input
              type="text"
              className="placeholder:text-textGrey text-14px"
              placeholder="Enter your bid (Minimum ₦1000)"
            />
            <p className="text-brandGreen text-16px font-semibold">
              Enter your bid (Minimum ₦1000){" "}
              <span className="font-medium text-black">0.00</span>
            </p>
          </div>
          <CustomButton
            buttonText={`Pay Auction Fee`}
            className={`w-full max-w-[357px]`}
          />
        </div>
      </div>
    </div>
    </BaseDashboardNavigation>
  );
};

export default LotItemDetails;
