import { useState, useEffect } from "react";
import ModalManagement from "../../../generalComponents/ModalManagement";
import InputWithFullBoarder from "../../../generalComponents/InputWithFullBoarder";
import ButtonWithIcon from "../../../generalComponents/ButtonWithIcon";
import { BiPlus } from "react-icons/bi";
import { PurchaseSalesManager } from "../controllers/purchaseSalesController";

const PurchaseModal = ({ stockId, assetName, warehouse, quantity }) => {
  const [purchaseAmount, setPurchaseAmount] = useState(0);

  const { purchaseSales, isSuccess } = PurchaseSalesManager( stockId);


  const handlePurchase = () => {
    const payload = {
      price: purchaseAmount,
    };

    purchaseSales(payload);
  };

  useEffect(() => {
    if (isSuccess) {
      document.getElementById("purchase_asset").close();
    }
  }, [isSuccess]);

  return (
    <div>
      <ModalManagement id={"purchase_asset"} title={"Purchase Asset"}  >
        <div className="md:w-[599px] h-auto rounded-[6px] relative bg-white flex flex-col pt-2">
          <div className="flex flex-col md:flex-row md:space-x-2">
            <div className="flex-1">
              <InputWithFullBoarder
                label={"Asset Name"}
                value={assetName}
                disabled
              />
            </div>
          </div>
          <div className="flex flex-col md:flex-row md:space-x-2">
            <div className="flex-1">
              <InputWithFullBoarder
                label={"Warehouse"}
                value={warehouse}
                disabled
              />
            </div>
          </div>
          <div className="flex flex-col md:flex-row md:space-x-2">
            <div className="flex-1">
              <InputWithFullBoarder
                label={"Quantity"}
                value={quantity}
                disabled
              />
            </div>
          </div>
          <div className="flex flex-col md:flex-row md:space-x-2">
            <div className="flex-1">
              <InputWithFullBoarder
                label={"Enter Purchase Amount"}
                placeholder={"Enter Purchase Amount"}
                type={"number"}
                value={purchaseAmount}
                onChange={(e) => setPurchaseAmount(e.target.value)}
                required
              />
            </div>
          </div>
          
          {/* <div className="flex flex-col md:flex-row md:space-x-2">
            <div className="flex-1">
              <SelectWithFullBorder
                label={"Select Exchange Asset"}
                selectOptions={
                  stockItems?.data?.results?.map((stock) => ({
                    label: stock?.asset_name,
                    value: stock?.stock_id,
                  })) || []
                }
                value={selectedExchangeAsset}
                onChange={(e) => setSelectedExchangeAsset(e.target.value)}
                placeholder="Select Exchange Asset"
              />
            </div>
          </div> */}
          {/* <div className="flex flex-col md:flex-row md:space-x-2">
            <div className="flex-1">
              <InputWithFullBoarder
                label={"Purchase Amount"}
                placeholder={"Enter Amount"}
                type={"number"}
                value={purchaseAmount}
                onChange={(e) => setPurchaseAmount(e.target.value)}
                required
              />
            </div>
          </div> */}
          {/* <div className="flex flex-col md:flex-row md:space-x-2">
            <div className="flex-1">
              <SelectWithFullBorder
                selectOptions={[
                  { label: "Select Warehouse", value: "" },
                  ...(wareHouses?.data?.results?.map((warehouse) => ({
                    label: warehouse?.name,
                    value: warehouse?.id,
                  })) || []),
                ]}
                value={selectedWarehouse}
                onChange={(e) => setSelectedWarehouse(e.target.value)}
                placeholder="Select Warehouse"
                label="Select Warehouse"
              />
            </div>
          </div> */}
          <div className="flex flex-col md:flex-row md:space-x-2 justify-end">
            <ButtonWithIcon
              icon={BiPlus}
              buttonText={"Cancel"}
              textColor={"#344054"}
              className={"bg-white border border-gray-600 h-[40px] w-auto"}
              onClick={() => {
                document.getElementById("purchase_asset").close();
              }}
            />
            <ButtonWithIcon
              icon={BiPlus}
              buttonText={"Purchase Asset"}
              onClick={handlePurchase}
            />
          </div>
        </div>
      </ModalManagement>
    </div>
  );
};

export default PurchaseModal;
