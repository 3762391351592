import React from "react";
import CustomButton from "../../../generalComponents/Button";
import CustomCheckBox from "../../../generalComponents/CustomCheckBox";
import InputWithFullBoarder from "../../../generalComponents/InputWithFullBoarder";
import ModalManagement from "../../../generalComponents/ModalManagement";
import { auctionCertificate } from "../../../assets/images";

const SoldItemsModals = () => {
  return (
    <div>
      {/* ADD LOT MODAL */}
      <ModalManagement id={"add_lot"} title={"Add Lot"}>
        <div className="md:w-[599px] h-auto rounded-[6px] relative bg-white flex flex-col pt-6">
          <div className="flex flex-col md:flex-row md:space-x-2">
            <div className="flex-1">
              <InputWithFullBoarder
                label={"Lot Name"}
                placeholder={"Enter Lot Name"}
              />
            </div>
            <div className="flex-1">
              <InputWithFullBoarder
                label={"Lot Number"}
                placeholder={"Lot Number"}
              />
            </div>
          </div>
          <div className="flex flex-col md:flex-row md:space-x-2">
            <div className="flex-1">
              <InputWithFullBoarder
                label={"Bid Event"}
                placeholder={"Bid Event"}
              />
            </div>
            <div className="flex-1">
              <InputWithFullBoarder
                label={"Location"}
                placeholder={"Location"}
              />
            </div>
          </div>
          <div className="flex flex-col md:flex-row md:space-x-2">
            <div className="flex-1">
              <InputWithFullBoarder
                label={"Start Price"}
                placeholder={"Start Price"}
              />
            </div>
            <div className="flex-1">
              <InputWithFullBoarder
                label={"Lot Image"}
                placeholder={"Lot Image"}
              />
            </div>
          </div>
          <div className="flex flex-col md:flex-row md:space-x-2">
            <div className="flex-1">
              <InputWithFullBoarder
                label={"Reserve Price"}
                placeholder={"Reserve Price"}
              />
            </div>
            <div className="flex-1">
              <InputWithFullBoarder label={"Bid Increment"} placeholder={"Bid Increment"} />
            </div>
          </div>
          <div className="flex flex-col md:flex-row md:space-x-2">
            <div className="flex-1">
              <InputWithFullBoarder
                label={"Currency"}
                placeholder={"Currency"}
              />
            </div>
            <div className="flex-1">
              <InputWithFullBoarder label={"Sales Type"} placeholder={"Sales Type"} />
            </div>
          </div>
          <div className="flex flex-col md:flex-row md:space-x-2">
            <div className="flex-1">
              <InputWithFullBoarder
                label={"Premium (%)"}
                placeholder={"Premium"}
              />
            </div>
            <div className="flex-1">
              <InputWithFullBoarder label={"Description"} placeholder={"Sales Type"} />
            </div>
          </div>
          <div className="flex space-x-4">
            <div className="flex-1">
              <CustomCheckBox text={"Featured"} />
            </div>
            <div className="flex-1">
              <CustomCheckBox text={"Live Auction"} />
            </div>
          </div>
          <div className="flex justify-end space-x-2 mt-4 w-full">
            <CustomButton
              buttonText={"Cancel"}
              textColor={"#344054"}
              className="bg-white border border-gray-600"
            />
            <CustomButton buttonText={"Next"} />
          </div>
        </div>
      </ModalManagement>

            {/* SHARE LOT MODAL */}
            <ModalManagement id={"share_lot"} title={"Share"}>
        <div className="md:w-[599px] h-auto rounded-[6px] relative bg-white flex flex-col pt-6">
          <div className="flex flex-col md:flex-row md:space-x-2">
            <div className="flex-1">
              <InputWithFullBoarder
                label={"Enter Email(s) "}
                placeholder={"Enter Email Address"}
              />
            </div>
          </div>
          <div className="flex flex-col md:flex-row md:space-x-2">
            <div className="flex-1">
              <InputWithFullBoarder
                label={"Link to Lot"}
                placeholder={"https://emmes-..."}
              />
            </div>
          </div>
          <div className="flex justify-end space-x-2 mt-4 w-full">
            <CustomButton
              buttonText={"Cancel"}
              textColor={"#344054"}
              className="bg-white border border-gray-600"
            />
            <CustomButton buttonText={"Share"} />
          </div>
        </div>
      </ModalManagement>


      {/* DELETE USER MODAL */}
      <ModalManagement id={"delete_sold_items"} title={"Delete Lot"}>
        <div className="w-[456px] h-auto rounded-[6px] relative bg-white flex flex-col py-3">
          <p className="text=[14px]">
            Are you sure you want to delete this item?
          </p>

          <div className="flex justify-end space-x-2 mt-4 w-full">
            <CustomButton
              buttonText={"Cancel"}
              textColor={"#344054"}
              className="bg-white border border-gray-600"
            />
            <CustomButton buttonText={"Continue"} />
          </div>
        </div>
      </ModalManagement>

      {/* CERTIFICATE MODAL */}
      <ModalManagement id={"certificate"} >
        <div className="w-[987px] h-auto rounded-[6px] relative bg-white flex flex-col ">
          <img src={auctionCertificate} alt="Certificate" />
          
        </div>
      </ModalManagement>
    </div>
  );
};

export default SoldItemsModals;
