import React from "react";
import ProjectItems from "./stockItems/view/ProjectItems";
import MarketItems from "./stockItems/view/MarketItems";
import Tabs from "../../generalComponents/Tabs";
import BaseDashboardNavigation from "../../generalComponents/BaseDashboardNavigation";
import ButtonWithIcon from "../../generalComponents/ButtonWithIcon";
import { BiPlusCircle } from "react-icons/bi";
import StockItems from "./stockItems/view/StockItems";
import HeadersMapping from "./headerMapping/view/HeadersMapping";
import StockUpload from "./stockUpload/view/StockUpload";
import UploadBatches from "./uploadHistory/view/UploadBatchesPage";
import { FaUpload } from "react-icons/fa";
import { hasPermissions } from "../../constants/permissions";

const InventoryPage = () => {
  const tabsData = [
    { label: "Stock Items", component: <StockItems /> },
    { label: "Project Items", component: <ProjectItems /> },
    { label: "Market Items", component: <MarketItems /> },
    { label: "Upload History", component: <UploadBatches /> },
  ];

  return (
    <BaseDashboardNavigation>
      <div>
        <div className="flex flex-col md:flex-row items-start md:items-center justify-between w-full">
          <div className="flex items-center mb-2 md:mb-0">
            <h3 className="text-[24px] font-bold">Inventory Management</h3>
          </div>
          <div className="flex items-center space-x-[10px] mt-1 md:mt-0">
            <div
              className={`${
                hasPermissions(["add_stockheader"])
                  ? "flex items-center space-x-[10px] mt-1 md:mt-0"
                  : "hidden"
              } `}
            >
              <ButtonWithIcon
                buttonText={"Map Stock Headers"}
                className={
                  "bg-transparent text-brandGreen border border-brandGreen"
                }
                textColor={"#358619"}
                radius={"md"}
                icon={BiPlusCircle}
                onClick={() => {
                  document.getElementById("map_headers").showModal();
                }}
              />
            </div>
            <div
              className={`${
                hasPermissions(["add_stock"])
                  ? "flex items-center space-x-[10px] mt-1 md:mt-0"
                  : "hidden"
              } `}
            >
              <ButtonWithIcon
                buttonText={"Upload Inventory"}
                radius={"md"}
                icon={FaUpload}
                onClick={() => {
                  document.getElementById("stock_upload").showModal();
                }}
              />
            </div>
          </div>
        </div>

        <Tabs tabsData={tabsData} />
      </div>
      <HeadersMapping />
      <StockUpload />
    </BaseDashboardNavigation>
  );
};

export default InventoryPage;
