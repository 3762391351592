import React from "react";
import BaseDashboardNavigation from "../../../generalComponents/BaseDashboardNavigation";
import Tabs from "../../../generalComponents/Tabs";
import IncomingInspections from "../components/IncomingInspections";
import OutgoingInspections from "../components/OutgoingInspections";

const Inspections = () => {
  const tabsData = [
    { label: "Incoming Inspections", component: <IncomingInspections /> },
    { label: "Outgoing Inspections", component: <OutgoingInspections /> },
  ];

  return (
    <BaseDashboardNavigation>
      <div>
        <h3 className="text-[24px] font-bold">Inspections</h3>
        <Tabs tabsData={tabsData} />
      </div>
    </BaseDashboardNavigation>
  );
};

export default Inspections;
