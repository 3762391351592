import React, { useState } from "react";
import PaginationRounded from "../../../generalComponents/Pagination";
import TablesComponent from "../../../generalComponents/TablesComponent";
import BaseDashboardNavigation from "../../../generalComponents/BaseDashboardNavigation";
import useDebounce from "../../../utils/UseDebounce";
import SearchField from "../../../generalComponents/SearchField";
import { calculatePaginationRange } from "../../../utils/calculatePaginationRange";
import { noImage } from "../../../assets/images";
import useGetAuctionItemsManager from "../../lots/controllers/getAuctionItemsController";
import truncateWithTooltip from "../../../utils/truncateText";

const AuctionItems = () => {
  const [setCurrentPage] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const debouncedSearchValue = useDebounce(`&search=${searchValue}`, 1000);

  const { data, isLoading } = useGetAuctionItemsManager({
    searchQuery: debouncedSearchValue,
    enabled: true,
    status: "LEVEL_THREE_APPROVED",
  });

  const { startItem, endItem } = calculatePaginationRange(
    data?.data?.pagination
  );

  const headers = [
    "Image",
    "Asset Name",
    "Asset Code",
    "Material",
    "NUIMS Quantity",
    "Operator Quantity",
  ];

  const getFormattedValue = (el, index) => {
    return [
      <span className="w-14 rounded-full">
        <img
          src={el?.stock?.image || noImage}
          className="rounded-full w-12 h-12"
          alt="Item"
        />
      </span>,
      truncateWithTooltip(el?.stock?.asset_name),
      el?.stock?.asset_code,
      el?.stock?.material,
      el?.nuims_quantity,
      el?.operator_quantity,
    ];
  };

  return (
    <BaseDashboardNavigation>
      <div>
        <div className="flex flex-col md:flex-row items-start md:items-center justify-between w-full">
          <div className="flex items-center mb-2 md:mb-0">
            <h3 className="text-[24px] font-bold">Auction Items</h3>
          </div>
        </div>
        <div className="mt-2 md:mt-6 flex flex-col w-full gap-4">
          <div className="flex flex-col md:flex-row justify-between items-center">
            <div className="">
              <SearchField
                onChange={(e) => setSearchValue(e.target.value)}
                value={searchValue}
              />{" "}
            </div>
            <div className="flex space-x-[10px] mt-3 md:mt-0">
              {/* <ButtonWithIcon
                buttonText={"Create Lot"}
                radius={"md"}
                icon={BiPlus}
                onClick={() => {
                  // document.getElementById("create_lot").showModal();
                  navigate("/lots/create-lot");
                }}
              /> */}
            </div>
          </div>
          <div className="h-[67vh] w-full relative">
            {
              <TablesComponent
                isLoading={isLoading}
                data={data?.data?.results}
                getFormattedValue={getFormattedValue}
                headers={headers}
                showCheckBox={false}
                hideActionButton
                // Close popup function
              />
            }
          </div>
          <div className="flex items-center justify-between mt-4">
            <p className="text-14px text-brandBlack">
              {" "}
              {startItem} - {endItem} of {data?.data?.pagination?.count} Events
            </p>
            <PaginationRounded
              defaultPage={data?.data?.pagination?.page}
              count={data?.data?.pagination?.total_pages}
              onChange={(page) => {
                setCurrentPage(page);
              }}
            />
          </div>
        </div>
      </div>
    </BaseDashboardNavigation>
  );
};

export default AuctionItems;
