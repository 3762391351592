import React from "react";
import BaseDashboardNavigation from "../../../generalComponents/BaseDashboardNavigation";
import Tabs from "../../../generalComponents/Tabs";
import MarketPlacePage from "../components/MarketPlacePage";
import IncomingExchange from "../components/IncomingExchange";
import OutgoingExchange from "../components/OutgoingExchange";
import IncomingSalesRequests from "../components/IncomingSalesRequests";
import OutgoingSalesRequests from "../components/OutgoingSalesRequests";


const Marketplace = () => {
  const tabsData = [
    { label: "Marketplace", component: <MarketPlacePage /> },
    { label: "Sales Request", component: <IncomingSalesRequests /> },
    { label: "Purchase Request", component: <OutgoingSalesRequests /> },
    { label: "Incoming Exchange", component: <IncomingExchange /> },
    { label: "Outgoing Exchange", component: <OutgoingExchange /> },
  ];

  return (
    <BaseDashboardNavigation>
      <div>
        <h3 className="text-[24px] font-bold">Marketplace</h3>
        <Tabs tabsData={tabsData} />
      </div>
    </BaseDashboardNavigation>
  );
};

export default Marketplace;
