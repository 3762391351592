// AssetDetails.js
import React, { useState } from "react";
import GridComponent from "../../../../generalComponents/GridComponent";

const AssetDetailsSection = ({ data }) => {
  const [isAssetDetailsOpen, setIsAssetDetailsOpen] = useState(true);
  const toggleAssetDetails = () => setIsAssetDetailsOpen(!isAssetDetailsOpen);

  return (
    <div>
        <div
      tabIndex={0}
      className={`collapse collapse-arrow border border-base-500 bg-white ${
        isAssetDetailsOpen ? "collapse-open" : "collapse-close"
      }`}
    >
      <div
        className="collapse-title text-xl font-medium"
        onClick={toggleAssetDetails}
      >
        <h3 className="text-[16px] text-[#181918] font-[500]">Asset Details</h3>
        <div className="border-t border-gray-200 mt-2"></div>
      </div>
      {isAssetDetailsOpen && <GridComponent data={data} />}
    </div>
        {/* <div
          tabIndex={0}
          className={`collapse collapse-arrow border border-base-500 bg-white mt-4 ${
            isHistoryOpen ? "collapse-open" : "collapse-close"
          }`}
        >
          <div
            className="collapse-title text-xl font-medium"
            onClick={toggleHistory}
          >
            <h3 className="text-[16px] text-[#181918] font-[500]">History</h3>
            <div className="border-t border-gray-200 mt-3"></div>
          </div>
          {isHistoryOpen && <Timeline items={historyItems} />}
        </div> */}
    </div>
    
  );
};

export default AssetDetailsSection;
