import useFormDataPostManager from "../../../../constants/controller_templates/post_formdata_controller_template";

export const UploadReceiptManager = (id) => {
  const { postCaller, data, isLoading, isSuccess, error } =
    useFormDataPostManager(`/auctions/${id}/transactions/receipts/upload/`, [
      "auction",
    ]);

  const submitReceipt = async (details) => {
    try {
      await postCaller(details);
    } catch (error) {
      console.error("error:", error);
    }
  };

  return {
    submitReceipt,
    data,
    isLoading,
    isSuccess,
    error,
  };
};
