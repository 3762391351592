import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { publicRoutes, protectedRoutes } from "./routes";
import { ToastContainer } from "react-toastify";
import { hasPermissions } from "./permissions";

const ProtectedRoutes = ({ isLoggedIn }) => {
  return (
    <div>
      <ToastContainer />
      <Routes>
        {/* Render public routes */}
        {publicRoutes.map((route, index) => (
          <Route key={index} path={route.path} element={route.element} />
        ))}

        {/* Render protected routes with permission check */}
        {protectedRoutes.map((route, index) => {
          // Here, you should specify the required permissions for each route
          const requiredPermissions = route.requiredPermissions || [];

          return (
            <Route
              key={index}
              path={route.path}
              element={
                // Check if user has required permissions for this route
                hasPermissions(requiredPermissions) ? (
                  route.element
                ) : (
                  <Navigate to="/not-permitted" replace />
                )
              }
            />
          );
        })}
      </Routes>
    </div>
  );
};

export default ProtectedRoutes;
