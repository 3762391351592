import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import AxiosWithToken from "../../../constants/api_management/MyHttpHelperWithToken";

const usePostReturnsManager = (endpoint, queryKey) => {
  const queryClient = useQueryClient();

  const postController = async (details) => {
    try {
      const response = await AxiosWithToken.post('/stock/returns/', details);
      return response.data;
    } catch (error) {
      // Throw the entire error object to handle it properly later
      throw error;
    }
  };

  const mutation = useMutation(postController, {
    onSuccess: async (data) => {
      toast.success(data.message);
      const updateQueryKeys = [queryKey];
      if (updateQueryKeys.length) {
        updateQueryKeys.forEach((key) => queryClient.invalidateQueries(key));
      }
    },
    onError: (error) => {
      // Handle error if necessary
      const errorMessage =
        error?.response?.data?.message || "An unexpected error occurred";
      toast.error(errorMessage);
      console.error("Post error:", error);
    },
  });

  const postCaller = async (details) => {
    try {
      await mutation.mutateAsync(details);
    } catch (error) {
      // Handle error if necessary
      console.error("Post error:", error);
      // Propagate the error to be caught by the component
      // throw error;
    }
  };

  return {
    postCaller,
    data: mutation.data,
    isLoading: mutation.isLoading,
    isSuccess: mutation.isSuccess,
    error: mutation.error,
  };
};

export default usePostReturnsManager;
