import React, { useState } from "react";

import PaginationRounded from "../../../generalComponents/Pagination";
import { operatorsData } from "../../../data/operatorsData";
import TablesComponent from "../../../generalComponents/TablesComponent";
import { useNavigate } from "react-router-dom";
import ButtonWithIcon from "../../../generalComponents/ButtonWithIcon";
import AvatarWithName from "../../../generalComponents/AvatarWithName";
import SearchField from "../../../generalComponents/SearchField";
import PageHeading from "../../../generalComponents/PageHeading";
import CustomDropdown from "../../../generalComponents/CustomDropDown";
import BaseDashboardNavigation from "../../../generalComponents/BaseDashboardNavigation";
import { IoMdAddCircleOutline } from "react-icons/io";
import GoBackButton from "../../../generalComponents/GoBackButton";
import ProductsModals from "../components/ProductsModals";
const ProductsCategoriesPage = () => {
  const [ setCurrentPage] = useState(1);
  const navigate = useNavigate();

  const headers = [
    `S/N`,
    "Name",
    "Created",
    "Modified",
    "Description",
    "Category Image",
    "Action",
  ];

  const getFormattedValue = (el, index) => {
    return [
      "001",
      "Pipe",
      "Apr 10, 2023",
      "Apr 10, 2023",
      <AvatarWithName />,
      "Pipe",
    ];
  };

  const options = ["Edit", "Delete"];

  return (
    <BaseDashboardNavigation>
      <div className="flex flex-col w-full gap-8 pt-5">
        <GoBackButton />
        <PageHeading title={"Products"} />
        <div className="flex md:flex-row flex-col justify-between items-center">
          <div className="flex items-center gap-3">
            <SearchField placeholder={"Search"} />
            <CustomDropdown placeholder={"Select Action"} />
          </div>
          <div className="flex items-center space-x-[10px] md:my-0 my-3">
            <ButtonWithIcon
              buttonText={"Add New Category"}
              icon={IoMdAddCircleOutline}
              radius={"md"}
              onClick={() => {
                document.getElementById('add_category').showModal();
              }}
            />

            <ButtonWithIcon buttonText={"Download Report"} radius={"md"} />
          </div>
        </div>
        <div className="h-[67vh] w-full relative">
          <TablesComponent
            // isLoading={isLoading}
            showCheckBox={false}
            data={operatorsData}
            getFormattedValue={getFormattedValue}
            headers={headers}
            options={options}
            buttonFunction={(val) => {
              console.log(val);
              navigate("/lots/lot/item");
            }}
            popUpFunction={(option, index, value) => {
              console.log(option, index, value);
              if (index === 0) {
                document.getElementById("add_category").showModal();
              }
              if (index === 1) {
                document.getElementById("delete_category").showModal();
              }
            }}
          />
        </div>
        <div className="flex items-center justify-between mt-4">
          <p className="text-14px text-brandBlack">1-10 of 195 items</p>
          <PaginationRounded
            defaultPage={1}
            count={100}
            onChange={(page) => {
              setCurrentPage(page);
            }}
          />
        </div>
      </div>
      <ProductsModals />
    </BaseDashboardNavigation>
  );
};
export default ProductsCategoriesPage;
