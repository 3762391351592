import React, { useState } from "react";

import { useNavigate } from "react-router-dom";
import TablesComponent from "../../../generalComponents/TablesComponent";
import PaginationRounded from "../../../generalComponents/Pagination";
import { operatorsData } from "../../../data/operatorsData";
import StatusButton from "../../../generalComponents/StatusButton";
import SearchField from "../../../generalComponents/SearchField";

const OpenTickets = () => {
  const [ setCurrentPage] = useState(1);
  const navigate = useNavigate();

  const headers = [
    "User",
    "Email",
    "Subject",
    "Date",
    "Status",
    "Action",
  ];

  const getFormattedValue = () => {
    return [
      "OLA",
      "ola1234@gmail.com",
      "Issue with Transaction",
      "Jan 11, 2024",
      <StatusButton status={"Pending"} />
    ];
  };

  return (
    <div className="mt-2 md:mt-6 flex flex-col w-full gap-4">
      <div className="flex flex-col md:flex-row justify-between items-center">
        <div className="flex items-center">
          <SearchField />
        </div>
      </div>
      <div className="h-[67vh] w-full relative">
        {
          <TablesComponent
            // isLoading={isLoading}
            data={operatorsData}
            getFormattedValue={getFormattedValue}
            headers={headers}
            buttonFunction={(val) => {
              navigate("/support/1");
              console.log(val);
            }}
            // Close popup function
          />
        }
      </div>
      <div className="flex items-center justify-between mt-4">
        <p className="text-14px text-brandBlack">1-10 of 195 items</p>
        <PaginationRounded
          defaultPage={1}
          count={100}
          onChange={(page) => {
            setCurrentPage(page);
          }}
        />
      </div>
    </div>
  );
};

export default OpenTickets;
