import React from "react";
import BaseDashboardNavigation from "../../../generalComponents/BaseDashboardNavigation";
import AllTransactions from "../components/AllTransactions";
import Tabs from "../../../generalComponents/Tabs";

const TransactionsPage = () => {
  const tabsData = [
    { label: "All Transactions", component: <AllTransactions /> },
  ];

  return (
    <BaseDashboardNavigation>
      <div className="flex flex-col w-full">
        <h3 className="text-[24px] font-bold my-0">Transactions</h3>
        <div className="w-full flex flex-col gap-7 sticky top-0 bg-[#F8F9FC]">
          <div className="flex justify-between items-center"></div>
        </div>
        <div className="h-[67vh] w-full relative">
          <Tabs tabsData={tabsData} />
        </div>
      </div>
    </BaseDashboardNavigation>
  );
};

export default TransactionsPage;
