import useUpdateManager from "../../../../constants/controller_templates/put_controller_template";

export const UpdateQuantityManager = ({ id }) => {
  const { updateCaller, isLoading, isSuccess, error, data } = useUpdateManager(
    `/stock/disposal/items/${id}/`,
    ["disposal"],
    false,
    true
  );
  
  const updateQuantity = async (details) => {
    try {
      await updateCaller(details);
    } catch (error) {
      console.error("error:", error);
    }
  };
  
  return {
    updateQuantity,
    data,
    isLoading,
    isSuccess,
    error,
  };
};