import { useQuery } from "react-query";
import AxiosWithToken from "../../../../constants/api_management/MyHttpHelperWithToken";

const useGetAuctionParticipants = (id) => {
  return useQuery(
    ["auction", id, "participants"],
    async () => {
      try {
        const [response] = [
          await AxiosWithToken.get(`/auctions/${id}/participants/profile/`),
        ];
        //console.log(`i am checking this ${response.status}`);
        return response.data;
      } catch (error) {
        //console.log(error.response.data);
        throw new Error(`Sorry: ${error.response.data.message}`);
      }
    },
    {
      enabled: !!id,
    }
  );
};

export default useGetAuctionParticipants;
