import usePostManager from "../../../constants/controller_templates/post_controller_template";

export const MarkAsReadManager = ({ id }) => {
  const { postCaller, isLoading, isSuccess, error, data } = usePostManager(
    `/notifications/${id}/`,
    ["notifications"],
    true
  );
  const mark = async () => {
    try {
      await postCaller();
    } catch (error) {
      console.error("error:", error);
    }
  };
  return {
    mark,
    data,
    isLoading,
    isSuccess,
    error,
  };
};
