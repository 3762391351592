import React, { useEffect, useState } from "react";
import ModalManagement from "../../../../generalComponents/ModalManagement";
import InputWithFullBoarder from "../../../../generalComponents/InputWithFullBoarder";
import CustomButton from "../../../../generalComponents/Button";
import SelectWithFullBorder from "../../../../generalComponents/SelectWithFullBorder";
import useGetWarehouseManager from "../../../warehouse/controllers/getWarehouseController";
import { CreateStockItemManager } from "../controllers/createStockItemController";
import { UpdateStockItemManager } from "../controllers/updateStockItemController";
import { formatName } from "../../../../utils/formatName";
import useGetProjectsManager from "../../../projects/controllers/getProjectsController";

const StockItemModal = ({ details }) => {
  const [currentPage] = useState(1);

  const initialData = {
    image: "",
    asset_name: "",
    asset_code: "",
    asset_class: "",
    asset_type: "",
    quantity: "",
    description: "",
    batch_number: "",
    component: "",
    unit: "",
    unit_cost_naira: "",
    unit_cost_dollar: "",
    serial_number: "",
    manufacturer: "",
    erp_number: "",
    model_number: "",
    dimension: "",
    classification: "",
    connection_type: "",
    capacity: "",
    storage_location: "",
    row: "",
    slot: "",
    warehouse: "",
    bin_number: "",
    project: "",
    date_of_purchase: "",
    book_value: "",
    tax_written_down_value: "",
    condition_of_material: "",
  };

  const [formData, setFormData] = useState(initialData);
  const [imageFile, setImageFile] = useState(null); // Step 2: Handle image state

  const { data: warehouses } = useGetWarehouseManager({
    enabled: true,
    page: currentPage,
  });
  const { data: projects } = useGetProjectsManager({
    enabled: true,
    page: currentPage,
  });

  const { createStockItem, isLoading, isSuccess } = CreateStockItemManager();

  const {
    updateStockItem,
    isLoading: updating,
    isSuccess: updated,
  } = UpdateStockItemManager({ id: details?.stock_id });

  useEffect(() => {
    if (details) {
      setFormData({
        asset_name: details?.asset_name,
        asset_code: details?.asset_code,
        quantity: details?.quantity,
        description: details?.description,
        batch_number: details?.batch_number,
        component: details?.component,
        unit: details?.unit,
        unit_cost_naira: details?.unit_cost_naira,
        unit_cost_dollar: details?.unit_cost_dollar,
        serial_number: details?.serial_number,
        manufacturer: details?.manufacturer,
        erp_number: details?.erp_number,
        model_number: details?.model_number,
        material: details?.material,
        dimension: details?.dimension,
        classification: details?.classification,
        connection_type: details?.connection_type,
        capacity: details?.capacity,
        storage_location: details?.storage_location,
        row: details?.row,
        slot: details?.slot,
        warehouse: details?.warehouse?.id,
        bin_number: details?.bin_number,
        project: details?.project?.id,
        date_of_purchase: details?.date_of_purchase,
        asset_class: details?.asset_class,
        asset_type: details?.asset_type,
        book_value: details?.book_value,
        tax_written_down_value: details?.tax_written_down_value,
        condition_of_material: details?.condition_of_material,
      });
    }
  }, [details]);

  const requiredFields = [
    "asset_name",
    "asset_code",
    "description",
    "quantity",
    "warehouse",
    "component",
    "unit",
    "unit_cost_naira",
    "unit_cost_dollar",
    "serial_number",
    "manufacturer",
    "model_number",
    "asset_class",
    "asset_type",
    "classification",
    "connection_type",
    "capacity",
    "storage_location",
    "condition_of_material",
  ];

  const handleCreateItem = async () => {
    const payload = {
      ...formData,
      image: imageFile,
    };

    if (details) {
      await updateStockItem(payload);
    } else {
      await createStockItem(payload);
    }
  };

  const handleInputChange = (e, isSelect = false) => {
    const name = isSelect ? e.name : e.target.name;
    const value = isSelect ? e.value : e.target.value;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImageFile(file); // Store the selected image file in the state
    }
  };

  useEffect(() => {
    if (isSuccess || updated) {
      document.getElementById("create_stock").close();
      setFormData("");
      setImageFile(null);
    }
  }, [isSuccess, updated]);

  return (
    <div>
      <ModalManagement
        id={"create_stock"}
        title={details ? "Update Item" : "Create Item"}
      >
        <div className="md:w-[80wv] h-auto rounded-[6px] relative bg-white flex flex-col pt-6">
          {/* Image Input Field */}
          <div className="">
            <label className="block text-sm font-medium text-gray-700">
              Upload Image
            </label>
            <input
              type="file"
              accept="image/*"
              onChange={handleImageChange}
              className="my-3 block w-full text-sm text-gray-900 rounded-md cursor-pointer focus:outline-none"
            />
          </div>
          <div className="grid grid-cols-4 gap-3">
            {Object.keys(formData).map((key) => {
              if (key === "warehouse") {
                return (
                  <SelectWithFullBorder
                    id="warehouse" // unique id for accessibility
                    label="Warehouse"
                    selectOptions={warehouses?.data?.results?.map(
                      (warehouse) => ({
                        label: warehouse?.name,
                        value: warehouse?.id,
                      })
                    )}
                    value={formData.warehouse}
                    onChange={(e) =>
                      handleInputChange(
                        { name: "warehouse", value: e.target.value },
                        true
                      )
                    } // Adjust onChange
                    placeholder="Select Warehouse"
                    required={requiredFields.includes("warehouse")}
                  />
                );
              }

              if (key === "project") {
                return (
                  <SelectWithFullBorder
                    id="project" // unique id for accessibility
                    label="Project"
                    selectOptions={projects?.data?.results?.map((project) => ({
                      label: project?.name,
                      value: project?.id,
                    }))}
                    value={formData.project}
                    onChange={(e) =>
                      handleInputChange(
                        { name: "project", value: e.target.value },
                        true
                      )
                    } // Adjust onChange
                    placeholder="Select Project"
                  />
                );
              }
              // Set the input type based on the key
              let inputType = "text"; // default type
              if (
                [
                  "quantity",
                  "unit",
                  "unit_cost_naira",
                  "unit_cost_dollar",
                  "book_value",
                  "tax_written_down_value",
                ].includes(key)
              ) {
                inputType = "number";
              } else if (key === "date_of_purchase") {
                inputType = "date";
              }
              return (
                <InputWithFullBoarder
                  name={key}
                  key={key}
                  label={formatName(key)}
                  placeholder={`Enter ${formatName(key)}`}
                  type={inputType}
                  value={formData[key]}
                  onChange={handleInputChange}
                  required={requiredFields.includes(key)}
                />
              );
            })}
          </div>

          <div className="flex justify-end space-x-2 mt-4 w-full">
            <CustomButton
              buttonText={"Cancel"}
              textColor={"#344054"}
              className="bg-white border border-gray-600"
              onClick={() => {
                document.getElementById("create_stock").close();
              }}
            />
            <CustomButton
              buttonText={details ? "Update Item" : "Create Item"}
              onClick={handleCreateItem}
              isLoading={isLoading || updating}
            />
          </div>
        </div>
      </ModalManagement>
    </div>
  );
};

export default StockItemModal;
