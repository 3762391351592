import React, { useEffect, useState } from "react";
import ModalManagement from "../../../../generalComponents/ModalManagement";
import InputWithFullBoarder from "../../../../generalComponents/InputWithFullBoarder";
import SelectWithFullBorder from "../../../../generalComponents/SelectWithFullBorder";
import useGetProjectsManager from "../../../projects/controllers/getProjectsController";
import ButtonWithIcon from "../../../../generalComponents/ButtonWithIcon";
import { BiTrash } from "react-icons/bi";
import { BulkTransferStockManager } from "../controllers/bulkTransferStockController";

const BulkTransferItemModal = ({
  selectedStock,
  selectedItems,
  availableQuantity,
  onRemoveItem
}) => {
  const [transferQuantities, setTransferQuantities] =
    useState(availableQuantity);
  const [selectedTransferOption, setSelectedTransferOption] = useState("");
  const [selectedProject, setSelectedProject] = useState("");

  const { transferStock, isSuccess } = BulkTransferStockManager(selectedStock);
  const { data: projects } = useGetProjectsManager({
    enabled: true,
  });

  const transferOptions = [
    { value: "STOCK", label: "Stock" },
    { value: "PROJECT", label: "Project" },
    { value: "SURPLUS", label: "Market" },
  ];

  const handleTransferAsset = () => {
    const payload = {
      items: selectedItems.map((item) => ({
        stock_allocation: item.id,
        allocation: selectedTransferOption,
        quantity: transferQuantities[item.id],
        project: selectedProject,
      })),
    };

    transferStock(payload);
  };


  useEffect(() => {
    if (isSuccess) {
      document.getElementById("bulk_transfer").close();
    }
  }, [isSuccess]);

  useEffect(() => {
    const initialQuantities = {};
    selectedItems.forEach((item) => {
      initialQuantities[item.id] = item.quantity; // Prefill with item quantity
    });
    setTransferQuantities(initialQuantities);
  }, [selectedItems]);

  const handleRemoveItem = (itemId) => {
    if (onRemoveItem) {
      onRemoveItem(itemId);
      
      // Also update local transfer quantities
      const updatedQuantities = { ...transferQuantities };
      delete updatedQuantities[itemId];
      setTransferQuantities(updatedQuantities);
    }
  };

  return (
    <div>
      <ModalManagement id={"bulk_transfer"} title={"Bulk Transfer Asset"}>
        <div className="h-auto rounded-[6px] relative bg-white flex flex-col pt-6">
          <div className="flex flex-col md:flex-row md:space-x-2">
            <div className="flex-1">
              <SelectWithFullBorder
                label={"Transfer to:"}
                selectOptions={transferOptions}
                value={selectedTransferOption}
                onChange={(e) => setSelectedTransferOption(e?.target?.value)}
                placeholder="Select Transfer Location"
              />
            </div>
            {selectedTransferOption === "PROJECT" ? (
              <div className="flex-1">
                <SelectWithFullBorder
                  label={"Select Project"}
                  selectOptions={[
                    ...projects?.data?.results?.map((project) => ({
                      label: project?.name,
                      value: project?.id,
                    })),
                  ]}
                  value={selectedProject}
                  onChange={(e) => setSelectedProject(e?.target?.value)}
                  placeholder="Project to transfer to"
                />
              </div>
            ) : (
              ""
            )}
          </div>

          {selectedItems.map((item) => (
            <div
            key={item?.id}
            className="flex flex-col md:flex-row md:space-x-2 mb-4"
          >
            <div className="flex-1">
              <InputWithFullBoarder
                label={"Asset Name"}
                value={item?.asset_name}
                disabled
              />
            </div>
            <div className="flex-1">
              <InputWithFullBoarder
                label={"Available Quantity"}
                value={item?.quantity}
                disabled
              />
            </div>
            <div className="flex-1 flex items-center space-x-2 justify-center"> {/* Changed items-end to items-center */}
              <InputWithFullBoarder
                label={"Transfer Quantity"}
                type={"number"}
                value={transferQuantities[item?.id]}
                onChange={(e) =>
                  setTransferQuantities({
                    ...transferQuantities,
                    [item.id]: e?.target?.value,
                  })
                }
                required
              />
              <BiTrash
                className="text-red-500 cursor-pointer"
                size={24} 
                onClick={() => handleRemoveItem(item.id)}
              /> 
            </div>
          </div>
          
          ))}

          <div className="flex flex-col md:flex-row md:space-x-2 justify-end">
            <ButtonWithIcon
              buttonText={"Cancel"}
              textColor={"#344054"}
              className={"bg-white border border-gray-600 h-[30px] w-auto"}
              onClick={() => {
                document.getElementById("bulk_transfer").close();
              }}
            />
            <ButtonWithIcon
              buttonText={"Transfer Asset"}
              onClick={handleTransferAsset}
            />
          </div>
        </div>
      </ModalManagement>
    </div>
  );
};

export default BulkTransferItemModal;
