import React, { useState } from "react";
import PaginationRounded from "../../../generalComponents/Pagination";
import TablesComponent from "../../../generalComponents/TablesComponent";
import useGetReturnItemsManager from "../controllers/getReturnItemsController";
import usePatchCompleteReturnManager from "../controllers/patchCompleteReturnController";
import ScheduleModal from "../../Requests/components/ScheduleModal";
import SearchField from "../../../generalComponents/SearchField";
import useDebounce from "../../../utils/UseDebounce";
import { calculatePaginationRange } from "../../../utils/calculatePaginationRange";

const IncomingReturns = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [stockId, setStockId] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const debouncedSearchValue = useDebounce(`&asset_name=${searchValue}`, 1000);

  const { data, isLoading } = useGetReturnItemsManager({
    is_borrower_client: true,
    enabled: true,
    page: currentPage,
    searchQuery: debouncedSearchValue,
  });

  const { startItem, endItem } = calculatePaginationRange(
    data?.data?.pagination
  );
  const { completeCaller } = usePatchCompleteReturnManager({ id: stockId });

  const headers = ["S/N", "Asset Name", "Quantity", "Request Date", "Action"];

  const options = [
    { label: "Schedule Inspection", permissions: ["add_inspection"] },
    { label: "Complete Return", permissions: ["complete_stockreturn"] },
  ];

  const getFormattedValue = (el, index) => {
    return [
      index + 1,
      el?.stock,
      el?.quantity,
      <span>{new Date(el?.created_datetime).toLocaleDateString()}</span>,
    ];
  };

  const handleCompleteReturn = (id) => {
    completeCaller({ id });
  };

  return (
    <div className="mt-2 md:mt-6 flex flex-col w-full gap-4">
      <div className="flex flex-col md:flex-row justify-between items-center">
        <div className="flex items-center gap-3">
          <SearchField
            onChange={(e) => setSearchValue(e.target.value)}
            value={searchValue}
          />{" "}
        </div>
      </div>
      <div className="h-[67vh] w-full relative">
        {
          <TablesComponent
            isLoading={isLoading}
            data={data?.data?.results}
            getFormattedValue={getFormattedValue}
            headers={headers}
            // buttonFunction={(val) => {
            //   navigate(`/borrow/request/${val.id}`);
            //   console.log(val);
            // }}
            popUpFunction={(option, index, val) => {
              console.log(option, index, val);
              setStockId(val.id);
              // if (index === 0) {
              //   handleApproval(val.id);
              // }
              // if (index === 1) {
              //   handleRejection(val.id);
              // }
              if (index === 0) {
                document.getElementById("schedule_inspection").showModal();
              }
              if (index === 1) {
                handleCompleteReturn(val.id);
              }
            }}
            options={options}
          />
        }
      </div>
      <div className="flex items-center justify-between mt-4">
        <p className="text-14px text-brandBlack">
          {" "}
          {startItem} - {endItem} of {data?.data?.pagination?.count} Items
        </p>
        <PaginationRounded
          defaultPage={data?.data?.pagination?.page}
          count={data?.data?.pagination?.total_pages}
          onChange={(page) => {
            setCurrentPage(page);
          }}
        />
      </div>
      <ScheduleModal stockId={stockId} type={"return"} />
    </div>
  );
};
export default IncomingReturns;
