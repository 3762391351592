import React, { useState } from "react";
import BaseDashboardNavigation from "../../../generalComponents/BaseDashboardNavigation";
import SelectWithFullBorder from "../../../generalComponents/SelectWithFullBorder";
import ButtonWithIcon from "../../../generalComponents/ButtonWithIcon";
import TabsManagement from "../../../generalComponents/TabsManagement";
import { BiDownload } from "react-icons/bi";
import InventoryOverview from "../components/InventoryOverview";
import InventoryStatistics from "../components/InventoryStatistics";
import OperatorStatistics from "../components/OperatorStatistics";

const DashboardPage = () => {
  const [selectedOperator, setSelectedOperator] = useState("Select operator");
  const [currentView, setCurrentView] = useState(0);

  const fundingOptions = [
    { label: "JV", value: "jv" },
    { label: "PSC", value: "psc" },
  ];

  const tabOptions = [
    "Inventory Overview",
    "Marketplace Overview",
    "Disposal",
    "Borrow Overview",
  ];

  return (
    <BaseDashboardNavigation title={"Dashboard"}>
      <div className="p-4 bg-gray-50 min-h-screen">
        {/* Header Section */}
        <div className="flex justify-between items-center bg-white border border-gray-300 p-4 rounded-lg mb-6">
          <div className="flex gap-4">
            <div className="relative">
              <div className="flex relative justify-center items-center gap-x-2">
                <div>
                  <p className="text-[16px] semi-bold">Select Date - </p>
                </div>

                <SelectWithFullBorder
                  selectOptions={fundingOptions}
                  value={selectedOperator}
                  onChange={(e) => setSelectedOperator(e.target.value)}
                  placeholder="Select Operator"
                  className="appearance-none bg-white border border-gray-200 rounded-md py-4 px-4 pr-8 text-gray-700 leading-tight focus:outline-none focus:border-green-500"
                />
              </div>
            </div>

            <div className="flex relative justify-center items-center gap-x-2">
              <div>
                <p className="text-[16px] semi-bold">Operator - </p>
              </div>

              <SelectWithFullBorder
                selectOptions={fundingOptions}
                value={selectedOperator}
                onChange={(e) => setSelectedOperator(e.target.value)}
                placeholder="Select Operator"
                className="appearance-none bg-white border border-gray-200 rounded-md py-4 px-4 pr-8 text-gray-700 leading-tight focus:outline-none focus:border-green-500"
              />
            </div>
          </div>

          <div className="flex items-center gap-4">
            <div className="flex -space-x-2">
              {[...Array(4)].map((_, i) => (
                <div
                  key={i}
                  className="w-12 h-12 rounded-full bg-gray-200 border-2 border-white"
                />
              ))}
              <div className="w-12 h-12 rounded-full bg-brandGreen border-2 border-white flex items-center justify-center text-white text-sm">
                +10
              </div>
            </div>
            <ButtonWithIcon
              buttonText={"Download Report"}
              icon={BiDownload}
              className={
                "flex items-center gap-2 text-white px-4 py-2 rounded-md hover:bg-green-700 transition-colors"
              }
            />
          </div>
        </div>

        {/* Title Section */}
        <h1 className="text-2xl font-semibold mb-2">All Operators</h1>
        <p className="text-gray-600 mb-6">
          Showing statistics for all operators
        </p>

        {/* Tabs Section */}
        <TabsManagement
          currentView={currentView}
          setCurrentView={setCurrentView}
          options={tabOptions}
        />

        {/* Content Section */}
        {currentView === 0 && <InventoryOverview />}
        {currentView === 1 && <InventoryOverview />}
        {currentView === 2 && <InventoryOverview />}
        {currentView === 3 && <InventoryOverview />}
      </div>
      <div className="grid grid-cols-2 gap-6 p-4">
        <InventoryStatistics />
        <OperatorStatistics />
      </div>
    </BaseDashboardNavigation>
  );
};

export default DashboardPage;
