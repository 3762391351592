import React from "react";

import BaseDashboardNavigation from "../../../generalComponents/BaseDashboardNavigation";
import Tabs from "../../../generalComponents/Tabs";
import OpenTickets from "./OpenTickets";
import ClosedTickets from "./ClosedTickets";

const SupportPage = () => {
  const tabsData = [
    { label: "Open Tickets", component: <OpenTickets /> },
    { label: "Closed Tickets", component: <ClosedTickets /> },
  ];

  return (
    <BaseDashboardNavigation>
      <div>
        <div className="flex flex-col md:flex-row items-start md:items-center justify-between w-full">
          <div className="flex items-center mb-2 md:mb-0">
            <h3 className="text-[24px] font-bold">Support</h3>
          </div>
          
        </div>
        <Tabs tabsData={tabsData} />
      </div>
    </BaseDashboardNavigation>
  );
};

export default SupportPage;