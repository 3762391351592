import React from "react";
import BaseDashboardNavigation from "../../generalComponents/BaseDashboardNavigation";
import Tabs from "../../generalComponents/Tabs";
import PageHeading from "../../generalComponents/PageHeading";
import UserManagement from "./usermanagement/view/UserManagement";
import RolesAndPermission from "./roles/views/RolesAndPermission";


const AccessControl = () => {
  const tabsData = [
    { label: "User Management", component: <UserManagement /> },
    { label: "Roles and Permissions", component: <RolesAndPermission /> },
  ];

  return (
    <BaseDashboardNavigation>
      <div>
        <div className="flex flex-col md:flex-row items-start md:items-center justify-between w-full">
          <div className="flex items-center mb-2 md:mb-0">
            <PageHeading title={"Access Control"} />
          </div>
        </div>
        <Tabs tabsData={tabsData} />
      </div>
    </BaseDashboardNavigation>
  );
};
export default AccessControl;
