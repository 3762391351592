import React, { useState } from "react";

import PaginationRounded from "../../../../generalComponents/Pagination";
import TablesComponent from "../../../../generalComponents/TablesComponent";

import { useNavigate } from "react-router-dom";
import StatusButton from "../../../../generalComponents/StatusButton";
import useDebounce from "../../../../utils/UseDebounce";
import { calculatePaginationRange } from "../../../../utils/calculatePaginationRange";
import { ApproveBorrowManager } from "../../../borrow/controllers/approveBorrowController";
import { RejectBorrowManager } from "../../../borrow/controllers/rejectBorrowController";
import useGetBorrowRequestsManager from "../../../borrow/controllers/getBorrowRequestsController";

const StockBorrow = ({ stock_id }) => {
  const [selected, setSelected] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedRows, setSelectedRows] = useState([]);
  const [searchValue] = useState("");
  const debouncedSearchValue = useDebounce(`&search=${searchValue}`, 1000);
  const navigate = useNavigate();

  const { data, isLoading } = useGetBorrowRequestsManager({
    is_lender_client: true,
    stock_id: stock_id,
    enabled: true,
    searchQuery: debouncedSearchValue,
    page: currentPage,
  });
  const { approveBorrow } = ApproveBorrowManager({ id: selected });
  const { rejectBorrow } = RejectBorrowManager({ id: selected });

  const { startItem, endItem } = calculatePaginationRange(
    data?.data?.pagination
  );

  const headers = [
    "Item Name",
    "Quantity",
    "Request Date",
    "Status",
    // "Action",
  ];
  const options = ["View Request", "Accept Request", "Reject Request"];

  const getFormattedValue = (el, index) => {
    return [
      el?.stock,
      el?.quantity,
      <span>{new Date(el?.created_datetime).toLocaleDateString()}</span>,
      <span>
        <StatusButton status={el?.status} />
      </span>,
    ];
  };

  const handleApproval = (id) => {
    approveBorrow({ id });
  };

  const handleRejection = (id) => {
    rejectBorrow({ id });
  };

  return (
    <div className="mt-0 md:mt-2 flex flex-col w-full gap-4">
      <div className="h-[67vh] w-full relative">
        {
          <TablesComponent
            isLoading={isLoading}
            data={data?.data?.results}
            getFormattedValue={getFormattedValue}
            headers={headers}
            setSelectedRows={setSelectedRows}
            selectedRows={selectedRows}
            toggleRowFunction={() => {}}
            toggleSelectAllFunction={() => {}}
            popUpFunction={(option, index, val) => {
              console.log(option, index, val);
              if (index === 0) {
                navigate(`/borrow/request/${val.id}`);
              }
              if (index === 1) {
                setSelected(val.id);
                handleApproval(val.id);
              }
              if (index === 2) {
                setSelected(val.id);
                handleRejection(val.id);
              }
            }}
            options={options}
            showCheckBox={false}
            hideActionButton
          />
        }
      </div>
      <div className="flex items-center justify-between mt-4">
        <p className="text-14px text-brandBlack">
          {" "}
          {startItem} - {endItem} of {data?.data?.pagination?.count} Items
        </p>
        <PaginationRounded
          defaultPage={data?.data?.pagination?.page}
          count={data?.data?.pagination?.total_pages}
          onChange={(page) => {
            setCurrentPage(page);
          }}
        />
      </div>
    </div>
  );
};
export default StockBorrow;
