import useFormDataPatchManager from "../../../../constants/controller_templates/patch_formdata_controller_template";

export const DisposalDetailsUpdate = (id) => {
  const { patchCaller, isLoading, isSuccess, error, data } = useFormDataPatchManager(
    `/stock/disposals/${id}/`,
    ["disposals"],
    false,
    true
  );

  const updateDisposal = async (file, letterType, additionalData = {}) => {
    try {
      const formData = {
        ...additionalData,
        [letterType]: file,
      };

      await patchCaller(formData);
    } catch (error) {
      console.error("Error uploading file:", error);
      throw error;
    }
  };

  return {
    updateDisposal,
    data,
    isLoading,
    isSuccess,
    error,
  };
};
