import useDeleteManager from "../../../constants/controller_templates/delete_controller_template";

export const DeleteProjectManager = ({ id }) => {
  const { deleteCaller, isLoading, isSuccess, error, data } = useDeleteManager(
    `/projects/${id}`,
    ["projects"]
  );
  const deleteProject = async () => {
    try {
      await deleteCaller();
    } catch (error) {
      console.error("error:", error);
    }
  };
  return {
    deleteProject,
    data,
    isLoading,
    isSuccess,
    error,
  };
};
