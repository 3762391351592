import React from "react";
import Tabs from "../../generalComponents/Tabs";
import BaseDashboardNavigation from "../../generalComponents/BaseDashboardNavigation";
import ButtonWithIcon from "../../generalComponents/ButtonWithIcon";
import { FaUpload } from "react-icons/fa";
import DisposalUploadModal from "./disposalUpload/view/DisposalUploadModal";
import DeclaredItems from "./disposalUploads/view/DeclaredItems";
import DisposalUploadHistory from "./disposalUploadHistory/view/DisposalUploadHistory";
import DisposalBatches from "./disposalBatches/view/DisposalBatches";
import DisposalUploads from "./disposalUploads/view/DisposalUploads";
import { hasPermissions } from "../../constants/permissions";

const DisposalManagement = () => {
  const tabsData = [
    { label: "Disposal Uploads", component: <DisposalUploads /> },
    { label: "Declared Items", component: <DeclaredItems /> },
    { label: "Disposal Batches", component: <DisposalBatches /> },
    { label: "Upload History", component: <DisposalUploadHistory /> },
  ];

  return (
    <BaseDashboardNavigation>
      <div>
        <div className="flex flex-col md:flex-row items-start md:items-center justify-between w-full">
          <div className="flex items-center mb-2 md:mb-0">
            <h3 className="text-[24px] font-bold">Disposal Management</h3>
          </div>
          <div
            className={`${
              hasPermissions(["add_stockdisposalitem"])
                ? "flex items-center space-x-[10px] mt-1 md:mt-0"
                : "hidden"
            } `}
          >
            <ButtonWithIcon
              buttonText={"Upload Disposal"}
              radius={"md"}
              icon={FaUpload}
              onClick={() => {
                document.getElementById("disposal_upload").showModal();
              }}
            />
          </div>
        </div>

        <Tabs tabsData={tabsData} />
      </div>
      <DisposalUploadModal />
    </BaseDashboardNavigation>
  );
};

export default DisposalManagement;
