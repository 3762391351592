import React from "react";
import BaseDashboardNavigation from "../../../generalComponents/BaseDashboardNavigation";
import Tabs from "../../../generalComponents/Tabs";
import IncomingReturns from "../components/IncomingReturns";
import OutgoingReturns from "../components/OutgoingReturns";

const Returns = () => {
  const tabsData = [
    { label: "Incoming Returns", component: <IncomingReturns /> },
    { label: "Outgoing Returns", component: <OutgoingReturns /> },
  ];

  return (
    <BaseDashboardNavigation>
      <div>
        <h3 className="text-[24px] font-bold">Returns</h3>
        <Tabs tabsData={tabsData} />
      </div>
    </BaseDashboardNavigation>
  );
};

export default Returns;
