import React from "react";
import HeaderFooter from "../../../generalComponents/HeaderFooter";
import { defaultProfilePicture } from "../../../constants/GlobalVariables";
import InputWithFullBoarder from "../../../generalComponents/InputWithFullBoarder";
import CustomButton from "../../../generalComponents/Button";

const ContactUsPage = () => {
  return (
    <HeaderFooter>
      <div className="w-[90vw] flex md:flex-row flex-col max-w-[1240px] md:mx-auto mx-4 relative my-20 gap-10">
        <div className="w-full max-w-[568px] relative flex flex-col">
          <div className="flex flex-col w-full mb-10">
            <h3 className="text-[35px] font-bold text-black">Contact us</h3>
            <h3 className="text-[14px] text-[#828282]">
              Questions or enquiries? contact us
            </h3>
          </div>
          <div className="w-full flex flex-col">
            <div className="flex flex-col md:flex-row items-center md:gap-10 gap-0">
              <div className="w-full">
                <InputWithFullBoarder
                  placeholder={`Enter your first name`}
                  label={`First Name`}
                />
              </div>
              <div className="w-full">
                <InputWithFullBoarder
                  placeholder={`Enter your last name`}
                  label={`Last Name`}
                />
              </div>
            </div>
            <InputWithFullBoarder
              placeholder={`Enter your email address`}
              label={`Email address`}
              type={"email"}
            />
            <InputWithFullBoarder
              placeholder={`Enter your question or message`}
              label={`Your message`}
            />
            <CustomButton buttonText={`Submit`} className={`w-full mt-10`} />
          </div>
        </div>
        <div className="w-full md:h-[623px] max-w-[481px] mx-auto">
          <img
            src={defaultProfilePicture}
            className="object-cover rounded-[6px] w-full h-full"
            alt="Item"
          />
        </div>
      </div>
    </HeaderFooter>
  );
};

export default ContactUsPage;
