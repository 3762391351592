import useUpdateManager from "../../../constants/controller_templates/put_controller_template";

export const UpdateAuctionManager = ({ id }) => {
  const { updateCaller, isLoading, isSuccess, error, data } = useUpdateManager(
    `/auctions/${id}/`,
    ["auction"],
    false,
    true
  );
  const updateAuction = async (details) => {
    try {
      await updateCaller(details);
    } catch (error) {
      console.error("error:", error);
    }
  };
  return {
    updateAuction,
    data,
    isLoading,
    isSuccess,
    error,
  };
};
