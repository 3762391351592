import { useQuery } from "react-query";
import AxiosWithToken from "../../../../constants/api_management/MyHttpHelperWithToken";

const useGetDisposalBatchesManager = ({
  searchQuery,
  page,
  enabled = true,
  pageSize = "15",
  // date_before = "",
  // date_after = ""
}) => {
  return useQuery(["disposal", page, searchQuery], async () => {
    try {
      const [response] = [
        await AxiosWithToken.get(
          `/stock/uploads/batches?&page=${page}&size=${pageSize}${searchQuery}`
        ),
      ];
      //console.log(`i am checking this ${response.status}`);
      return response.data;
    } catch (error) {
      //console.log(error.response.data);
      throw new Error(`Sorry: ${error.response.data.message}`);
    }
  });
};

export default useGetDisposalBatchesManager;
