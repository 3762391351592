import usePostManager from "../../../../constants/controller_templates/post_controller_template";

export const PostBidsManager = ({id}) => {
  const { postCaller, isLoading, isSuccess, error, data } = usePostManager(
    `/lots/${id}/bids/`,
    ["bid"],
    true
  );
  const postBid = async (details) => {
    try {
      await postCaller(details);
    } catch (error) {
      console.error("error:", error);
    }
  };
  return {
    postBid,
    data,
    isLoading,
    isSuccess,
    error,
  };
};
