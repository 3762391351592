import useFormDataUpdateManager from "../../../../constants/controller_templates/put_formdata_controller_template";

export const UpdateStockItemManager = ({ id }) => {
  const { updateCaller, isLoading, isSuccess, error, data } = useFormDataUpdateManager(
    `/stocks/${id}/`,
    ["stocks"],
    false,
    true
  );
  
  const updateStockItem = async (details) => {
    try {
      await updateCaller(details);
    } catch (error) {
      console.error("error:", error);
    }
  };
  
  return {
    updateStockItem,
    data,
    isLoading,
    isSuccess,
    error,
  };
};