import usePostManager from "../../../../constants/controller_templates/post_controller_template";

export const CreateStockHeaderManager = () => {
  const { postCaller, isLoading, isSuccess, error, data } = usePostManager(
    "/clients/stock-headers/bulk/",
    ["stockheaders"],
    true
  );
  const createStockHeader = async (details) => {
    try {
      await postCaller(details);
    } catch (error) {
      console.error("error:", error);
    }
  };
  return {
    createStockHeader,
    data,
    isLoading,
    isSuccess,
    error,
  };
};
