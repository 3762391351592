import usePostManager from "../../authentication/controller/post_controller_template";

export const CreateExchangeManager = () => {
  const { postCaller, isLoading, isSuccess, error, data } = usePostManager(
    `/stock/exchange/`,
    ["stocks"],
    true
  );
  const createExchange = async (details) => {
    try {
      await postCaller(details);
    } catch (error) {
      console.error("error:", error);
    }
  };
  return {
    createExchange,
    data,
    isLoading,
    isSuccess,
    error,
  };
};
