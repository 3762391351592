import { useQuery } from "react-query";
import AxiosWithToken from "../../../../constants/api_management/MyHttpHelperWithToken";

const useGetDisposalsManager = ({
  page,
  searchQuery = "",
  enabled = true,
  pageSize = "15",
  status = "",
  request_id = "",
  is_owner_client,
}) => {
  return useQuery(
    ["stocks", status, request_id, is_owner_client, page, searchQuery],
    async () => {
      try {
        const [response] = [
          await AxiosWithToken.get(
            `/stock/disposals/items?is_owner_client=${is_owner_client}&status=${status}&request_id=${request_id}&page=${page}&size=${pageSize}${searchQuery}`
          ),
        ];
        //console.log(`i am checking this ${response.status}`);
        return response.data;
      } catch (error) {
        //console.log(error.response.data);
        throw new Error(`Sorry: ${error.response.data.message}`);
      }
    }
  );
};

export default useGetDisposalsManager;
