import React, { useState, useEffect } from "react";
import ModalManagement from "../../../../generalComponents/ModalManagement";
import InputWithFullBoarder from "../../../../generalComponents/InputWithFullBoarder";
import CustomButton from "../../../../generalComponents/Button";
import useGetStockHeadersManager from "../controllers/getStockHeadersController";
import { CreateStockHeaderManager } from "../controllers/createStockHeadersController";

const HeadersMapping = (details) => {
  const [stockHeaders, setStockHeaders] = useState([]);

  // Fetch the headers from the backend
  const { data: headersList, isSuccess: isHeadersLoaded } =
    useGetStockHeadersManager();

  useEffect(() => {
    if (headersList?.data?.results) {
      const initialHeaders = headersList.data.results.map((header) => ({
        id: header.id,
        name: header.name,
        customName: header.customName || header.name,
        mustBeMapped: header.must_be_mapped,
      }));
      setStockHeaders(initialHeaders);
    }
  }, [headersList, isHeadersLoaded]);

  // Handle input change for custom names
  const handleInputChange = (index, field, value) => {
    const updatedHeaders = stockHeaders.map((header, i) =>
      i === index ? { ...header, [field]: value } : header
    );
    setStockHeaders(updatedHeaders);
  };

  const { createStockHeader, isLoading, isSuccess } =
    CreateStockHeaderManager();

  const handleSubmit = async () => {
    
      const payload = {
        mappings: stockHeaders.map((header) => ({
          name: header.customName || header.name,
          description: header.description || "",
          header:
            headersList?.data?.results.find((result) => result.id === header.id)
              ?.header?.id || header.id,
        })),
      };

      await createStockHeader(payload);
    
  };

  // const handleSubmit = async () => {
  //   try {
  //     const payload = {
  //       mappings: stockHeaders.map((header) => {
  //         // Find the corresponding header in headersList if it exists
  //         const existingHeader = headersList?.data?.results.find(
  //           (result) => result.id === header.id
  //         );

  //         return {
  //           name: header.customName || header.name,
  //           description: header.description || "",
  //           // Use the header's existing id if it exists, otherwise use the current header id
  //           header: existingHeader?.header?.id || header.id,
  //         };
  //       }),
  //     };

  //     await createStockHeader(payload);
  //   } catch (error) {
  //     console.error("Error submitting stock headers:", error);
  //   }
  // };

  useEffect(() => {
    if (isSuccess) {
      document.getElementById("map_headers").close();
    }
  }, [isSuccess]);

  return (
    <ModalManagement id={"map_headers"} title={"Map Headers"} type={"large"}>
      <div className="flex flex-col w-full gap-7">
        <p>
          Kindly map the name of the headers with what you have on your ERP
          <br />
          <span className="text-red-700 text-18px">*</span>
          <span className="text-14px"> is Required</span>
        </p>
        <div className="grid grid-cols-3 md:grid-cols-6 gap-4">
          {stockHeaders.map((header, index) => (
            <div
              key={header.id}
              className="flex flex-col space-y-1 border border-gray-300 rounded-md p-2"
            >
              {/* Label showing the name from the backend */}
              <label className="block text-sm font-medium text-gray-700">
                {headersList?.data?.results?.[index]?.header?.name ||
                  header.name}
                {headersList?.data?.results?.[index]?.header
                  ?.must_be_mapped && (
                  <span className="text-red-700 text-[12px]">*</span>
                )}
              </label>
              {/* Input field for the user to enter a custom name */}
              <InputWithFullBoarder
                placeholder={`Enter name for ${header?.name}`}
                value={header?.customName}
                onChange={(e) =>
                  handleInputChange(index, "customName", e?.target?.value)
                }
              />
            </div>
          ))}
        </div>
        <div className="flex justify-end space-x-2 mt-4 w-full">
          <CustomButton
            buttonText={"Cancel"}
            textColor={"#344054"}
            className="bg-white border border-gray-600"
            onClick={() => {
              document.getElementById("map_headers").close();
            }}
          />
          <CustomButton
            buttonText={"Save Headers"}
            onClick={handleSubmit}
            isLoading={isLoading}
          />
        </div>
      </div>
    </ModalManagement>
  );
};

export default HeadersMapping;
