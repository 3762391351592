import React, { useEffect, useState } from "react";
import InputWithFullBoarder from "../../../../generalComponents/InputWithFullBoarder";
import ModalManagement from "../../../../generalComponents/ModalManagement";
import ButtonWithIcon from "../../../../generalComponents/ButtonWithIcon";
import useGetStaffManager from "../../../accessControl/usermanagement/controllers/getUsersController";
import MultiSelectWithFullBorder from "../../../../generalComponents/MultiSelectWithFullBorder";
import { CreateDisposalInspectionManager } from "../controllers/createDisposalInspectionController";

const DisposalInspectionModal = ({ request_id, itemId }) => {
  const [staff, setStaff] = useState([]);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [scheduleDate, setScheduleDate] = useState("");
  const [selectedStaff, setSelectedStaff] = useState([]);

  const { data: staffList } = useGetStaffManager({ enabled: true });
  const { createDisposalInspection, isSuccess } = CreateDisposalInspectionManager(itemId);

  useEffect(() => {
    if (staffList) {
      const options = staffList?.data?.results?.map((st) => ({
        value: st.id,
        label: `${st.user.first_name} ${st.user.last_name}`,
      }));
      setStaff(options);
    }
  }, [staffList]);

  const handleSchedule = () => {
    const schedule = {
      title: title,
      description: description,
      scheduled_date: scheduleDate,
      assigned_staffs: selectedStaff?.map((staff) => staff?.value),
    };

    createDisposalInspection(schedule);
  };

  useEffect(() => {
    if (isSuccess) {
      document.getElementById("schedule_inspection").close();
    }
  }, [isSuccess]);

  return (
    <div>
      <ModalManagement id={"schedule_inspection"} title={"Create Inspection"}>
        <div className="md:w-[599px] h-auto rounded-[6px] relative bg-white flex flex-col pt-3">
          <div className="flex flex-col md:flex-row md:space-x-2 ">
            <div className="flex-1">
              <InputWithFullBoarder
                label={"Add Title"}
                placeholder={"Enter Title"}
                type={"text"}
                value={title}
                onChange={(e) => {
                  setTitle(e.target.value);
                }}
              />
            </div>
          </div>
          <div className="flex flex-col md:flex-row md:space-x-2 ">
            <div className="flex-1">
              <InputWithFullBoarder
                label={"Description"}
                placeholder={"Enter Desctiption"}
                type={"text"}
                isTextArea
                value={description}
                onChange={(e) => {
                  setDescription(e.target.value);
                }}
              />
            </div>
          </div>

          <div className="flex flex-col md:flex-row md:space-x-2 ">
            <div className="flex-1">
              <MultiSelectWithFullBorder
                label={"Assign Staff"}
                selectOptions={staff}
                value={selectedStaff}
                onChange={setSelectedStaff}
                placeholder="Select Staff"
              />
            </div>
          </div>

          <div className="flex flex-col md:flex-row md:space-x-2 ">
            <div className="flex-1">
              <InputWithFullBoarder
                label={"Inspection Date"}
                placeholder={"Inspection Date"}
                type={"date"}
                value={scheduleDate}
                onChange={(e) => {
                  console.log("Date changed:", e.target.value);
                  setScheduleDate(e.target.value);
                }}
              />
            </div>
          </div>

          <div className="flex flex-col md:flex-row md:space-x-2 justify-end">
            <ButtonWithIcon
              buttonText={"Cancel"}
              textColor={"#ff0000"}
              className={"bg-red-500 border w-auto text-white"}
              onClick={() => {
                document.getElementById("schedule_inspection").close();
              }}
            />
            <ButtonWithIcon
              buttonText={"Continue"}
              onClick={handleSchedule}
            />
          </div>
        </div>
      </ModalManagement>
    </div>
  );
};

export default DisposalInspectionModal;
