import React, { useEffect, useState } from "react";
import BaseDashboardNavigation from "../../../generalComponents/BaseDashboardNavigation";
import SearchField from "../../../generalComponents/SearchField";
import useGetNotificationManager from "../controllers/getNotificationController";
import { formatDateAgo } from "../../../utils/timeAgo";
import PaginationRounded from "../../../generalComponents/Pagination";
import { calculatePaginationRange } from "../../../utils/calculatePaginationRange";
import useDebounce from "../../../utils/UseDebounce";
import Loader from "../../../generalComponents/Loader";
import { MarkAllReadManager } from "../controllers/markAllReadController";
import { MarkAsReadManager } from "../controllers/markAsReadController";

const NotificationsPage = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const debouncedSearchValue = useDebounce(`&search=${searchValue}`, 1000);
  const [selectedNotification, setSelectedNotification] = useState("");
  const { data, isLoading } = useGetNotificationManager({
    enabled: true,
    searchQuery: debouncedSearchValue,
    page: currentPage,
  });
  const { mark, isLoading: markingAll } = MarkAllReadManager();
  const { mark: markOne } = MarkAsReadManager({
    id: selectedNotification,
  });

  const { startItem, endItem } = calculatePaginationRange(
    data?.data?.pagination
  );

  useEffect(() => {
    if (selectedNotification) {
      markOne();
    }
  }, [selectedNotification, markOne]);

  // const options = ["View Notification", "Delete Notification"];

  // const handleMoreClick = (notification, index) => {
  //   // Show options popup when more icon is clicked
  //   setCurrentNotification(notification);
  //   setShowOptions(true);
  // };

  // const handleOptionSelect = (option) => {
  //   if (option === "View Notification") {
  //     // Show modal when "View Notification" option is selected
  //     document.getElementById("view_notification").showModal();
  //   } else {
  //     // Handle other options if needed
  //     console.log(option, currentNotification);
  //   }
  //   setShowOptions(false);
  // };

  return (
    <BaseDashboardNavigation>
      <div className="mt-6 flex flex-col w-full gap-2">
        <div className="flex flex-col items-center justify-between mb-6">
          <h3 className="text-[24px] font-bold text-left w-full">
            Notifications
          </h3>
          <div className="flex flex-col md:flex-row items-center space-x-4 mt-3 w-full justify-between">
            <SearchField
              onChange={(e) => setSearchValue(e.target.value)}
              value={searchValue}
            />
            <button
              onClick={() => {
                mark();
              }}
              className="underline text-brandGreen"
            >
              {markingAll ? "awaiting..." : "Mark all as read"}
            </button>
            {/* <ButtonWithIcon
              className={"mt-3"}
              buttonText={"Send Notification"}
              radius={"md"}
              icon={BsSend}
              onClick={() => {
                document.getElementById("show_item").showModal();
              }}
            /> */}
          </div>
        </div>
        <div className="bg-white p-5 rounded-md">
          {isLoading ? (
            <Loader />
          ) : (
            data &&
            data?.data?.results.map((notification, index) => (
              <div
                onClick={() => {
                  setSelectedNotification(notification?.id);

                  if (selectedNotification === notification?.id) {
                    markOne();
                  }
                }}
                key={notification?.id}
                className={`flex items-center justify-between p-4 md:h-[78px] h-100px cursor-pointer ${
                  index < notification.length - 1
                    ? "border-b border-gray-200"
                    : ""
                }`}
              >
                <div className="flex items-center">
                  <div
                    className={`md:w-2 w-4 md:h-2 h-4 rounded-full ${
                      notification?.is_read ? "bg-textGrey/50" : "bg-brandGreen"
                    } flex items-center justify-center mr-4`}
                  ></div>
                  <div className="max-w-max flex flex-wrap">
                    <p className="text-[14px]">{notification?.content}</p>
                  </div>
                </div>
                <div className="flex items-center space-x-2 relative">
                  <p className="text-[12px] text-[#92929D]">
                    {formatDateAgo(notification?.created_datetime)}
                  </p>
                  {/* <button
                    className="text-[#B5B5BE] pl-2"
                    onClick={() => handleMoreClick(notification, index)}
                  >
                    <FiMoreHorizontal size={20} /> 
                  </button> */}
                  {/* {showOptions &&
                    currentNotification &&
                    currentNotification.id === notification.id && (
                      <OptionsPopup
                        options={options}
                        popUpFunction={handleOptionSelect}
                      />
                    )} */}
                </div>
              </div>
            ))
          )}
        </div>
        <div className="flex items-center justify-between mt-4">
          <p className="text-14px text-brandBlack">
            {" "}
            {startItem} - {endItem} of {data?.data?.pagination?.count} users
          </p>
          <PaginationRounded
            defaultPage={data?.data?.pagination?.page}
            count={data?.data?.pagination?.total_pages}
            onChange={(page) => {
              setCurrentPage(page);
            }}
          />
        </div>
      </div>

      {/* <ModalManagement
        id={"show_item"}
        title={"Send Notification"}
        subtitle={"Send a notification to all, a group or a single user"}
      >
        <div className="md:w-[599px] h-auto rounded-[6px] relative bg-white flex flex-col pt-6">
          <div className="flex flex-col md:flex-row md:space-x-2">
            <div className="flex-1">
              <InputWithFullBoarder
                label={"User Category"}
                placeholder={"Select User Category"}
              />
            </div>
          </div>
          <div className="flex flex-col md:flex-row md:space-x-2">
            <div className="flex-1">
              <InputWithFullBoarder
                label={"Or Individual User/Asset Group"}
                placeholder={"Select/Type individual user (s)"}
              />
            </div>
          </div>
          <div className="flex flex-col md:flex-row md:space-x-2">
            <div className="flex-1">
              <InputWithFullBoarder
                label={"Subject"}
                placeholder={"Enter subject of notification"}
              />
            </div>
          </div>
          <div className="flex flex-col md:flex-row md:space-x-2">
            <div className="flex-1">
              <InputWithFullBoarder
                label={"Message"}
                placeholder={"Enter Message"}
                isTextArea
              />
            </div>
          </div>
          <div className="flex space-x-4 mt-4 items-center border border-dashed border-[#D0D5DD] p-3 rounded-md">
            <div className="flex-shrink-0">
              
              <FiUploadCloud />
            </div>
            <div className="flex-1">
             
              <p className="text-[14px] text-[#101928] font-medium">
                Add Attachment
              </p>
              <p className="text-[11px] text-[#98A2B3]">
                Maximum file size - 10MB
              </p>
            </div>
            <div className="flex-shrink-0">
              <CustomButton buttonText={"Upload"} />
            </div>
          </div>

          <div className="flex justify-end space-x-2 mt-4 w-full">
            <CustomButton
              buttonText={"Cancel"}
              textColor={"#344054"}
              className="bg-white border border-gray-600"
            />
            <CustomButton buttonText={"Send"} />
          </div>
        </div>
      </ModalManagement> */}

      {/* <ModalManagement
        id={"view_notification"}
        title={"Subject: Upcoming Auction"}
      >
        <div className="md:w-[599px] h-auto rounded-[6px] relative bg-white flex flex-col pt-6">
          <div className="flex space-x-4">
            <div className="flex-1">
              <InputWithFullBoarder
                label={"User Category"}
                placeholder={"Select User Category"}
              />
            </div>
            <div className="p-3 bg-[#F9F9F9] rounded-md h-[154px] w-full ">
              <p className="text-[14px]">
                We have an Upcoming Auction event at Emerald center, <br />
                Garki. Date:02, Jan, 2024 <br />
                Time:10 AM prompt.
              </p>
            </div>
          </div>

          <div className="flex space-x-4 mt-4 items-center border border-dashed border-[#D0D5DD] bg-[#F9F9F9] p-3 rounded-md">
            <div className="flex-1 ">
              <p className="text-[14px] text-[#101928] font-medium">
                Auction Invitation.pdf
              </p>
              <p className="text-[11px] text-[#98A2B3]">
                File Title.pdf | 313 KB . 31 Aug, 2022
              </p>
            </div>
            <div className="flex px-2 text-brandGreen">
              <BsEye />
              <p className="px-2 underline text-[11px] font-semibold">
                Preview
              </p>
            </div>
          </div>

          <div className="flex justify-end space-x-2 mt-4 w-full">
            <CustomButton
              buttonText={"Cancel"}
              textColor={"#344054"}
              className="bg-white border border-gray-600"
            />
            <CustomButton buttonText={"Send"} />
          </div>
        </div>
      </ModalManagement> */}
    </BaseDashboardNavigation>
  );
};

export default NotificationsPage;
