import { Bar, BarChart, CartesianGrid, Tooltip, XAxis } from "recharts";

const InventoryOverview = () => {
  const operatorStats = [
    { name: "DECAGON", value: 9 },
    { name: "OLA", value: 8 },
    { name: "FIRST E&P", value: 16 },
    { name: "SEPLAT", value: 10 },
    { name: "TOTAL", value: 14 },
    { name: "PENTAGON", value: 11 },
    { name: "MAYOWA", value: 12 },
    { name: "MOBILE", value: 13 },
  ];

  return (
    <div className="flex flex-col items-end justify-normal w-full relative">
      <div className="grid grid-cols-3 gap-6 mb-8 w-full">
        <div className="bg-white p-6 rounded-lg shadow-sm">
          <h3 className="text-gray-500 mb-2">Stock Items:</h3>
          <p className="text-3xl font-semibold">104</p>
        </div>
        <div className="bg-white p-6 rounded-lg shadow-sm">
          <h3 className="text-gray-500 mb-2">Project Items:</h3>
          <p className="text-3xl font-semibold">280</p>
        </div>
        <div className="bg-white p-6 rounded-lg shadow-sm">
          <h3 className="text-gray-500 mb-2">Market Items:</h3>
          <p className="text-3xl font-semibold">590</p>
        </div>
      </div>
      <div className="w-full bg-[#F7F8F9] items-center justify-center p-4 rounded-t-lg border border-gray-200">
        <h3 className="text-lg font-semibold">Operators Statistics</h3>
      </div>
      <div className="bg-white p-6 rounded-b-lg w-full border border-gray-200">
        <BarChart width={1200} height={350} data={operatorStats}>
          <CartesianGrid strokeDasharray="3 3" vertical={false} />
          <XAxis dataKey="name" tick={{ fontSize: 12 }} axisLine={false} />
          <Tooltip />
          <Bar
            dataKey="value"
            fill="#358619"
            radius={[4, 4, 0, 0]}
            label={{
              position: "top",
              fill: "#666",
              fontSize: 14,
            }}
            barSize={60}
          />
        </BarChart>
      </div>
    </div>
  );
};

export default InventoryOverview;
