import React, { useEffect, useState } from "react";
import ModalManagement from "../../../../generalComponents/ModalManagement";
import InputWithFullBoarder from "../../../../generalComponents/InputWithFullBoarder";
import ButtonWithIcon from "../../../../generalComponents/ButtonWithIcon";
import { DisposalDetailsUpdate } from "../controllers/DisposalDetailsUpdateController";

const EditRequestIdModal = ({ request_id, itemId }) => {
  const [newRequestId, setNewRequestId] = useState("");

  const { updateDisposal, isSuccess } = DisposalDetailsUpdate(itemId);

  const handleRequestIdUpdate = () => {
    const payload = {
      request_id: newRequestId,
    };

    updateDisposal(null, null, payload);
  };

  useEffect(() => {
    if (isSuccess) {
      document.getElementById("edit_requestId").close();
    }
  }, [isSuccess]);

  return (
    <div>
      <ModalManagement id={"edit_requestId"} title={"Edit Request ID"}>
        <p>Edit Request ID here</p>
        <div className="md:w-[599px] h-auto rounded-[6px] relative bg-white flex flex-col pt-6">
          <div className="flex flex-col md:flex-row md:space-x-2">
            <div className="flex-1">
              <InputWithFullBoarder
                label={"Request ID"}
                placeholder={"Enter Request ID"}
                type={"text"}
                value={request_id}
                // onChange={(e) => setRequestId(e.target.value)}
                disabled
              />
            </div>
          </div>
          <div className="flex flex-col md:flex-row md:space-x-2">
            <div className="flex-1">
              <InputWithFullBoarder
                label={"New Request ID"}
                placeholder={"Enter New Request ID"}
                type={"text"}
                value={newRequestId}
                onChange={(e) => setNewRequestId(e.target.value)}
                required
              />
            </div>
          </div>
          <div className="flex flex-col md:flex-row md:space-x-2 justify-end">
            <ButtonWithIcon
              buttonText={"Cancel"}
              textColor={"#344054"}
              className={"bg-white border border-gray-600 h-[30px] w-auto"}
              onClick={() => {
                document.getElementById("edit_requestId").close();
              }}
            />
            <ButtonWithIcon
              buttonText={"Update Request ID"}
              onClick={handleRequestIdUpdate}
            />
          </div>
        </div>
      </ModalManagement>
    </div>
  );
};

export default EditRequestIdModal;
