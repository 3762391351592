import React, { useState } from "react";
import BaseDashboardNavigation from "../../../generalComponents/BaseDashboardNavigation";

import { useLocation, useParams } from "react-router-dom";
import { getLastPathSegment } from "../../../utils/getLastPath";
import GridComponent from "../../../generalComponents/GridComponent";
import Breadcrumb from "../../../generalComponents/BreadCrumb";
import CustomButton from "../../../generalComponents/Button";
import { CiCircleMinus, CiCirclePlus } from "react-icons/ci";
import useGetStockItemByIdManager from "../../inventory/stockItems/controllers/getStockItemByIdController";
import { noImage } from "../../../assets/images";
import { CreateBorrowManager } from "../controllers/createBorrowController";
import useGetWarehouseManager from "../../warehouse/controllers/getWarehouseController";
import SelectWithFullBorder from "../../../generalComponents/SelectWithFullBorder";

const BorrowDetailsPage = () => {
  const { id } = useParams();
  const [currentPage] = useState(1);
  const [isAssetDetailsOpen, setIsAssetDetailsOpen] = useState(true);
  const location = useLocation();
  const lastSegment = getLastPathSegment(location.pathname);
  const [inputValue, setInputValue] = useState(0);
  const [selectedWarehouse, setSelectedWarehouse] = useState();

  const toggleAssetDetails = () => setIsAssetDetailsOpen(!isAssetDetailsOpen);

  const { data: itemDetails } = useGetStockItemByIdManager(id);

  const { data: wareHouses } = useGetWarehouseManager({
    enabled: true,
    page: currentPage,
  });
  const { createBorrow, isLoading: isRequesting } = CreateBorrowManager();

  const item = itemDetails?.data;

  const handleIncrement = () => {
    setInputValue(inputValue + 1);
  };

  const handleDecrement = () => {
    if (inputValue > 0) {
      setInputValue(inputValue - 1);
    }
  };

  const items = [
    { name: "Borrow", path: "/borrow/" },
    {
      name: item?.asset_name,
      path: `/borrow/${lastSegment}`,
    },
  ];

  const data = [
    { label: "Asset Code", value: item?.asset_code || "N/A" },
    { label: "Material", value: item?.material || "N/A" },
    { label: "Quantity", value: item?.quantity || "N/A" },
    { label: "Unit", value: item?.unit || "N/A" },
    { label: "Unit Cost Naira", value: item?.unit_cost_naira || "N/A" },
    { label: "Unit Cost USD", value: item?.unit_cost_dollar || "N/A" },
    { label: "Project Name", value: item?.project || "N/A" },
    { label: "Manufacturer", value: item?.manufacturer || "N/A" },
    { label: "Warehouse", value: item?.warehouse || "N/A" },
    { label: "Storage Location", value: item?.storage_location || "N/A" },
    { label: "Row", value: item?.row || "N/A" },
    { label: "Slot", value: item?.slot || "N/A" },
    { label: "Dimension", value: item?.dimension || "N/A" },
    { label: "Component", value: item?.component || "N/A" },
    { label: "Classification", value: item?.classification || "N/A" },
    { label: "Connection Type", value: item?.connection_type || "N/A" },
    { label: "Capacity", value: item?.capacity || "N/A" },
    { label: "ERP Number", value: item?.erp_number || "N/A" },
    { label: "Serial Number", value: item?.serial_number || "N/A" },
    { label: "Model Number", value: item?.model_number || "N/A" },
    { label: "Bin Number", value: item?.bin_number || "N/A" },
    { label: "Bactch Number", value: item?.batch_number || "N/A" },
  ];

  const handleSubmit = () => {
    const payload = {
      stock: item?.stock_id,
      warehouse: selectedWarehouse,
      quantity: inputValue,
    };

    createBorrow(payload);
    setInputValue(0);
  };

  return (
    <BaseDashboardNavigation>
      <div className="md:mt-4 flex flex-col md:flex-row mt-2 justify-between gap-y-5">
        <Breadcrumb items={items} />
      </div>
      <div className="">
        <div className="flex md:flex-row flex-col w-full relative items-center mb-4 gap-5 h-[450px] mt-5">
          {/* Left Card with Image */}
          <div className="flex flex-[0.5] items-center bg-white p-2 rounded-lg w-full h-full">
            <img
              src={item?.image || noImage}
              className=" overflow-hidden rounded-lg shadow-md w-full h-full object-cover"
              alt="Item"
            />
          </div>

          {/* Right Card */}
          <div className="flex flex-[0.5] flex-col justify-center  gap-4 h-full w-full  bg-white rounded-lg border border-gray-200 p-12">
            <div className="flex flex-col gap-2 py-2 items-start border-b border-gray-200">
              <h1 className="text-20px md:text-[28px] font-semibold my-2 leading-[20px]">
                {item?.asset_name}
              </h1>
              <p className="text-[14px] font-medium text-gray-500">
                {itemDetails?.data?.client?.name}
              </p>
            </div>
            <div className="flex w-full items-center gap-2">
              <CiCircleMinus
                size={50}
                onClick={handleDecrement}
                color="#358619"
                className="cursor-pointer"
              />
              <input
                value={inputValue}
                onChange={(e) => setInputValue(Number(e.target.value))}
                className="w-full flex-grow text-center border border-[#F0F2F5] rounded-md py-3 bg-[#F9FAFB]"
              />
              <CiCirclePlus
                size={50}
                onClick={handleIncrement}
                color="#358619"
                className="cursor-pointer"
              />
            </div>
            {inputValue > 0 ? (
              <SelectWithFullBorder
                selectOptions={[
                  { label: "Select Warehouse", value: "" },
                  ...wareHouses?.data?.results?.map(
                    (warehouse) =>
                      ({
                        label: warehouse.name,
                        value: warehouse.id,
                      } || [])
                  ),
                ]}
                value={selectedWarehouse}
                onChange={(e) => setSelectedWarehouse(e.target.value)}
                placeholder="Select Warehouse"
                label="Select Warehouse"
              />
            ) : (
              ""
            )}

            <CustomButton
              buttonText={"Borrow Item"}
              disabled={inputValue === 0}
              isLoading={isRequesting}
              onClick={handleSubmit}
            />
            {/* <CustomButton
              buttonText={"Open Chat"}
              textColor={"#358619"}
              className="bg-white border border-brandGreen text-brandGreen"
              onClick={() =>{
                navigate(`/requests/chat/${id}`)
              }}
            /> */}
          </div>
        </div>
        <div
          tabIndex={0}
          className={`md:mt-0 mt-32 collapse collapse-arrow border border-base-500 bg-white ${
            isAssetDetailsOpen ? "collapse-open" : "collapse-close"
          }`}
        >
          <div
            className="collapse-title text-xl font-medium"
            onClick={toggleAssetDetails}
          >
            <h3 className="text-[16px] text-[#181918] font-[500]">
              Item Details
            </h3>
            <div className="border-t border-gray-200 mt-2"></div>
          </div>
          {isAssetDetailsOpen && <GridComponent data={data} />}
        </div>
        {/* <div
          tabIndex={0}
          className={`mt-4 collapse collapse-arrow border border-base-500 bg-white ${
            isAssetDetailsOpen ? "collapse-open" : "collapse-close"
          }`}
        >
          <div
            className="collapse-title text-[16px] text-[#181918] font-medium"
            onClick={toggleContactDetails}
          >
            <h3 className="text-[16px] text-[#181918] font-[500]">
              Contact Person
            </h3>

            <div className="border-t border-gray-200 mt-2"></div>
          </div>
          {isContactPerson && <GridComponent data={contactPerson} />}
        </div> */}
        {/* <div
          tabIndex={0}
          className={`collapse collapse-arrow border border-base-500 bg-white mt-4 ${
            isHistoryOpen ? "collapse-open" : "collapse-close"
          }`}
        >
          <div
            className="collapse-title text-[16px] text-[#181918] font-medium"
            onClick={toggleHistory}
          >
            <h3 className="text-[16px] text-[#181918] font-[500]">
              Borrow Requests
            </h3>
            <div className="border-t border-gray-200 mt-2"></div>
          </div>
          {isHistoryOpen && (
            <div className="h-[67vh] w-full relative">
              {
                <TablesComponent
                  // isLoading={isLoading}
                  hideActionButton={true}
                  data={operatorsData}
                  getFormattedValue={getFormattedValue}
                  headers={borrowRequestsHeader}
                  buttonFunction={(val) => {
                    navigate("#");
                    console.log(val);
                  }}

                  // Close popup function
                />
              }
            </div>
          )}
        </div> */}
      </div>
    </BaseDashboardNavigation>
  );
};

export default BorrowDetailsPage;
