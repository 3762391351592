import React, { useEffect, useState } from "react";
import ButtonWithIcon from "../../../../generalComponents/ButtonWithIcon";
import { GoDownload } from "react-icons/go";
import TablesComponent from "../../../../generalComponents/TablesComponent";
import PaginationRounded from "../../../../generalComponents/Pagination";
import { useNavigate } from "react-router-dom";
import useGetStockManager from "../controllers/getStockController";
import { BiSend } from "react-icons/bi";
import useDebounce from "../../../../utils/UseDebounce";
import SearchField from "../../../../generalComponents/SearchField";
import { calculatePaginationRange } from "../../../../utils/calculatePaginationRange";
import { noImage } from "../../../../assets/images";
import StockItemModal from "../components/StockItemModal";
import AdminDeleteConfirmationModal from "../../../../generalComponents/AdminDeleteConfirmationModal";
import { DeleteStockItemManager } from "../controllers/deleteStockItemController";
import TransferItemModal from "../components/TransferItemModal";
import BulkTransferItemModal from "../components/BulkTransferItemModal";
import { BulkDeleteStockItemsManager } from "../controllers/bulkDeleteStockItemsController";

const ProjectItems = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const navigate = useNavigate();
  const [selectedStock, setSelectedStock] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]); // State to store selected items
  const [searchValue, setSearchValue] = useState("");
  const debouncedSearchValue = useDebounce(`&search=${searchValue}`, 1000);

  const { data, isLoading } = useGetStockManager({
    allocation: "PROJECT",
    enabled: true,
    searchQuery: debouncedSearchValue,
    page: currentPage,
  });
  const {
    deleteBulkStockItems,
    isSuccess: bulkDeleted,
    isLoading: bulkDeleting,
  } = BulkDeleteStockItemsManager();

  const { startItem, endItem } = calculatePaginationRange(
    data?.data?.pagination
  );

  const headers = [
    "Image",
    "Asset Name",
    "Quantity",
    "Classification",
    "Storage Location",
    "Action",
  ];

  const options = [
    {
      label: "View Asset",
      permissions: ["view_stockallocation", "view_stock"],
    },
    { label: "Transfer Asset", permissions: ["transfer_stockallocation"] },
  ];

  const {
    deleteStockItem,
    isLoading: deleting,
    isSuccess: deleted,
  } = DeleteStockItemManager({
    id: selectedStock,
  });

  const handleDelete = async () => {
    await deleteStockItem();
  };

  useEffect(() => {
    if (deleted || bulkDeleted) {
      document.getElementById("admin_delete").close();
    }
  }, [deleted, bulkDeleted]);

  const getFormattedValue = (el, index) => {
    return [
      <span className="w-14 rounded-full">
        <img
          src={el.image || noImage}
          className="rounded-full w-12 h-12"
          alt="Item"
        />
      </span>,
      el?.asset_name,
      el?.quantity,
      el?.classification,
      el?.storage_location,
    ];
  };

  const handleBulkTransfer = () => {
    if (selectedRows.length > 0) {
      const selectedItems = selectedRows.map(
        (rowIndex) => data?.data?.results[rowIndex]
      );
      document.getElementById("bulk_transfer").showModal();
      setSelectedItems(selectedItems);
    }
  };

  const handleBulkDelete = async () => {
    if (selectedRows.length > 0) {
      const itemsToDelete = selectedRows.map(
        (rowIndex) => data?.data?.results[rowIndex].stock_id
      );
      const payload = {
        stocks: itemsToDelete, // Wrap itemsToDelete in "stocks" key
      };
      await deleteBulkStockItems(payload);
      setSelectedRows([]);
    }
  };

  const handleRemoveItemFromBulkTransfer = (itemId) => {
    // Remove the item from selectedItems
    setSelectedItems((prevItems) =>
      prevItems.filter((item) => item.id !== itemId)
    );

    // Update selectedRows to match
    const rowIndex = data?.data?.results.findIndex(
      (item) => item.id === itemId
    );
    if (rowIndex !== -1) {
      setSelectedRows((prevRows) =>
        prevRows.filter((index) => index !== rowIndex)
      );
    }
  };

  return (
    <div className="mt-2 md:mt-2 flex flex-col w-full gap-4">
      <div className="flex flex-col md:flex-row justify-between items-center">
        <div className="">
          <SearchField
            onChange={(e) => setSearchValue(e.target.value)}
            value={searchValue}
          />
        </div>
        <div className="flex space-x-[10px] mt-3 md:mt-0">
          {selectedRows.length > 0 && (
            <>
              <ButtonWithIcon
                buttonText={"Transfer Assets"}
                radius={"md"}
                icon={BiSend}
                onClick={handleBulkTransfer}
                className={
                  "bg-transparent text-brandGreen border border-brandGreen"
                }
                textColor={"#358619"}
              />
            </>
          )}
          <ButtonWithIcon
            buttonText={"Download Report"}
            radius={"md"}
            icon={GoDownload}
          />
        </div>
      </div>
      <div className="h-[67vh] w-full relative">
        <TablesComponent
          isLoading={isLoading}
          data={data?.data?.results}
          getFormattedValue={getFormattedValue}
          headers={headers}
          setSelectedRows={setSelectedRows}
          selectedRows={selectedRows}
          toggleRowFunction={() => {}}
          toggleSelectAllFunction={() => {}}
          popUpFunction={(option, index, val) => {
            console.log(option, index, val);
            setSelectedStock(val);
            if (index === 0) {
              setSelectedStock(val.id);
              navigate(`/inventory/${val.id}`);
            }
            // if (index === 1) {
            //   document.getElementById("create_stock").showModal();
            // }
            if (index === 1) {
              setSelectedStock(val.id);
              document.getElementById("transfer_asset").showModal();
            }
          }}
          options={options}
        />
      </div>
      <div className="flex items-center justify-between mt-4">
        <p className="text-14px text-brandBlack">
          {startItem} - {endItem} of {data?.data?.pagination?.count} Items
        </p>
        <PaginationRounded
          defaultPage={data?.data?.pagination?.page}
          count={data?.data?.pagination?.total_pages}
          onChange={(page) => {
            setCurrentPage(page);
          }}
        />
      </div>

      <AdminDeleteConfirmationModal
        body={`Are you sure you want to delete ${selectedRows.length} assets?`}
        title={"Delete Asset"}
        buttonText={"Yes, Delete"}
        isLoading={deleting || bulkDeleting}
        onClick={selectedRows.length > 0 ? handleBulkDelete : handleDelete}
      />
      <TransferItemModal selectedStock={selectedStock} />
      <BulkTransferItemModal
        selectedStock={selectedStock}
        selectedItems={selectedItems}
        onRemoveItem={handleRemoveItemFromBulkTransfer}
      />
      <StockItemModal details={selectedStock} />
    </div>
  );
};

export default ProjectItems;
