import React from "react";
import CustomButton from "../../../generalComponents/Button";
import CustomCheckBox from "../../../generalComponents/CustomCheckBox";
import InputWithFullBoarder from "../../../generalComponents/InputWithFullBoarder";
import ModalManagement from "../../../generalComponents/ModalManagement";

const EventsModal = () => {
  return (
    <div>
      {/* ADD EVENT MODAL */}
      <ModalManagement id={"add_event"} title={"Add Event"}>
        <div className="md:w-[599px] h-auto rounded-[6px] relative bg-white flex flex-col pt-6">
          <div className="flex flex-col md:flex-row md:space-x-2">
            <div className="flex-1">
              <InputWithFullBoarder
                label={"Name"}
                placeholder={"Enter Event Name"}
              />
            </div>
            <div className="flex-1">
              <InputWithFullBoarder
                label={"Event Code"}
                placeholder={"Enter Code"}
              />
            </div>
          </div>
          <div className="flex flex-col md:flex-row md:space-x-2">
            <div className="flex-1">
              <InputWithFullBoarder
                label={"Start Date"}
                placeholder={"DD/MM/YYYY"}
                type={"date"}
              />
            </div>
            <div className="flex-1">
              <InputWithFullBoarder
                label={"End Date"}
                placeholder={"DD/MM/YYYY"}
                type={"date"}
              />
            </div>
          </div>
          <div className="flex flex-col md:flex-row md:space-x-2">
            <div className="flex-1">
              <InputWithFullBoarder
                label={"Location"}
                placeholder={"Enter Location"}
              />
            </div>
            <div className="flex-1">
              <InputWithFullBoarder
                label={"Event Type"}
                placeholder={"Select Type"}
              />
            </div>
          </div>
          <div className="flex flex-col md:flex-row md:space-x-2">
            <div className="flex-1">
              <InputWithFullBoarder
                label={"Invitees"}
                placeholder={"Inviteese"}
              />
            </div>
            <div className="flex-1">
              <InputWithFullBoarder label={"Entry Price"} placeholder={"Enter Price"} />
            </div>
          </div>
          <div className="flex flex-col md:flex-row md:space-x-2">
            <div className="flex-1">
              <InputWithFullBoarder
                label={"Description"}
                placeholder={"Description"}
              />
            </div>
            <div className="flex-1">
              <InputWithFullBoarder label={"Event Image"} placeholder={"Event Image"} />
            </div>
          </div>
          <div className="flex space-x-4">
            <div className="flex-1">
              <CustomCheckBox text={"Mark as Active"} />
            </div>
            <div className="flex-1">
              <CustomCheckBox text={"Premium Event"} />
            </div>
          </div>
          <div className="flex justify-end space-x-2 mt-4 w-full">
            <CustomButton
              buttonText={"Cancel"}
              textColor={"#344054"}
              className="bg-white border border-gray-600"
            />
            <CustomButton buttonText={"Edit User"} />
          </div>
        </div>
      </ModalManagement>

            {/* SHARE EVENT MODAL */}
            <ModalManagement id={"share_event"} title={"Share"}>
        <div className="md:w-[599px] h-auto rounded-[6px] relative bg-white flex flex-col pt-6">
          <div className="flex flex-col md:flex-row md:space-x-2">
            <div className="flex-1">
              <InputWithFullBoarder
                label={"Enter Email(s) "}
                placeholder={"Enter Email Address"}
              />
            </div>
          </div>
          <div className="flex flex-col md:flex-row md:space-x-2">
            <div className="flex-1">
              <InputWithFullBoarder
                label={"Link to Lot"}
                placeholder={"https://emmes-..."}
              />
            </div>
          </div>
          <div className="flex justify-end space-x-2 mt-4 w-full">
            <CustomButton
              buttonText={"Cancel"}
              textColor={"#344054"}
              className="bg-white border border-gray-600"
            />
            <CustomButton buttonText={"Share"} />
          </div>
        </div>
      </ModalManagement>

      {/* DEACTIVATE USER MODAL */}
      <ModalManagement id={"deactivate_user"} title={"Deactivate User"}>
        <div className="w-[456px] h-auto rounded-[6px] relative bg-white flex flex-col py-3">
          <p className="text=[14px]">
            Are you sure you want to deactivate this user?
          </p>

          <div className="flex justify-end space-x-2 mt-4 w-full">
            <CustomButton
              buttonText={"Cancel"}
              textColor={"#344054"}
              className="bg-white border border-gray-600"
            />
            <CustomButton buttonText={"Continue"} />
          </div>
        </div>
      </ModalManagement>

      {/* DELETE USER MODAL */}
      <ModalManagement id={"delete_event"} title={"Delete User"}>
        <div className="w-[456px] h-auto rounded-[6px] relative bg-white flex flex-col py-3">
          <p className="text=[14px]">
            Are you sure you want to delete this event?
          </p>

          <div className="flex justify-end space-x-2 mt-4 w-full">
            <CustomButton
              buttonText={"Cancel"}
              textColor={"#344054"}
              className="bg-white border border-gray-600"
            />
            <CustomButton buttonText={"Continue"} />
          </div>
        </div>
      </ModalManagement>
    </div>
  );
};

export default EventsModal;
