import React, { useEffect, useState, useRef } from "react";
import { searchIcon } from "../../../assets/icons";
import MarketItemTile from "../../../generalComponents/MarketItemTile";
import PaginationRounded from "../../../generalComponents/Pagination";
import useGetBorrowManager from "../controllers/getBorrowController";
import { noData } from "../../../assets/images";
import Loader from "../../../generalComponents/Loader";
import useDebounce from "../../../utils/UseDebounce";
import { calculatePaginationRange } from "../../../utils/calculatePaginationRange";

const BorrowPage = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownRef = useRef(null);
  const debouncedSearchValue = useDebounce(`&search=${searchValue}`, 1000);

  const { data, isLoading } = useGetBorrowManager({
    enabled: true,
    searchQuery: debouncedSearchValue,
    page: currentPage,
  });

  const { startItem, endItem } = calculatePaginationRange(
    data?.data?.pagination
  );

  const items = data?.data?.results;

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  return (
    <div className="flex flex-col w-full gap-7">
      <div className="w-full sticky top-0 bg-[#F8F9FC] pb-3 py-5">
        <div className="w-full relative">
          <div className="absolute inset-y-0 left-0 flex items-center justify-center pl-4">
            <img src={searchIcon} className="w-6 h-6" alt="Search Icon" />
          </div>
          <input
            type="text"
            value={searchValue}
            onChange={(e) => {
              setSearchValue(e.target.value);
              setShowDropdown(true);
            }}
            onFocus={() => {
              if (searchValue.trim() !== "") {
                setShowDropdown(true);
              }
            }}
            placeholder="Search by item code, category, material, dimension or classification..."
            className="input input-bordered pl-10 h-[56px] text-[14px] font-[#8A919E] w-full border border-[#DBE2E8] rounded-full"
          />

          {/* Search Results Dropdown */}
          {showDropdown && searchValue && (
            <div
              ref={dropdownRef}
              className="absolute z-50 w-full mt-1 bg-white rounded-lg shadow-lg border border-gray-200 max-h-96 overflow-y-auto"
            >
              {isLoading ? (
                <div className="p-4 flex justify-center">
                  <Loader />
                </div>
              ) : items?.length > 0 ? (
                items.map((item) => (
                  <div
                    key={item.id}
                    className="flex items-center gap-4 p-4 hover:bg-gray-50 cursor-pointer border-b border-gray-100 last:border-b-0"
                    onClick={() => {
                      setSearchValue(item.asset_name);
                      setShowDropdown(false);
                    }}
                  >
                    <div className="w-12 h-12 flex-shrink-0">
                      <img
                        src={item.image}
                        alt={item.asset_name}
                        className="w-full h-full object-cover rounded-md"
                      />
                    </div>
                    <div className="flex flex-col">
                      <span className="font-medium text-brandGreen text-md">
                        {item.asset_name}
                      </span>
                      <span className="text-xs text-gray-500">
                        Material Code: {item.asset_code}
                      </span>
                    </div>
                  </div>
                ))
              ) : (
                <div className="p-4 text-center text-gray-500">
                  No results found
                </div>
              )}
            </div>
          )}
        </div>
      </div>

      {/* Display search results only when there's a search value */}
      {searchValue && (
        <div className="w-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
          {data ? (
            items?.length > 0 ? (
              items.map((item) => (
                <MarketItemTile
                  key={item.id}
                  item={item}
                  itemLink={`/borrow/${item.id}`}
                />
              ))
            ) : (
              <div className="flex flex-col items-center justify-center h-full mt-6">
                <img src={noData} width={"120px"} alt="No data available" />
                <span className="text-20px font-medium text-brandGreen">
                  No Data
                </span>
              </div>
            )
          ) : (
            <Loader />
          )}
        </div>
      )}

      {/* Show pagination only when there are search results */}
      {searchValue && items?.length > 0 && (
        <div className="flex items-center justify-between mt-4">
          <p className="text-14px text-brandBlack">
            {startItem} - {endItem} of {data?.data?.pagination?.count} Items
          </p>
          <PaginationRounded
            defaultPage={data?.data?.pagination?.page}
            count={data?.data?.pagination?.total_pages}
            onChange={(page) => {
              setCurrentPage(page);
            }}
          />
        </div>
      )}
    </div>
  );
};

export default BorrowPage;
