import React, { useState } from "react";
import MarketItemTile from "../../../generalComponents/MarketItemTile";
import PaginationRounded from "../../../generalComponents/Pagination";
import Loader from "../../../generalComponents/Loader";
import { noData } from "../../../assets/images";
import useGetMarketplaceItemsManager from "../controllers/getMarketplaceItemsController";

const MarketPlacePage = () => {
  const [ setCurrentPage] = useState(1);

  const { data: marketplaceItems } = useGetMarketplaceItemsManager();

  const items = marketplaceItems?.data?.results;


  
  return (
      <div className="flex flex-col w-full gap-7">
        <div className="w-full flex flex-col gap-7 sticky top-0 bg-[#F8F9FC] pb-3 py-5">
          {/* <CategoriesSelector
            categories={categories}
            currentCategory={currentCategory}
            onCategoryChange={handleCategoryChange}
          /> */}
          {/* <div className="flex flex-col md:flex-row justify-between items-center">
            <div className="flex items-center gap-3">
              <DropdownandSearch placeholder={"All Users"} options={[]} />
            </div>
            <div className="flex space-x-[10px] mt-3 md:mt-0">
            <img src={specification} alt="Item" />
              <ButtonWithIcon buttonText={"Download"} radius={"md"} />
            </div>
          </div> */}
        </div>
        <div className="w-full grid grid-cols sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
          {marketplaceItems ? (
            items.length > 0 ? (
              items.map((item) => (
                <MarketItemTile
                  key={item.id}
                  item={item}
                  itemLink={`/marketplace/${item.id}`}
                />
              ))
            ) : (
              <div className="flex flex-col items-center justify-center h-full mt-6">
                <img src={noData} width={"120px"} alt="No data available" />
                <span className="text-20px font-medium text-brandGreen">
                  No Data
                </span>
              </div>
            )
          ) : (
            <Loader />
          )}
        </div>
        {/* <div className=" w-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 p-4">
          {stockItems.map((item, index) => (
            <MarketItemTile key={index} item={item} itemLink={"/marketplace/marketplace-details/1"} />
          ))}
        </div> */}
        <div className="flex items-center justify-between mt-4">
          <p className="text-14px text-brandBlack">1-10 of 195 items</p>
          <PaginationRounded
            defaultPage={1}
            count={100}
            onChange={(page) => {
              setCurrentPage(page);
            }}
          />
        </div>
      </div>
  );
};

export default MarketPlacePage;
