import React, { useState } from "react";
import ButtonWithIcon from "../../../../generalComponents/ButtonWithIcon";
import { GoDownload } from "react-icons/go";
import TablesComponent from "../../../../generalComponents/TablesComponent";
import PaginationRounded from "../../../../generalComponents/Pagination";
import { useNavigate, useParams } from "react-router-dom";
import BaseDashboardNavigation from "../../../../generalComponents/BaseDashboardNavigation";
import useGetItemsByUploadIdManager from "../controllers/getItemsByUploadIdController";
import useDebounce from "../../../../utils/UseDebounce";
import { noImage } from "../../../../assets/images";
import SearchField from "../../../../generalComponents/SearchField";
import { calculatePaginationRange } from "../../../../utils/calculatePaginationRange";
import DownloadStockBatchManager from "../controllers/downloadStockBatchController";
import GoBackButton from "../../../../generalComponents/GoBackButton";
import truncateWithTooltip from "../../../../utils/truncateText";

const ItemsByUploadBatch = ({ toggleRowFunction, toggleSelectAllFunction }) => {
  const { batch_id } = useParams();
  const [currentPage, setCurrentPage] = useState(1);
  const navigate = useNavigate();
  const [selectedRows, setSelectedRows] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const debouncedSearchValue = useDebounce(`&search=${searchValue}`, 1000);

  const { data, isLoading } = useGetItemsByUploadIdManager({
    batch_id: batch_id,
    enabled: true,
    searchQuery: debouncedSearchValue,
    page: currentPage,
  });

  const { startItem, endItem } = calculatePaginationRange(
    data?.data?.pagination
  );

  const { data: downloadFile } = DownloadStockBatchManager({
    batch_id,
  });

  const fileName = data?.data?.results[0]?.batch?.file_name;

  const headers = [
    "Image",
    "Asset Name",
    "Quantity",
    "Classification",
    "Storage Location",
    "Action",
  ];

  const getFormattedValue = (el, index) => {
    return [
      <span className="w-14 rounded-full">
        <img
          src={el.image || noImage}
          className="rounded-full w-12 h-12"
          alt="Item"
        />
      </span>,
      truncateWithTooltip(el?.asset_name),
      el?.quantity,
      el?.classification,
      el?.storage_location,
    ];
  };

  const handleDownload = () => {
    if (downloadFile && downloadFile instanceof Blob) {
      try {
        const url = window.URL.createObjectURL(downloadFile);
        const link = document.createElement("a");
        link.href = url;

        // Get the filename from the Content-Disposition header if possible
        // Default to a generic name if not available

        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        window.URL.revokeObjectURL(url);
      } catch (err) {
        console.error("Download failed:", err);
      }
    } else {
      console.error("Invalid download data");
    }
  };

  return (
    <BaseDashboardNavigation>
      <div className="w-full flex flex-col gap-5 pt-3">
        <GoBackButton />
        <div className="flex flex-col md:flex-row items-start md:items-center justify-between w-full">
          <div className="flex items-center mb-2 md:mb-0">
            <h3 className="text-[24px] font-bold">{batch_id}</h3>
          </div>
        </div>
      </div>
      <div className="mt-2 md:mt-6 flex flex-col w-full gap-4">
        <div className="flex flex-col md:flex-row justify-between items-center">
          <div className="">
            <SearchField
              onChange={(e) => setSearchValue(e.target.value)}
              value={searchValue}
            />{" "}
          </div>
          <div className="flex space-x-[10px] mt-3 md:mt-0">
            <ButtonWithIcon
              buttonText={"Export"}
              radius={"md"}
              icon={GoDownload}
              onClick={handleDownload}
            />
          </div>
        </div>
        <div className="h-[67vh] w-full relative">
          {
            <TablesComponent
              isLoading={isLoading}
              data={data?.data?.results}
              getFormattedValue={getFormattedValue}
              headers={headers}
              toggleRowFunction={toggleRowFunction}
              toggleSelectAllFunction={toggleSelectAllFunction}
              setSelectedRows={setSelectedRows}
              selectedRows={selectedRows}
              buttonFunction={(val) => {
                console.log(val);
                navigate(`/inventory/${val.id}`);
              }}
            />
          }
        </div>
        <div className="flex items-center justify-between mt-4">
          <p className="text-14px text-brandBlack">
            {" "}
            {startItem} - {endItem} of {data?.data?.pagination?.count} Items
          </p>
          <PaginationRounded
            defaultPage={data?.data?.pagination?.page}
            count={data?.data?.pagination?.total_pages}
            onChange={(page) => {
              setCurrentPage(page);
            }}
          />
        </div>
      </div>
    </BaseDashboardNavigation>
  );
};

export default ItemsByUploadBatch;
