import axios from "axios";
import config from "../../config";

const AxiosWithToken = axios.create({
  baseURL: config.BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

// Request interceptor to attach the token
AxiosWithToken.interceptors.request.use(
  (config) => {
    // Retrieve the token dynamically
    const token = localStorage.getItem("token");
    console.log("Token in Request:", token);

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response interceptor to handle token expiration or other errors
AxiosWithToken.interceptors.response.use(
  (response) => {
    // Handle successful responses here
    return response;
  },
  async (error) => {
    if (error.response && error.response.status === 401) {
      // Redirect to login on unauthorized response
      window.location.href = "/login";
    }

    return Promise.reject(error);
  }
);

export default AxiosWithToken;
