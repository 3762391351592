import usePatchManager from "../../../constants/controller_templates/patch_controller_template";

export const RejectTransactionsManager = (id) => {
  const { patchCaller, isLoading, isSuccess, error, data } = usePatchManager(
    `/transactions/${id}/reject/`,
    ["transactions"],
    false,
    true
  );
  const rejectTransaction = async (details) => {
    try {
      await patchCaller(details);
    } catch (error) {
      console.error("error:", error);
    }
  };
  return {
    rejectTransaction,
    data,
    isLoading,
    isSuccess,
    error,
  };
};
