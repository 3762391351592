import { useQuery } from "react-query";
import AxiosWithToken from "../../../../constants/api_management/MyHttpHelperWithToken";

const useGetStockManager = ({
  allocation,
  page,
  searchQuery = "",
  enabled = true,
  pageSize = "15",
}) => {
  return useQuery(["stocks", page, allocation, searchQuery], async () => {
    try {
      const [response] = [
        await AxiosWithToken.get(
          `/stock/allocations/?allocation=${allocation}&page=${page}&size=${pageSize}${searchQuery}`
        ),
      ];
      //console.log(`i am checking this ${response.status}`);
      return response.data;
    } catch (error) {
      //console.log(error.response.data);
      throw new Error(`Sorry: ${error.response.data.message}`);
    }
  });
};

export default useGetStockManager;
