import React, { useEffect, useState } from "react";
import ModalManagement from "../../../generalComponents/ModalManagement";
import CustomButton from "../../../generalComponents/Button";
import { successCheck } from "../../../assets/icons";
import ReactConfetti from "react-confetti";

export const BidComplete = ({ winner, warRoom }) => {
  const [showConfetti, setShowConfetti] = useState(false);

  useEffect(() => {
    if (warRoom) {
      setShowConfetti(true);
      const timer = setTimeout(() => setShowConfetti(false), 5000);
      return () => clearTimeout(timer);
    }
  }, [warRoom]);

  return (
    <div>
      <ModalManagement id={"bid_complete"}>
        {showConfetti && (
          <ReactConfetti
            width={window.innerWidth}
            height={window.innerHeight}
            numberOfPieces={800} // Adjust the number of pieces as needed
          />
        )}
        <div className="md:w-[400px] h-auto rounded-[6px] relative bg-white flex flex-col pt-6 items-center">
          <img src={successCheck} alt="Success" className="w-28" />
          <p className="text-18px font-semibold py-2 text-center">
            We have a winner!!!
          </p>
          {winner && (
            <p className="text-24px py-2 font-semibold text-brandGreen text-center">
              {winner} Won
            </p>
          )}
          <div className="flex flex-col md:flex-row md:space-x-2"></div>
          <div className="flex justify-center space-x-2 mt-4 w-full">
            <CustomButton
              buttonText={"Close"}
              textColor={"#368618"}
              className="bg-white border border-brandGreen text-[#368618]"
              onClick={() => {
                document.getElementById("bid_complete").close();
              }}
            />
          </div>
        </div>
      </ModalManagement>
    </div>
  );
};

export const BidWinner = () => {
  const [showConfetti, setShowConfetti] = useState(false);

  useEffect(() => {
    setShowConfetti(true);
    const timer = setTimeout(() => setShowConfetti(false), 5000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <div>
      <ModalManagement id={"winner_modal"}>
        {showConfetti && (
          <ReactConfetti
            width={window.innerWidth}
            height={window.innerHeight}
            numberOfPieces={800} // Adjust the number of pieces as needed
          />
        )}
        <div className="md:w-[400px] h-auto rounded-[6px] relative bg-white flex flex-col pt-6 items-center">
          <img src={successCheck} alt="Success" className="w-28" />
          <p className="text-18px font-semibold py-4 text-center">
            You have won!!!
          </p>
          <p className="text-14px py-2 text-center">
            Gorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc
            vulputate libero et velit interdum, ac aliquet odio mattis.
          </p>
          <div className="flex flex-col md:flex-row md:space-x-2"></div>
          <div className="flex justify-center space-x-2 mt-4 w-full">
            <CustomButton
              buttonText={"Close"}
              textColor={"#368618"}
              className="bg-white border border-brandGreen text-[#368618]"
              onClick={() => {
                document.getElementById("winner_modal").close();
              }}
            />
            <CustomButton
              buttonText={"Proceed to Checkout"}
              // onClick={() => {
              //   document.getElementById("winner_modal").close();
              // }}
            />
          </div>
        </div>
      </ModalManagement>
    </div>
  );
};
