import React, { useState } from "react";
import PaginationRounded from "../../../../generalComponents/Pagination";
import TablesComponent from "../../../../generalComponents/TablesComponent";
import StatusButton from "../../../../generalComponents/StatusButton";
import useGetExchangeRequestsManager from "../../../marketPlace/controllers/getExchangeRequestsController";
import { calculatePaginationRange } from "../../../../utils/calculatePaginationRange";

const StockExchange = ({ stock_id }) => {
  const [currentPage, setCurrentPage] = useState(1);

  const { data, isLoading } = useGetExchangeRequestsManager({
    is_seller_client: true,
    stock_id: stock_id,
    enabled: true,
    page: currentPage,
  });

  const { startItem, endItem } = calculatePaginationRange(
    data?.data?.pagination
  );

  const headers = [
    "Asset Name",
    "Asset Quantity",
    "Exchange Asset",
    "Exchange Quantity",
    "Status",
    "Request Date",
    "Action",
  ];

  const getFormattedValue = (el, index) => {
    return [
      el?.stock,
      el?.quantity,
      el?.exchange_stock,
      el?.exchange_stock_quantity,
      <span>
        <StatusButton status={el?.status} />
      </span>,
      <span>{new Date(el?.created_datetime).toLocaleDateString()}</span>,
    ];
  };


  return (
    <div className="mt-0 md:mt-2 flex flex-col w-full gap-4">
      <div className="h-[67vh] w-full relative">
        {
          <TablesComponent
            isLoading={isLoading}
            data={data?.data?.results}
            getFormattedValue={getFormattedValue}
            headers={headers}
            // buttonFunction={(val) => {
            //   navigate(`/borrow/request/${val.id}`);
            //   console.log(val);
            // }}
            showCheckBox={false}
            hideActionButton
            // Close popup function
          />
        }
      </div>
      <div className="flex items-center justify-between mt-4">
        <p className="text-14px text-brandBlack">
          {" "}
          {startItem} - {endItem} of {data?.data?.pagination?.count} Items
        </p>
        <PaginationRounded
          defaultPage={data?.data?.pagination?.page}
          count={data?.data?.pagination?.total_pages}
          onChange={(page) => {
            setCurrentPage(page);
          }}
        />
      </div>
    </div>
  );
};
export default StockExchange;
