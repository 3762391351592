import React, { useState } from "react";
import { logo } from "../../../assets/images";
import CustomButton from "../../../generalComponents/Button";
import LeftSideComponent from "../components/LeftSideComponent";
import InputWithFullBoarder from "../../../generalComponents/InputWithFullBoarder";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { ResetPasswordManager } from "../controller/resetPasswordController";
import { toast, ToastContainer } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";

const ResetPassword = () => {
  const [viewNewPassword, setViewNewPassword] = useState(false);
  const [viewConfirmPassword, setViewConfirmPassword] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [token, setToken] = useState("");
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const email = searchParams.get("email");
  const navigate = useNavigate();

  const { changePassword, isLoading } = ResetPasswordManager();

  const handlePasswordReset = () => {
    if (confirmPassword === newPassword) {
      changePassword({
        email,
        totp: token,
        new_password: newPassword,
      }).then(() => {
        navigate("/login");
      });
    } else {
      toast.error("Your new password and confirm password do not match");
    }
  };

  return (
    <div className="flex flex-col md:flex-row sm:h-screen">
      <ToastContainer />
      {/* Left side with image and overlay */}
      <LeftSideComponent />

      {/* Right side with login form */}
      <div className="w-full md:w-1/2 flex flex-col justify-center items-center bg-white p-8">
        <div className="w-full max-w-md">
          <img
            src={logo}
            alt="EMMS Logo"
            className="mb-[105px] mx-auto h-[62px] w-auto object-contain"
          />
          <h2 className="text-2xl md:text-3xl font-bold mb-2 mt-3 text-center">
            Reset Password
          </h2>
          <p className="text-gray-600 mb-6 text-center">
            Enter the token sent to your email and your new password
          </p>

          <div>
            <div className="flex flex-col md:flex-row md:space-x-2">
              <div className="flex-1">
                <InputWithFullBoarder
                  label={"Enter Token"}
                  type="Token"
                  placeholder="Enter Token"
                  value={token}
                  onChange={(e) => setToken(e.target.value)}
                  className="h-[56px]"
                  required
                />
              </div>
            </div>
            <div className="flex flex-col md:flex-row md:space-x-2">
              <div className="flex-1">
                <InputWithFullBoarder
                  label={"New Password"}
                  hasSuffix={true}
                  type={viewNewPassword ? "text" : "password"}
                  placeholder="Enter New Password"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  className="h-[56px]"
                  required
                  icon={
                    viewNewPassword ? (
                      <AiOutlineEyeInvisible
                        onClick={() => setViewNewPassword(!viewNewPassword)}
                      />
                    ) : (
                      <AiOutlineEye
                        onClick={() => setViewNewPassword(!viewNewPassword)}
                      />
                    )
                  }
                />
              </div>
            </div>

            <div className="flex flex-col md:flex-row md:space-x-2">
              <div className="flex-1">
                <InputWithFullBoarder
                  label={"Confirm New Password"}
                  hasSuffix={true}
                  type={viewConfirmPassword ? "text" : "password"}
                  placeholder="Confirm New Password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  className="h-[56px]"
                  required
                  icon={
                    viewConfirmPassword ? (
                      <AiOutlineEyeInvisible
                        onClick={() =>
                          setViewConfirmPassword(!viewConfirmPassword)
                        }
                      />
                    ) : (
                      <AiOutlineEye
                        onClick={() =>
                          setViewConfirmPassword(!viewConfirmPassword)
                        }
                      />
                    )
                  }
                />
              </div>
            </div>
          </div>
          <div className="flex justify-between space-x-2 mt-4 w-full">
            <CustomButton
              buttonText={"Reset Password"}
              isLoading={isLoading}
              onClick={handlePasswordReset}
              className={"w-full"}
            />
          </div>
          <div className="flex mt-4 justify-center">
            <a
              className="inline-block align-baseline text-sm font-semibold text-brandGreen underline"
              href="/login"
            >
              Back to Login
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
