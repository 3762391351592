import React, { useState } from "react";
import PaginationRounded from "../../../../generalComponents/Pagination";
import TablesComponent from "../../../../generalComponents/TablesComponent";
import useGetReturnItemsManager from "../../../returns/controllers/getReturnItemsController";
import usePatchReturnApprovalManager from "../../../returns/controllers/patchReturnApprovalController";
import usePatchReturnRejectionManager from "../../../returns/controllers/patchReturnRejectionController";
import usePatchCompleteReturnManager from "../../../returns/controllers/patchCompleteReturnController";
import { calculatePaginationRange } from "../../../../utils/calculatePaginationRange";

const StockReturn = ({ stock_id }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [stockId, setStockId] = useState("");

  const { data, isLoading } = useGetReturnItemsManager({
    is_lender_client: true,
    enabled: true,
    page: currentPage,
    stock_id: stock_id,
  });

  const { startItem, endItem } = calculatePaginationRange(
    data?.data?.pagination
  );

  const { approvalCaller } = usePatchReturnApprovalManager({ id: stockId });
  const { rejectionCaller } = usePatchReturnRejectionManager({ id: stockId });
  const { completeCaller } = usePatchCompleteReturnManager({ id: stockId });

  const headers = ["Asset Name", "Quantity", "Request Date", "Action"];

  const options = [
    "Accept Request",
    "Reject Request",
    "Schedule Inspection",
    "Complete Return",
  ];

  const getFormattedValue = (el, index) => {
    return [
      el?.stock,
      el?.quantity,
      <span>{new Date(el?.created_datetime).toLocaleDateString()}</span>,
    ];
  };

  const handleApproval = (id) => {
    approvalCaller({ id });
  };

  const handleRejection = (id) => {
    rejectionCaller({ id });
  };

  const handleCompleteReturn = (id) => {
    completeCaller({ id });
  };

  return (
    <div className="mt-0 md:mt-2 flex flex-col w-full gap-4">
      <div className="h-[67vh] w-full relative">
        {
          <TablesComponent
            isLoading={isLoading}
            data={data?.data?.results}
            getFormattedValue={getFormattedValue}
            headers={headers}
            // buttonFunction={(val) => {
            //   navigate(`/borrow/request/${val.id}`);
            //   console.log(val);
            // }}
            popUpFunction={(option, index, val) => {
              console.log(option, index, val);
              setStockId(val.id);
              if (index === 0) {
                handleApproval(val.id);
              }
              if (index === 1) {
                handleRejection(val.id);
              }
              if (index === 2) {
                document.getElementById("schedule_inspection").showModal();
              }
              if (index === 3) {
                handleCompleteReturn(val.id);
              }
            }}
            options={options}
            showCheckBox={false}
          />
        }
      </div>
      <div className="flex items-center justify-between mt-4">
        <p className="text-14px text-brandBlack">
          {" "}
          {startItem} - {endItem} of {data?.data?.pagination?.count} Items
        </p>
        <PaginationRounded
          defaultPage={data?.data?.pagination?.page}
          count={data?.data?.pagination?.total_pages}
          onChange={(page) => {
            setCurrentPage(page);
          }}
        />
      </div>
    </div>
  );
};
export default StockReturn;
