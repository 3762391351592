import React from "react";
import ModalManagement from "../../../generalComponents/ModalManagement";
import CustomButton from "../../../generalComponents/Button";
import ReceiptUploadModal from "./ReceiptUploadModal";

const AuctionFeeModal = ({ eventName, entryFee }) => {
  return (
    <div>
      <ModalManagement id={"auction_fee_modal"}>
        <div className="md:w-[400px] h-auto rounded-[6px] relative bg-white flex flex-col pt-6">
          <p className="text-18px font-semibold py-4 text-center">
            How would you like to pay for the auction fee for {eventName}
          </p>
          <div className="flex flex-col md:flex-row md:space-x-2"></div>
          <div className="flex justify-center space-x-2 mt-4 w-full">
            <CustomButton
              buttonText={"Bank Transfer"}
              textColor={"#368618"}
              className="bg-white border border-brandGreen text-[#368618]"
              onClick={() => {
                document.getElementById("receipt_upload").showModal();
                document.getElementById("auction_fee_modal").close();
              }}
            />
            <CustomButton buttonText={"Pay Via Remita"} />
          </div>
        </div>
      </ModalManagement>
      <ReceiptUploadModal entryFee={entryFee} />
    </div>
  );
};

export default AuctionFeeModal;
