import { useQuery } from "react-query";
import AxiosWithToken from "../../../constants/api_management/MyHttpHelperWithToken";

const useGetMarketplaceItemsManager = (queryParams = {} ) => {
  return useQuery(['stocks', queryParams], async () => {
    try {
      const response = await AxiosWithToken.get('/stock/exchange/market/');
      //console.log(`i am checking this ${response.status}`);
      return response.data;
    } catch (error) {
      //console.log(error.response.data);
      throw new Error(`Sorry: ${error.response.data.message}`);
    }
  });
};

export default useGetMarketplaceItemsManager;
