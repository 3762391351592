import React, { useState } from "react";
import TablesComponent from "../../../../generalComponents/TablesComponent";
import PaginationRounded from "../../../../generalComponents/Pagination";
import { useNavigate, useParams } from "react-router-dom";
import BaseDashboardNavigation from "../../../../generalComponents/BaseDashboardNavigation";
import useDebounce from "../../../../utils/UseDebounce";
import { noImage } from "../../../../assets/images";
import SearchField from "../../../../generalComponents/SearchField";
import { calculatePaginationRange } from "../../../../utils/calculatePaginationRange";
import GoBackButton from "../../../../generalComponents/GoBackButton";
import useGetDisposalByBatchIdManager from "../controllers/getIDisposalByBatchIdController";
import truncateWithTooltip from "../../../../utils/truncateText";

const DisposalItemsByUploadBatch = () => {
  const { batch_id } = useParams();
  const [currentPage, setCurrentPage] = useState(1);
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState("");
  const debouncedSearchValue = useDebounce(`&search=${searchValue}`, 1000);

  const { data, isLoading } = useGetDisposalByBatchIdManager({
    batch_id: batch_id,
    enabled: true,
    searchQuery: debouncedSearchValue,
    page: currentPage,
  });

  const { startItem, endItem } = calculatePaginationRange(
    data?.data?.pagination
  );

  const headers = [
    "Image",
    "Asset Name",
    "Asset Code",
    "Material",
    "NUIMS Quantity",
    "Operator Quantity",
    "Action",
  ];

  const getFormattedValue = (el, index) => {
    return [
      <span className="w-14 rounded-full">
        <img
          src={el?.stock?.image || noImage}
          alt="Item"
          className="rounded-full w-12 h-12"
        />
      </span>,
      truncateWithTooltip(el?.stock?.asset_name),
      el?.stock?.asset_code,
      el?.stock?.material,
      el?.nuims_quantity,
      el?.operator_quantity,
    ];
  };

  return (
    <BaseDashboardNavigation>
      <div className="w-full flex flex-col gap-5 pt-3">
        <GoBackButton />
        <div className="flex flex-col md:flex-row items-start md:items-center justify-between w-full">
          <div className="flex items-center mb-2 md:mb-0">
            <h3 className="text-[24px] font-bold">{batch_id}</h3>
          </div>
        </div>
      </div>
      <div className="mt-2 md:mt-6 flex flex-col w-full gap-4">
        <div className="flex flex-col md:flex-row justify-between items-center">
          <div className="">
            <SearchField
              onChange={(e) => setSearchValue(e.target.value)}
              value={searchValue}
            />{" "}
          </div>
          {/* <div className="flex space-x-[10px] mt-3 md:mt-0">
            <ButtonWithIcon
              buttonText={"Export"}
              radius={"md"}
              icon={GoDownload}
              onClick={handleDownload}
            />
          </div> */}
        </div>
        <div className="h-[67vh] w-full relative">
          {
            <TablesComponent
              isLoading={isLoading}
              data={data?.data?.results}
              getFormattedValue={getFormattedValue}
              headers={headers}
              buttonFunction={(val) => {
                console.log(val);
                navigate(`/inventory/${val.id}`);
              }}
            />
          }
        </div>
        <div className="flex items-center justify-between mt-4">
          <p className="text-14px text-brandBlack">
            {" "}
            {startItem} - {endItem} of {data?.data?.pagination?.count} Items
          </p>
          <PaginationRounded
            defaultPage={data?.data?.pagination?.page}
            count={data?.data?.pagination?.total_pages}
            onChange={(page) => {
              setCurrentPage(page);
            }}
          />
        </div>
      </div>
    </BaseDashboardNavigation>
  );
};

export default DisposalItemsByUploadBatch;
