import { useQuery } from "react-query";
import AxiosWithToken from "../../../constants/api_management/MyHttpHelperWithToken";

const useGetInspectionsManager = (options = {}) => {
  const {
    isStockOwnerClient,
    isVisitingClient,
    page = 1,
    assetName = "",
    status = "",
  } = options;

  return useQuery(
    ['inspections', isStockOwnerClient, isVisitingClient, page, assetName, status],
    async () => {
      try {
        const response = await AxiosWithToken.get('/inspections/', {
          params: {
            is_stock_owner_client: isStockOwnerClient,
            is_visiting_client: isVisitingClient,
            page,
            asset_name: assetName,
            status,
          }
        });
        return response.data;
      } catch (error) {
        throw new Error(`Error fetching inspections: ${error.response?.data?.message || error.message}`);
      }
    },
    {
      keepPreviousData: true,
      staleTime: 5 * 60 * 1000, // 5 minutes
    }
  );
};

export default useGetInspectionsManager;