import React from "react";
import BaseDashboardNavigation from "../../../generalComponents/BaseDashboardNavigation";
import Tabs from "../../../generalComponents/Tabs";
import PendingTasks from "../components/PendingTasks";
import CompletedTasks from "../components/CompletedTasks";

const Tasks = () => {
  const tabsData = [
    { label: "Pending", component: <PendingTasks /> },
    { label: "Completed", component: <CompletedTasks /> },
  ];

  return (
    <BaseDashboardNavigation>
      <div>
        <h3 className="text-[24px] font-bold">Tasks</h3>
        <Tabs tabsData={tabsData} />
      </div>
    </BaseDashboardNavigation>
  );
};

export default Tasks;
