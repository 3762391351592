import {
  accessControlIcon,
  borrowIcon,
  cartIcon,
  chatIcon,
  dashboardIcon,
  disposalIcon,
  inventoryIcon,
  itemLocationIcon,
  marketplaceIcon,
  myRequestsIcon,
  notificationsIcon,
  organizationsIcon,
  requestsIcon,
  settingsIcon,
  soldItemsIcon,
  supportIcon,
  transactionsIcon,
  userManagementIcon,
} from "../assets/icons";

export const menus = [
  // { title: "Home", path: "/", isHighlighted: false, forTop: true },
  { title: "Categories", path: "#", isHighlighted: false, forTop: true },
  { title: "Event", path: "/events", isHighlighted: false, forTop: true },
  {
    title: "New Listing",
    path: "/new-listing",
    isHighlighted: false,
    forTop: true,
  },
  {
    title: "Contact Us",
    path: "/contact-us",
    isHighlighted: false,
    forTop: true,
  },
  // { title: "Sign In", path: "/login", isHighlighted: true, forTop: true },
];

export const footerMenu = [
  { title: "About", path: "/about-us", isHighlighted: false, forTop: true },
  { title: "Press", path: "/press", isHighlighted: false, forTop: true },
];

export const footerMenu2 = [
  { title: "FAQ", path: "/faq", isHighlighted: false, forTop: true },
  {
    title: "Privacy Policy",
    path: "/privacy-policy",
    isHighlighted: false,
    forTop: true,
  },
  {
    title: "Terms and Conditions",
    path: "/terms-and-conditions",
    isHighlighted: false,
    forTop: true,
  },
];

export const nuimsAdminMenu = [
  {
    title: "Dashboard",
    path: "/dashboard",
    icon: dashboardIcon,
    activeIcon: dashboardIcon,
    // permissions: ["admin", "operator"],
  },
  {
    title: "Inventory",
    path: "/inventory",
    icon: inventoryIcon,
    activeIcon: inventoryIcon,
  },
  {
    title: "Disposal",
    path: "/disposal",
    icon: disposalIcon,
    activeIcon: disposalIcon,
  },
  {
    title: "Borrow",
    path: "/borrow",
    icon: borrowIcon,
    activeIcon: borrowIcon,
  },
  {
    title: "Requests",
    path: "/requests",
    icon: requestsIcon,
    activeIcon: requestsIcon,
  },
  {
    title: "Marketplace",
    path: "/marketplace",
    icon: marketplaceIcon,
    activeIcon: marketplaceIcon,
  },
  {
    title: "Transactions",
    path: "/transactions",
    icon: transactionsIcon,
    activeIcon: transactionsIcon,
  },
  {
    title: "Access Control",
    path: "/access-control",
    icon: userManagementIcon,
    activeIcon: userManagementIcon,
  },
  {
    title: "Need Help",
    path: "/chat",
    icon: supportIcon,
    activeIcon: supportIcon,
  },
];

export const operatorsMenu = [
  {
    title: "Dashboard",
    path: "/dashboard",
    icon: dashboardIcon,
    activeIcon: dashboardIcon,
    // permissions: ["admin", "operator"],
  },
  {
    title: "Inventory",
    path: "/inventory",
    icon: inventoryIcon,
    activeIcon: inventoryIcon,
  },
  {
    title: "Disposal",
    path: "/disposal",
    icon: disposalIcon,
    activeIcon: disposalIcon,
  },
  {
    title: "Borrow",
    path: "/borrow",
    icon: borrowIcon,
    activeIcon: borrowIcon,
  },
  {
    title: "Marketplace",
    path: "/marketplace",
    icon: marketplaceIcon,
    activeIcon: marketplaceIcon,
  },
  {
    title: "My Requests",
    path: "/requests",
    icon: myRequestsIcon,
    activeIcon: myRequestsIcon,
  },
  {
    title: "Chat",
    path: "/chat",
    icon: chatIcon,
    activeIcon: chatIcon,
  },
  {
    title: "My Cart",
    path: "/my-cart",
    icon: cartIcon,
    activeIcon: cartIcon,
  },
  {
    title: "Transactions",
    path: "/transactions",
    icon: transactionsIcon,
    activeIcon: transactionsIcon,
  },
  {
    title: "Notifications",
    path: "/notifications",
    icon: notificationsIcon,
    activeIcon: notificationsIcon,
  },
];

export const nipexAdminMenu = [
  {
    title: "Dashboard",
    path: "/dashboard",
    icon: dashboardIcon,
    activeIcon: dashboardIcon,
    // permissions: ["admin", "operator"],
  },
  {
    title: "Users",
    path: "/access-control",
    icon: organizationsIcon,
    activeIcon: organizationsIcon,
  },
  {
    title: "Auction",
    path: "/auction",
    icon: marketplaceIcon,
    activeIcon: marketplaceIcon,
  },
  {
    title: "Transactions",
    path: "/transactions",
    icon: transactionsIcon,
    activeIcon: transactionsIcon,
  },
  {
    title: "Chat",
    path: "/chat",
    icon: chatIcon,
    activeIcon: chatIcon,
  },
  {
    title: "Support",
    path: "/support",
    icon: supportIcon,
    activeIcon: supportIcon,
  },
  {
    title: "Access Control",
    path: "/access-control",
    icon: requestsIcon,
    activeIcon: requestsIcon,
  },
];

export const rightclickMenu = [
  {
    title: "Dashboard",
    path: "/dashboard",
    icon: dashboardIcon,
    activeIcon: dashboardIcon,
  },
  {
    title: "Access Control",
    path: "/access-control",
    icon: accessControlIcon,
    activeIcon: accessControlIcon,
    requiredPermissions: ["view_staff"],
  },
  {
    title: "Clients",
    path: "/clients",
    icon: organizationsIcon,
    activeIcon: organizationsIcon,
    requiredPermissions: ["view_client"],
  },
  {
    title: "Asset Groups",
    path: "/asset-groups",
    icon: organizationsIcon,
    activeIcon: organizationsIcon,
    requiredPermissions: ["view_assetgroup"],
  },
  {
    title: "Inventory",
    path: "/inventory",
    icon: inventoryIcon,
    activeIcon: inventoryIcon,
    requiredPermissions: ["view_stock"],
  },
  {
    title: "Disposal",
    path: "/disposal",
    icon: disposalIcon,
    activeIcon: disposalIcon,
    requiredPermissions: ["view_stockdisposal"],
  },
  {
    title: "Warehouse",
    path: "/warehouse",
    icon: marketplaceIcon,
    activeIcon: marketplaceIcon,
    requiredPermissions: ["view_warehouse"],
  },
  {
    title: "Projects",
    path: "/projects",
    icon: inventoryIcon,
    activeIcon: inventoryIcon,
    requiredPermissions: ["view_project"],
  },
  {
    title: "Borrow",
    path: "/borrow",
    icon: borrowIcon,
    activeIcon: borrowIcon,
    requiredPermissions: ["view_stockborrow"],
  },
  {
    title: "Inspections",
    path: "/inspections",
    icon: borrowIcon,
    activeIcon: borrowIcon,
    requiredPermissions: ["view_inspection"],
  },
  {
    title: "Tasks",
    path: "/tasks",
    icon: borrowIcon,
    activeIcon: borrowIcon,
    requiredPermissions: ["view_inspection", "view_inspectionreport"],
  },
  {
    title: "Returns",
    path: "/returns",
    icon: borrowIcon,
    activeIcon: borrowIcon,
    requiredPermissions: ["view_stockreturn"],
  },
  {
    title: "Auction",
    path: "/auction",
    icon: inventoryIcon,
    activeIcon: inventoryIcon,
    requiredPermissions: ["view_auction"],
  },
  {
    title: "Auction Items",
    path: "/auction-items",
    icon: inventoryIcon,
    activeIcon: inventoryIcon,
    requiredPermissions: ["v_auctionitem"],
  },
  {
    title: "Transactions",
    path: "/transactions",
    icon: transactionsIcon,
    activeIcon: transactionsIcon,
    requiredPermissions: ["view_transaction"],
  },
  {
    title: "Lots",
    path: "/lots",
    icon: inventoryIcon,
    activeIcon: inventoryIcon,
    requiredPermissions: ["view_lot"],
  },
  {
    title: "Marketplace",
    path: "/marketplace",
    icon: marketplaceIcon,
    activeIcon: marketplaceIcon,
    requiredPermissions: ["view_stocksale"],
  },

  // {
  //   title: "Chat",
  //   path: "/chat",
  //   icon: chatIcon,
  //   activeIcon: chatIcon,
  // },
  {
    title: "Support",
    path: "/support",
    icon: supportIcon,
    activeIcon: supportIcon,
  },
];

export const thirdPartyAdminMenu = [
  {
    title: "Dashboard",
    path: "/dashboard",
    icon: dashboardIcon,
    activeIcon: dashboardIcon,
    // permissions: ["admin", "operator"],
  },
  {
    title: "Events",
    path: "/events/auction/",
    icon: inventoryIcon,
    activeIcon: inventoryIcon,
  },
  {
    title: "Lots",
    path: "/lots",
    icon: disposalIcon,
    activeIcon: disposalIcon,
  },
  {
    title: "Disposal",
    path: "/disposal",
    icon: disposalIcon,
    activeIcon: disposalIcon,
  },
  {
    title: "Sold Items",
    path: "/sold-items",
    icon: soldItemsIcon,
    activeIcon: soldItemsIcon,
  },
  {
    title: "Transactions",
    path: "/transactions",
    icon: transactionsIcon,
    activeIcon: transactionsIcon,
  },

  {
    title: "Products",
    path: "/products",
    icon: requestsIcon,
    activeIcon: requestsIcon,
  },
  {
    title: "Item Location",
    path: "/locations",
    icon: itemLocationIcon,
    activeIcon: itemLocationIcon,
  },
  {
    title: "Access Control",
    path: "/access-control",
    icon: accessControlIcon,
    activeIcon: accessControlIcon,
  },
];

export const lowerMenu = [
  {
    title: "Notifications",
    path: "/notifications",
    icon: notificationsIcon,
    activeIcon: notificationsIcon,
  },
  {
    title: "Settings",
    path: "/settings",
    icon: settingsIcon,
    activeIcon: settingsIcon,
  },
];
