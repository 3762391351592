import React from "react";

const HomeModules = () => {
  const modules = [
    "Inventory Management",
    "Disposal Management",
    "Material Exchange",
    "e-Auction Transparency",
    "Borrow Management",
    "Material Sales",
  ];
  return (
    <div className="flex w-full justify-center gap-6 py-8">
      {/* Circle 1 */}
      {modules.map((module, index) => (
        <div
          key={index}
          className="flex font-clash font-medium text-14px text-center items-center justify-center w-[120px] h-[120px] p-16 bg-[#F7F8F9] border border-[#DBE2E8] rounded-full text-black"
        >
          {module}
        </div>
      ))}
    </div>
  );
};

export default HomeModules;
