import { useQuery } from "react-query";
import AxiosWithToken from "../../../constants/api_management/MyHttpHelperWithToken";

const useGetAuctionItemsManager = ({
  page = "",
  searchQuery = "",
  enabled = true,
  pageSize = "",
  request_id = "",
  status = "",
  is_lot_item = false,
}) => {
  return useQuery(
    ["disposals", page, request_id, status, is_lot_item, searchQuery, ],
    async () => {
      try {
        const [response] = [
          await AxiosWithToken.get(
            `/stock/disposals/items?request_id=${request_id}&status=${status}&is_lot_item=${is_lot_item}&page=${page}&size=${pageSize}${searchQuery}`
          ),
        ];
        //console.log(`i am checking this ${response.status}`);
        return response.data;
      } catch (error) {
        //console.log(error.response.data);
        throw new Error(`Sorry: ${error.response.data.message}`);
      }
    }  );
};

export default useGetAuctionItemsManager;
