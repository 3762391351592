import React, { useEffect, useState } from "react";
import { MdCancel } from "react-icons/md";
import { GrFormNext } from "react-icons/gr";
import InputWithFullBoarder from "../../../generalComponents/InputWithFullBoarder";
import ModalManagement from "../../../generalComponents/ModalManagement";
import ButtonWithIcon from "../../../generalComponents/ButtonWithIcon";
import useGetStaffManager from "../../accessControl/usermanagement/controllers/getUsersController";
import { ScheduleInspectionManager } from "../controllers/scheduleInspectionController";
import MultiSelectWithFullBorder from "../../../generalComponents/MultiSelectWithFullBorder";

const ScheduleModal = ({ stockId, type }) => {
  const [staff, setStaff] = useState([]);
  const [scheduleDate, setScheduleDate] = useState("");
  const [selectedStaff, setSelectedStaff] = useState(null);

  const { data: staffList } = useGetStaffManager({ enabled: true });
  const { scheduleInspection, isSuccess } = ScheduleInspectionManager(
    stockId,
    type
  );

  useEffect(() => {
    if (staffList) {
      const options = staffList?.data?.results?.map((st) => ({
        value: st.id,
        label: `${st.user.first_name} ${st.user.last_name}`,
      }));
      setStaff(options);
    }
  }, [staffList]);

  const handleSchedule = () => {
    const schedule = {
      assigned_staffs: selectedStaff?.map((staff) => staff?.value),
      scheduled_date: scheduleDate,
    };

    scheduleInspection(schedule);
  };

  useEffect(() => {
    if (isSuccess) {
      document.getElementById("schedule_inspection").close();
    }
  }, [isSuccess]);

  return (
    <div>
      <ModalManagement id={"schedule_inspection"} title={"Schedule Inspection"}>
        <div className="md:w-[599px] h-auto rounded-[6px] relative bg-white flex flex-col pt-6">
          <div className="flex flex-col md:flex-row md:space-x-2 ">
            <div className="flex-1">
              <InputWithFullBoarder
                label={"Inspection Date"}
                placeholder={"Inspection Date"}
                type={"date"}
                value={scheduleDate}
                onChange={(e) => {
                  console.log("Date changed:", e.target.value);
                  setScheduleDate(e.target.value);
                }}
              />
            </div>
          </div>
          <div className="flex flex-col md:flex-row md:space-x-2 ">
            <div className="flex-1">
              <MultiSelectWithFullBorder
                label={"Assign Staff"}
                selectOptions={staff}
                value={selectedStaff ? selectedStaff.value : ""}
                onChange={setSelectedStaff}
                placeholder="Select Staff"
              />
            </div>
          </div>

          <div className="flex flex-col md:flex-row md:space-x-2">
            <ButtonWithIcon
              icon={MdCancel}
              buttonText={"Cancel"}
              textColor={"#ff0000"}
              className={"bg-red-500 border w-auto text-white"}
              onClick={() => {
                document.getElementById("schedule_inspection").close();
              }}
            />
            <ButtonWithIcon
              icon={GrFormNext}
              buttonText={"Continue"}
              onClick={handleSchedule}
            />
          </div>
        </div>
      </ModalManagement>
    </div>
  );
};

export default ScheduleModal;
