import { useQuery } from "react-query";
import AxiosWithToken from "../../../constants/api_management/MyHttpHelperWithToken";

const useGetReturnItemsManager = ({
  page,
  searchQuery = "",
  enabled = true,
  pageSize = "15",
  is_lender_client = false,
  is_borrower_client = false,
  stock_id = ""
}) => {
  return useQuery(['return', page, is_lender_client, is_borrower_client, stock_id, searchQuery], async () => {
    try {
      const response = await AxiosWithToken.get(`/stock/returns/?is_lender_client=${is_lender_client}&is_borrower_client=${is_borrower_client}&stock_id=${stock_id}&page=${page}&size=${pageSize}${searchQuery}`);
      return response.data;
    } catch (error) {
      throw new Error(`Sorry: ${error.response?.data?.message || 'An error occurred'}`);
    }
  });
};

export default useGetReturnItemsManager;