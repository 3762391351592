import React from "react";
import PillTabs from "../../../../generalComponents/PillTabs";
import StockBorrow from "../components/StockBorrow";
import StockExchange from "../components/StockExchange";
import StockReturn from "../components/StockReturn";
import StockSales from "../components/StockSales";

const StockRequests = ({ stock_id }) => {
  const tabsData = [
    {
      label: "Borrow Requests",
      component: <StockBorrow stock_id={stock_id} />,
    },
    {
      label: "Exchange Requests",
      component: <StockExchange stock_id={stock_id} />,
    },
    {
      label: "Return Requests",
      component: <StockReturn stock_id={stock_id} />,
    },
    { label: "Sales Requests", component: <StockSales stock_id={stock_id} /> },
  ];

  return (
    <div className="mt-0 md:mt-0 flex flex-col w-full gap-x-4">
      <PillTabs tabsData={tabsData} />
    </div>
  );
};
export default StockRequests;
