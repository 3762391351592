import React, { useEffect, useState } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { menus } from "../constants/menus";
import { logo } from "../assets/images";
import { MdKeyboardArrowDown } from "react-icons/md";
import CustomButton from "./Button";

const NavBar = () => {
  const [openMenu, setOpenMenu] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const router = useNavigate();
  // const localStorageObj =
  //   typeof window !== "undefined"
  //     ? JSON.parse(localStorage.getItem("dsa-user"))
  //     : null;
  // const { token } = localStorageObj ?? [];
  const location = useLocation();
  const pathname = location.pathname;
  useEffect(() => {
    const index = menus.findIndex((menu) => menu.path === pathname);
    setCurrentIndex(index !== -1 ? index : 0);
  }, [pathname]);

  const openCloseMenu = () => {
    setOpenMenu(!openMenu);
  };

  return (
    <div className="flex max-w-[1240px] lg:px-5 lg:justify-between lg:border border-b-[#E0E0E0] border-transparent lg:pb-6 items-end lg:mx-auto lg:my-0 text-black">
      <Link to={"/"}>
        <div className="hidden lg:flex mr-2 items-center  z-40 ">
          <img
            src={logo}
            alt="logo of dsa-therapy"
            className="h-[71.6px] w-auto"
          />
        </div>
      </Link>

      {/* main menu */}
      <div className="flex flex-col items-start">
        <ul className="hidden lg:flex text-16px font-medium  gap-12 lg:items-center mb-1 ml-1">
          {menus.map((menu, index) => (
            <li
              className={
                !menu.forTop
                  ? "hidden"
                  : menu.isHighlighted
                  ? "bg-brandGreen text-white rounded-md px-8 py-2"
                  : pathname === menu.path
                  ? `border-brandGreen border-b-[2px] `
                  : ""
              }
              key={index}
            >
              <button
                onClick={() => {
                  router(menu.path);
                  setCurrentIndex(index);
                }}
                // to={menu.isHighlighted ? "" : menu.path}
              >
                <h3
                  className={`hover:font-semibold duration-500 ${
                    pathname.includes(menu.path) ? "text-brandGreen" : ""
                  } transition flex items-center gap-2`}
                >
                  {menu.title}
                  {menu.title === "Categories" && (
                    <MdKeyboardArrowDown size={24} />
                  )}
                </h3>
              </button>
            </li>
          ))}
        </ul>
        {/* <div
          className={`hidden lg:flex text-12px font-normal font-dm-sans ${
            token ? "gap-16" : "gap-10"
          } lg:items-center`}
        >
          {token ? (
            <>
              {loggedInUser.map((menu, index) => (
                <div
                  className={
                    !menu.forTop
                      ? "hidden"
                      : pathname === menu.path
                      ? `w-20 h-[3px] rounded-full bg-transparent`
                      : "w-12 h-[2px] rounded-full bg-transparent"
                  }
                  key={index}
                ></div>
              ))}
            </>
          ) : (
            <>
              {menus
                .filter((item) => item.title !== "Appointments")
                .map((menu, index) => (
                  <div
                    className={
                      !menu.forTop
                        ? "hidden"
                        : pathname === menu.path
                        ? `w-14 h-[3px] rounded-full bg-underlineBlue`
                        : "w-12 h-[2px] rounded-full bg-transparent"
                    }
                    key={index}
                  ></div>
                ))}
            </>
          )}
        </div> */}
      </div>
      <CustomButton
        buttonText={"Log In"}
        onClick={() => {
          router("/login");
        }}
      />

      {/* <ul className="hidden lg:flex text-12px font-normal font-dm-sans gap-[10px] lg:items-center mb-1 ml-1">
        {!token ? (
          loginRegister.map((menu, index) => (
            <li
              className={`${
                index === 0
                  ? "border border-brandBlue rounded-full px-5 py-2"
                  : "border border-brandRed  bg-brandRed text-whiteColor rounded-full px-5 py-2"
              } cursor-pointer`}
              key={index}
              onClick={() => {
                router(menu.path);
                setCurrentIndex(index);
              }}
            >
              <button>{menu.title}</button>
            </li>
          ))
        ) : (
          <li
            className="border cursor-pointer border-brandRed  bg-brandRed text-whiteColor rounded-full px-5 py-2"
            onClick={() => {
              toast.success("Logged out successfully");
              setTimeout(() => {
                localStorage.removeItem("dsa-user");
                router("/");
              }, 1000);
            }}
          >
            <button>Logout</button>
          </li>
        )}
      </ul> */}

      {/* mobile starts here */}
      <div className="fixed px-5 lg:hidden right-0 z-50 bg-white top-0 py-3 flex items-center justify-between w-full">
        <Link to={"/"}>
          <div className="  mr-2 items-center z-40 ">
            <img src={logo} alt="emms logo" className="h-[45.17px] w-auto" />
          </div>
        </Link>
        <div className=" md:text-[30px] text-black " onClick={openCloseMenu}>
          {openMenu ? <FaTimes /> : <FaBars />}
        </div>
      </div>
      <ul
        className={`fixed lg:hidden ${
          openMenu ? "left-0" : "left-[-100%]"
        }  top-0 pt-8 pl-8 pr-8 bg-white h-full w-[70%] md:w-[40%] flex flex-col text-[16px] font-normal font-dm-sans space-y-8 items-left ease-in-out duration-500 z-50`}
      >
        <Link to={"/"}>
          <div className="flex mr-2 items-center">
            <img src={logo} alt="emms logo" className="h-[71.6px] " />
          </div>
        </Link>
        <ul>
          {
            <>
              {menus
                .filter((item) => item.title !== "Appointments")
                .map((menu, index) => (
                  <li
                    className={
                      !menu.forTop
                        ? "hidden"
                        : menu.isHighlighted
                        ? "border border-lemonGreen px-5 py-2 my-2 max-w-max bg-brandGreen text-white rounded-md"
                        : index === currentIndex
                        ? `border border-b-lemonGreen border-transparent border-b-[2px] max-w-max my-4`
                        : "my-4"
                    }
                    key={index}
                  >
                    <button
                      onClick={() => {
                        router(menu.path);
                        setCurrentIndex(index);
                      }}
                    >
                      {menu.title}
                    </button>
                  </li>
                ))}
            </>
          }
        </ul>
        <CustomButton buttonText={"Log In"} />
      </ul>
    </div>
  );
};

export default NavBar;
