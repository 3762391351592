import React from "react";
import HeaderFooter from "../../../generalComponents/HeaderFooter";
import InputWithFullBoarder from "../../../generalComponents/InputWithFullBoarder";
import EventTiles from "../../events/components/EventTiles";

const NewListingHome = () => {
  return (
    <HeaderFooter>
      <div className="max-w-[1240px] w-full flex flex-col text-blackColor mx-auto md:mt-10 mt-16">
        <div className="w-full justify-between items-center flex mt-10">
          <h3 className="text-28px font-semibold md:mx-0 mx-4">Bid Items</h3>
          <InputWithFullBoarder type={"date"} />
        </div>
        <div className="w-full grid grid-cols-1 md:grid-cols-3 2xl:grid-cols-4 gap-4 mb-20 md:mx-0 mx-4">
          {[...Array(8)].map((el, i) => (
            <EventTiles key={i} />
          ))}
        </div>
      </div>
    </HeaderFooter>
  );
};

export default NewListingHome;
