import React, { useEffect, useState } from "react";
import InputWithFullBoarder from "../../../generalComponents/InputWithFullBoarder";
import ModalManagement from "../../../generalComponents/ModalManagement";
import useGetInspectionByIdManager from "../controllers/getInspectionByIdController";
import StatusButton from "../../../generalComponents/StatusButton";
import { PostInspectionReportManager } from "../controllers/postInspectionReportController";
import { AiOutlinePaperClip } from "react-icons/ai";
import useGetInspectionReportManager from "../controllers/getInspectionReportController";
import { noImage } from "../../../assets/images";
import ButtonWithIcon from "../../../generalComponents/ButtonWithIcon";

const TaskReport = ({ inspectionId, itemName }) => {
  const [report, setReport] = useState("");
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState("");

  const { data: inspectionData } = useGetInspectionByIdManager(inspectionId);
  const { data: reportData } = useGetInspectionReportManager(inspectionId);
  const { postInspection, isSuccess } =
    PostInspectionReportManager(inspectionId);

  const inspectionDetails = inspectionData?.data;
  const inspectionReport = reportData?.data?.results;

  const handleSubmitReport = async () => {
    const payload = {
      report: report,
      report_document: file,
    };
    postInspection(payload);
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
      setFileName(selectedFile.name);
    }
  };

  useEffect(() => {
    if (isSuccess) {
      document.getElementById("task_report").close();
    }
  }, [isSuccess]);

  return (
    <ModalManagement
      id={"task_report"}
      title={
        inspectionDetails?.title || inspectionDetails?.stocks[0]?.asset_name
      }
    >
      <div className="w-[499px] h-auto rounded-[6px] relative bg-white flex flex-col pt-6">
        {inspectionDetails?.description && (
          <div className="flex py-3">
            <div className="flex w-1/3">
              <p className="text-[16px] text-[#101928] font-medium">
                Description:
              </p>
            </div>
            <div className="flex w-full justify-end">
              <p className="text-[16px] text-[#101928]">
                {" "}
                {inspectionDetails?.description}
              </p>
            </div>
          </div>
        )}
        {/* <div className="flex w-full border border-gray-100 my-2" />
        <div className="flex py-3">
          <div className="flex w-full">
            <p className="text-[16px] text-[#101928] font-medium">
              Approved Items:
            </p>
          </div>
          <div className="flex w-full justify-end">
            <p className="text-[16px] text-brandGreen underline">
              View Approved Items
            </p>
          </div>
        </div> */}
        <div className="flex w-full border border-gray-100 my-2" />
        <div className="flex py-3">
          <div className="flex w-full">
            <p className="text-[16px] text-[#101928] font-medium">
              Assigned To:
            </p>
          </div>
          <div className="flex w-full space-x-2 justify-end">
            {inspectionDetails?.assigned_staffs?.map((staff) => (
              <div key={staff.id}>
                {" "}
                <p className="text-[14px] p-0.5 px-2  rounded-xl bg-[#F1FCEE] text-[#358619] border border-[#D4F6CB] ">
                  {staff?.first_name + " " + staff?.last_name}
                </p>
              </div>
            ))}
          </div>
        </div>
        <div className="flex w-full border border-gray-100 my-2" />
        <div className="flex py-3">
          <div className="flex w-full">
            <p className="text-[16px] text-[#101928] font-medium">Date:</p>
          </div>
          <div className="flex w-1/3">
            <p className="text-[16px] text-[#101928]">
              {" "}
              {inspectionDetails?.scheduled_date}
            </p>
          </div>
        </div>
        <div className="flex w-full border border-gray-100 my-2" />

        <div className="flex py-3">
          <div className="flex w-1/3">
            <p className="text-[16px] text-[#101928] font-medium">Status:</p>
          </div>
          <div className="flex w-full justify-end">
            <StatusButton status={inspectionDetails?.status} />
          </div>
        </div>
        {inspectionReport?.map((inspection) => (
          <div>
            <div className="flex w-full border border-gray-100 my-2" />
            <div className="flex py-3">
              <div className="flex w-full gap-x-2 items-center">
                <img
                  src={noImage}
                  alt="Item"
                  className="w-10 h-10 rounded-full"
                />
                <p className="text-[16px] text-[#1F2227] font-medium">
                  {inspection?.staff?.first_name +
                    " " +
                    inspection?.staff?.last_name}
                </p>
                <p className="text-[12px] text-[#585C63]">
                  {new Date(inspection?.created_datetime).toLocaleDateString()}
                </p>
              </div>
            </div>
            <div className="flex w-full gap-x-2 items-center ml-8 mt-[-15px]">
              <p className="text-[14px] text-[#1F2227] font-medium ml-4">
                {inspection?.report}
              </p>
            </div>
            <div className="flex bg-[#F5F6FA] rounded-md p-2 ml-8 items-center justify-between my-3">
              <p className="text-[14px] text-[#1F2227] font-medium ml-4">
                {inspection?.report_document_title}
              </p>
              <ButtonWithIcon
                buttonText={"View Document"}
                textColor={"#344054"}
                className={"bg-white border border-gray-600 h-[27px] w-auto"}
                onClick={() => {
                  window.open(inspection?.report_document_url, "_blank");
                }}
              />
            </div>
          </div>
        ))}
        {inspectionDetails?.status === "ACCEPTED" && (
          <>
            <div className="flex w-full border border-gray-200 my-2"></div>
            <div className="flex flex-col md:flex-row md:space-x-2">
              <div className="flex-1">
                <InputWithFullBoarder
                  label={"Task Reports & Comments"}
                  placeholder={"Input Task Reports & Comments"}
                  isTextArea={true}
                  value={report}
                  onChange={(e) => setReport(e.target.value)}
                />
              </div>
            </div>

            {/* Attachment Section */}
            <div className="flex items-center justify-end mt-2 space-x-2">
              <label
                htmlFor="file-upload"
                className="flex items-center text-brandGreen cursor-pointer"
              >
                <AiOutlinePaperClip size={20} />
                <span className="ml-1">Attach File</span>
              </label>
              <input
                type="file"
                id="file-upload"
                className="hidden"
                onChange={handleFileChange}
                accept=".png, .jpeg, .jpg, .pdf"
              />
              {fileName && (
                <span className="text-sm text-gray-500">{fileName}</span>
              )}
            </div>
            <div className="flex justify-end space-x-2 mt-4 w-full">
              <ButtonWithIcon
                buttonText={"Cancel"}
                textColor={"#344054"}
                className={"bg-white border border-gray-600 h-[30px] w-auto"}
                onClick={() => {
                  document.getElementById("task_report").close();
                }}
              />
              <ButtonWithIcon
                buttonText={"Submit"}
                // textColor={"#344054"}
                // className={"bg-white border border-gray-600 h-[30px] w-auto"}
                onClick={handleSubmitReport}
              />
              {/* <CustomButton buttonText={"Submit"} onClick={handleSubmitReport} /> */}
            </div>
          </>
        )}
      </div>
    </ModalManagement>
  );
};

export default TaskReport;
