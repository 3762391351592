import usePostManager from "../../../constants/controller_templates/post_controller_template";

export const CreateProjectManager = () => {
  const { postCaller, isLoading, isSuccess, error, data } = usePostManager(
    "/projects/",
    ["projects"],
    true
  );
  const createProject = async (details) => {
    try {
      await postCaller(details);
    } catch (error) {
      console.error("error:", error);
    }
  };
  return {
    createProject,
    data,
    isLoading,
    isSuccess,
    error,
  };
};
