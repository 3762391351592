import React, { useState } from "react";
import PaginationRounded from "../../../generalComponents/Pagination";
import TablesComponent from "../../../generalComponents/TablesComponent";
import { useParams } from "react-router-dom";
import StatusButton from "../../../generalComponents/StatusButton";
import useGetBorrowInspectionByIdManager from "../controllers/getBorrowInspectionById";
import usePatchAcceptInspectionManager from "../../inspections/controllers/patchAcceptInspectionController";
import usePatchRejectInspectionManager from "../../inspections/controllers/patchRejectInspectionController";

const PendingInspections = () => {
  const { id } = useParams();
  const [setCurrentPage] = useState(1)
  

  const { data: Inspections, isLoading } = useGetBorrowInspectionByIdManager(id);

  
  const { approvalCaller } =
    usePatchAcceptInspectionManager({ id: id });
  const { rejectionCaller } =
    usePatchRejectInspectionManager({ id: id });
    

  const headers = [
    "S/N",
    "User",
    "Inspection Date",
    "Status",
    "Action",
  ];
  const options = [
    "Accept Inspection",
    "Reject Inspection",
  ];

  const getFormattedValue = (el, index) => {
    return [
      index + 1,
      el?.visiting_client,
      <span>{new Date(el?.scheduled_date).toLocaleDateString()}</span>,
      <span>
        <StatusButton status={el?.status} />
      </span>,
    ];
  };

  const handleApproveInspection = (id) => {
    approvalCaller({ id });
  };

  const handleRejectInspection = (id) => {
    rejectionCaller({ id });
  };

  return (
    <div className="mt-2 md:mt-2 flex flex-col w-full gap-4">
      <div className="h-[67vh] w-full relative">
        {
          <TablesComponent
            isLoading={isLoading}
            data={Inspections?.data?.results}
            getFormattedValue={getFormattedValue}
            headers={headers}
            popUpFunction={(option, index, val) => {
              console.log(option, index, val);
            
              if (index === 0) {
                handleApproveInspection(val.inspection_id);
              }
              if (index === 1) {
                handleRejectInspection(val.inspection_id);
              }
            }}
            options={options}
            // Close popup function
            // Close popup function
          />
        }
      </div>
      <div className="flex items-center justify-between mt-4">
        <p className="text-14px text-brandBlack">1-10 of 195 items</p>
        <PaginationRounded
          defaultPage={1}
          count={100}
          onChange={(page) => {
            setCurrentPage(page);
          }}
        />
      </div>
    </div>
  );
};
export default PendingInspections;
