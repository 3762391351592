import React from "react";
import BaseDashboardNavigation from "../../../generalComponents/BaseDashboardNavigation";
import Tabs from "../../../generalComponents/Tabs";
import BorrowPage from "../components/BorrowPage";
import BorrowRequests from "../components/BorrowRequests";
import BorrowItems from "../components/BorrowItems";
import BorrowedItems from "../components/BorrowedItems";

const Borrow = () => {
  const tabsData = [
    { label: "Borrow", component: <BorrowPage /> },
    { label: "Borrowed Items", component: <BorrowedItems /> },
    { label: "Incoming Requests", component: <BorrowRequests /> },
    { label: "Outgoing Requests", component: <BorrowItems /> },
  ];

  return (
    <BaseDashboardNavigation>
      <div>
        <h3 className="text-[24px] font-bold">Borrow</h3>
        <Tabs tabsData={tabsData} />
      </div>
    </BaseDashboardNavigation>
  );
};

export default Borrow;
