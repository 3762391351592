import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";

import IconsWithText from "./IconsWithText";
import { logo } from "../assets/images";

import { logoutIcon, notificationsIcon2, searchIcon } from "../assets/icons";

import { lowerMenu, rightclickMenu } from "../constants/menus";
// import { Menu } from "@mui/material";
import { FaBars, FaTimes } from "react-icons/fa";
import AxiosWithToken from "../constants/api_management/MyHttpHelperWithToken";
import { getInitials } from "../utils/getInitials";
import useGetUserDetailsManager from "../modules/settings/controllers/get_UserDetails_controller";
import NotificationDrawer from "../modules/notifications/view/NotificationDrawer";
import { ToastContainer } from "react-toastify";
import useGetPermissionsManager from "../modules/accessControl/roles/controllers/get_permissions_controller";
import { hasPermissions } from "../constants/permissions";
import Loader from "./Loader";

const BaseDashboardNavigation = ({
  children,
  title,
  onChange,
  value,
  hideSearch,
}) => {
  const [showMenu, setShowMenu] = useState(false);
  // const [showNotification, setShowNotification] = useState(false);

  const { data } = useGetUserDetailsManager();
  const {
    data: permissionData,
    isLoading: permissionsLoading,
    isSuccess: permissionsLoaded,
    refetch,
  } = useGetPermissionsManager();

  // const [isAdmin, setIsAdmin] = useState(false);
  const handleOpenMenu = () => {
    setShowMenu(!showMenu);
  };

  useEffect(() => {
    if (
      permissionsLoaded &&
      permissionData &&
      !localStorage.getItem("userPermissions")
    ) {
      // Store the entire permissions data structure
      localStorage.setItem(
        "userPermissions",
        JSON.stringify(permissionData.data)
      );

      refetch().then(() => {
        window.location.reload();
      });
    }
  }, [permissionData, permissionsLoaded, refetch]);

  const renderMenuItems = (menuItems) => {
    return menuItems
      .filter((el) => {
        // Only show items with requiredPermissions that the user has
        return el.requiredPermissions && hasPermissions(el.requiredPermissions);
      })
      .map((el) => (
        <Link
          key={el.path}
          className="w-[90%] mx-auto cursor-pointer"
          to={el.path}
        >
          <IconsWithText
            icon={el.icon}
            activeIcon={el.activeIcon}
            path={el.path}
            iconSize="28px"
            text={el.title}
          />
        </Link>
      ));
  };

  // const navigate = useNavigate();
  // const location = useLocation();
  // checking if it is admin or not
  // useEffect(() => {
  //   if (isSuccess) {
  //     // Call getUserRole when data is successfully loaded
  //     const getUserRole = () => {
  //       setIsAdmin(data.data.user.role.name === "admin");
  //     };
  //     if (data.data.user.role.name !== "admin") {
  //       if (
  //         data.data.user.fullname === "" ||
  //         data.data.user.sex === "" ||
  //         data.data.user.phone === ""
  //       ) {
  //         navigate("/dashboard/settings");
  //         window.my_modal_1.showModal();
  //       }
  //     }
  //     getUserRole(); // Call the function immediately
  //   }
  // }, [isSuccess, data]);

  // if (isError) {
  //   return `error.message`;
  // }

  // if (isLoading) {
  //   return <Loader />;
  // }

  const handleLogout = async () => {
    try {
      // Make the logout request
      await AxiosWithToken.post("/auth/logout");

      // Clear localStorage
      localStorage.removeItem("authToken"); // Specific removal of authToken
      localStorage.removeItem("userPermissions"); // Specific removal of authToken
      localStorage.clear();

      // Optionally, wait for localStorage to be cleared
      await new Promise((resolve) => setTimeout(resolve, 100));

      // Redirect to login page
      window.location.href = "/login";
    } catch (err) {
      console.error("Logout failed:", err);
      // Handle any logout errors if needed
    }
  };

  // const tokenExists = localStorage.getItem("token") !== null;

  // //console.log('--->>user<<---', data)
  // const isSalesRep = data?.data?.user?.referral_code?.length > 0;
  // const activeMenu =
  //   data?.data?.user?.role?.name === "admin"
  //     ? adminMenu
  //     : data?.data?.user?.shops?.length > 0
  //       ? vendorMenu.filter((item) => !item.isSalesRep || (item.isSalesRep && isSalesRep))
  //       : customerMenu.filter((item) => !item.isSalesRep || (item.isSalesRep && isSalesRep));

  // const activeMenu = adminMenu;

  return (
    <div className="relative md:fixed flex h-[100vh] w-full text-blackColor p-0">
      <div
        className={` o hidden md:flex ease-in-out duration-500 w-[20%] bg-whiteColor z-50 h-full   md:overflow-auto scrollbar-hide  flex-col  items-start justify-between gap-8  rounded-[10px] relative py-10`}
      >
        <div className="flex flex-col items-start w-full ">
          <Link to={"/"}>
            <div className="w-[90%] mx-auto mb-10">
              <img
                className="object-contain w-[100px] h-[67.98px] mx-2 hover:cusor"
                src={logo}
                alt="emms logo"
              />
            </div>
          </Link>
          {permissionsLoading ? <Loader /> : renderMenuItems(rightclickMenu)}{" "}
        </div>

        <div className="flex flex-col items-start w-full ">
          {lowerMenu.map((el) => (
            <Link className="w-[90%] mx-auto cursor-pointer " to={el?.path}>
              <IconsWithText
                icon={el?.icon}
                activeIcon={el?.activeIcon}
                path={el?.path}
                iconSize="28px"
                text={el?.title}
              />
            </Link>
          ))}
          <div className="flex w-[90%] mx-auto cursor-pointer items-center justify-between mt-10">
            <Link to={"/settings"}>
              <div className="flex gap-2 items-center">
                <div className="rounded-full h-[40px] w-[40px] border-brandPrimary border-solid border-2 bg-[#EDF6E6] p-1.5 mr-[7px] cursor-pointer">
                  <p className="text-16px font-semibold">
                    {getInitials(
                      `${data?.data?.user?.first_name} ${data?.data?.user?.last_name}`
                    )}
                  </p>
                </div>
                <div className="text-14px leading-tight ">
                  <p className="text-14px font-medium text-[#101928]">
                    {" "}
                    {data?.data?.user?.first_name} {data?.data?.user?.last_name}
                  </p>
                  <p className="text-14px font-normal text-[#344054]">
                    {data?.data?.user?.email}
                  </p>
                </div>
              </div>
            </Link>
          </div>
          <div
            className="flex justify-center cursor-pointer py-4 mr-3 gap-2 mt-4"
            onClick={handleLogout}
          >
            <p className="text-14px">Logout</p>
            <img
              className=" object-cover h-[20] w-[20] p-[0.7px] rounded-full "
              src={logoutIcon}
              alt="logout"
            />
          </div>
        </div>
      </div>
      {/* this is where the mobile menu is */}

      <div
        className={`fixed md:hidden ${
          showMenu ? "left-0" : "left-[-100%]"
        } ease-in-out duration-500 w-3/5 bg-lightGrey z-50 h-full pt-4  flex flex-col  items-start space-y-10 mr-7 overflow-y-scroll bg-white`}
      >
        <img
          className="object-contain w-[64px] h-[67.98px] mx-7"
          src={logo}
          alt="emms logo"
        />
        {permissionsLoading ? <Loader /> : renderMenuItems(rightclickMenu)}{" "}
      </div>

      {/* this is where the top section starts */}
      <div className="w-screen">
        <div className="flex flex-col max-h-[100vh] overflow-x-hidden">
          <div className="flex flex-col md:flex-row items-center justify-between text-left ml-0 md:ml-0 z-40 bg-white px-4 py-[15px] mb-[12px]">
            <div className="w-full md:w-auto flex items-center justify-between">
              <h3 className="text-[20px] font-bold ">{title}</h3>
              <div
                onClick={handleOpenMenu}
                className=" md:hidden  bg-white shadow-md p-3.5 h-14 border w-14 rounded-full"
              >
                {showMenu ? (
                  <FaTimes size={25} color="blackColor" />
                ) : (
                  <FaBars size={25} color="blackColor" />
                )}
              </div>
            </div>
            <div className="hidden md:flex justify-end items-center">
              <div
                className={` ${
                  hideSearch ? "hidden" : "flex"
                }  items-center justify-start pl-5 pr-4 py-3 h-[50px] mr-[10px] bg-[#F7F9FC] rounded-[60px] md:min-w-[280px] max-w-xs`}
              >
                <input
                  className="text-[12px] font-normal text-[#8E8E8E] bg-transparent outline-none focus:outline-none w-full"
                  placeholder="Search"
                  type="text"
                  value={value}
                  onChange={onChange}
                />
                <img src={searchIcon} alt="search_icon" />
              </div>
              <label
                htmlFor="user_notifications"
                // onClick={() => {
                //   document.getElementById("user_notifications").showModal();
                // }}
                className="drawer-button"
              >
                <img
                  src={notificationsIcon2}
                  alt="notification_icon"
                  className="mr-[10px]"
                />

                <NotificationDrawer />
              </label>

              {/* <button className="mr-[10px]"
                onClick={() => {
                  navigate('/messages')
                }}>
                <img src={dashboardIcon} alt="message_icon" />
              </button> */}
              {/* notification display */}
              {/* {showNotification && ( */}
              {/* <NotificationList showNotification={showNotification} /> */}

              <div className="rounded-full border-brandPrimary border-solid border-2 mr-[13px] cursor-pointer">
                {" "}
                <img
                  className=" object-cover h-[44px] w-[44px] p-[0.7px] rounded-full "
                  src={
                    // data?.data?.user?.profile_picture !== ""
                    data?.data?.user?.profile_image_url ||
                    `https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png`
                  }
                  alt="user avatar"
                />
              </div>
              <Link to={"/settings"}>
                <div>
                  <p className="flex items-center text-blackColor text-[13px] font-semibold mb-0">
                    {data?.data?.user?.first_name} {data?.data?.user?.last_name}
                    {/* <MdOutlineKeyboardArrowDown size={30} /> */}
                  </p>
                  {/* <p className="text-[#8E8E8E] text-[10px] capitalize">Admin</p> */}
                </div>
              </Link>
            </div>
          </div>
          <div className="max-h-[90%] ml-0 md:ml-3  pb-10 px-10 w-full">
            {children}
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default BaseDashboardNavigation;
