import React, { useState } from "react";

const MessageInput = () => {
  const [message, setMessage] = useState("");

  return (
    <div className="flex h-[62px] items-center border rounded-[8px] px-4 py-2 shadow-sm w-full">
      <svg
        width="20"
        height="22"
        viewBox="0 0 20 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5.21935 21.3043C3.81389 21.3043 2.49756 20.701 1.54754 19.739C-0.294885 17.8738 -0.807938 14.6183 1.77472 12.0041L12.3706 1.27564C13.4445 0.18866 14.8108 -0.232001 16.1185 0.123441C17.4044 0.471274 18.4566 1.5376 18.8011 2.83871C19.1511 4.16482 18.737 5.54855 17.6642 6.63552L7.53027 16.8966C6.952 17.4825 6.29763 17.8292 5.6411 17.8988C4.99 17.9684 4.36934 17.7575 3.93455 17.3173C3.14757 16.5172 3.03453 15.0161 4.34542 13.69L11.463 6.48335C11.7553 6.18769 12.2293 6.18769 12.5217 6.48335C12.8141 6.77901 12.8141 7.25945 12.5217 7.55511L5.40305 14.7629C4.78782 15.3846 4.7313 15.9792 4.99326 16.2455C5.10848 16.3607 5.2824 16.414 5.48349 16.3911C5.7911 16.3596 6.1422 16.1564 6.47155 15.8248L16.6054 5.56485C17.3011 4.86049 17.5674 4.03221 17.3555 3.23328C17.2495 2.8426 17.0444 2.48591 16.7601 2.19777C16.4758 1.90964 16.1218 1.69983 15.7326 1.58869C14.9435 1.37455 14.1239 1.64521 13.4282 2.34957L2.83235 13.078C0.858398 15.077 1.33123 17.3781 2.60517 18.6684C3.8802 19.9586 6.15089 20.4391 8.12593 18.4379L18.7218 7.70946C18.7909 7.63919 18.8733 7.58339 18.9642 7.54529C19.055 7.5072 19.1526 7.48758 19.2511 7.48758C19.3497 7.48758 19.4473 7.5072 19.5381 7.54529C19.629 7.58339 19.7114 7.63919 19.7805 7.70946C19.9212 7.85257 20 8.04521 20 8.24588C20 8.44655 19.9212 8.63919 19.7805 8.7823L9.18465 19.5108C7.93571 20.7738 6.53677 21.3043 5.21935 21.3043Z"
          fill="#667185"
        />
      </svg>

      <input
        type="text"
        placeholder="Type your Message"
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        className="flex-1 ml-4 outline-none border-none text-gray-600 placeholder-gray-400"
      />
    </div>
  );
};

export default MessageInput;
