import React from "react";
import HeaderFooter from "../../../generalComponents/HeaderFooter";
import { useParams } from "react-router-dom";
import useGetAuctionByIdManager from "../../auctionEvents/controllers/getAuctionByIdController";
import EventLotTiles from "../components/EventLotTiles";
import Loader from "../../../generalComponents/Loader";
import ButtonWithIcon from "../../../generalComponents/ButtonWithIcon";
import AuctionFeeModal from "../components/AuctionFeeModal";
import GoBackButton from "../../../generalComponents/GoBackButton";
import useGetAuctionParticipants from "../components/controllers/getAuctionParticipantsController";
import StatusButton from "../../../generalComponents/StatusButton";

const EventLots = () => {
  const { id } = useParams();
  const { data, isLoading } = useGetAuctionByIdManager(id);
  const { data: participants } = useGetAuctionParticipants(id);

  return (
    <HeaderFooter>
      <div className="max-w-[1240px] w-full flex flex-col text-blackColor mx-auto">
        <div
          className={`justify-between items-center flex flex-col mt-10 mb-2 w-full top-0 z-10 bg-white/90 backdrop-blur-sm`}
        >
          <GoBackButton />
        </div>{" "}
        <div className="w-full justify-between items-center flex md:mt-5 mt-16 mb-3">
          <h3 className="text-28px font-semibold md:mx-0 mx-4">
            {data?.data?.event_name}
          </h3>
          {data?.data?.entry_fee > 0 &&
            (participants?.data?.status === "PENDING" || !participants?.data ? (
              <ButtonWithIcon
                buttonText="Pay Auction Fee"
                onClick={() => {
                  document.getElementById("auction_fee_modal").showModal();
                }}
              />
            ) : (
              <StatusButton status={participants?.data?.status} />
            ))}
        </div>
        {isLoading && (
          <div className="w-full h-[50vh] flex justify-center items-center">
            <Loader />
          </div>
        )}
        <div className="w-full grid grid-cols-1 md:grid-cols-3 2xl:grid-cols-4 gap-4 mb-20 md:mx-0 mx-4">
          {data?.data?.lots?.map((el, i) => (
            <EventLotTiles
              key={i}
              eventName={el?.name}
              eventImage={el?.image_url}
              endDatetime={el?.auction?.end_datetime}
              id={el?.id}
              eventId={id}
            />
          ))}
        </div>
      </div>
      <AuctionFeeModal
        eventName={data?.data?.event_name}
        entryFee={data?.data?.entry_fee}
      />
    </HeaderFooter>
  );
};

export default EventLots;
