import React, { useState } from "react";
import TablesComponent from "../../../generalComponents/TablesComponent";
import PaginationRounded from "../../../generalComponents/Pagination";
import { useNavigate, useParams } from "react-router-dom";
import useGetItemsByProjectManager from "../controllers/getItemsByProjectController";
import BaseDashboardNavigation from "../../../generalComponents/BaseDashboardNavigation";
import { noImage } from "../../../assets/images";
import GoBackButton from "../../../generalComponents/GoBackButton";
import { calculatePaginationRange } from "../../../utils/calculatePaginationRange";
import truncateWithTooltip from "../../../utils/truncateText";

const ItemsByProject = ({ toggleRowFunction, toggleSelectAllFunction }) => {
  const { projectName } = useParams();
  const [setCurrentPage] = useState(1);
  const navigate = useNavigate();
  const [selectedRows, setSelectedRows] = useState([]);

  const { data, isLoading } = useGetItemsByProjectManager(projectName);

  const { startItem, endItem } = calculatePaginationRange(
    data?.data?.pagination
  );

  const headers = [
    "Image",
    "Asset Name",
    "Asset Code",
    // "Material",
    "Quantity",
    "Classification",
    "Storage Location",
    "Action",
  ];

  const getFormattedValue = (el, index) => {
    return [
      <span className="w-14 rounded-full">
        <img
          src={el.image || noImage}
          className="rounded-full w-12 h-12"
          alt="Item"
        />
      </span>,
      truncateWithTooltip(el?.asset_name),
      el?.asset_code,
      // el?.material,
      el?.quantity,
      el?.classification,
      el?.storage_location,
    ];
  };

  return (
    <BaseDashboardNavigation>
      <div className="max-w-max md:mt-4 pb-4">
        <GoBackButton />
      </div>
      <div className="flex flex-col md:flex-row items-start md:items-center justify-between w-full">
        <div className="flex items-center mb-0 md:mb-0">
          <h3 className="text-[24px] font-bold">{projectName} Project</h3>
        </div>
      </div>
      <div className="mt-2 md:mt-6 flex flex-col w-full gap-4">
        {/* <div className="flex flex-col md:flex-row justify-between items-center">
          <div className="">
            <DropdownandSearch
              options={dropdownOptions}
              placeholder="All Operators"
            />
          </div>
          <div className="flex space-x-[10px] mt-3 md:mt-0">
            <Dropdown options={dropdownOptions} placeholder="Select Action" />
            <ButtonWithIcon
              buttonText={"Download Report"}
              radius={"md"}
              icon={GoDownload}
            />
          </div>
        </div> */}
        <div className="h-[67vh] w-full relative">
          {
            <TablesComponent
              isLoading={isLoading}
              data={data?.data?.results}
              getFormattedValue={getFormattedValue}
              headers={headers}
              toggleRowFunction={toggleRowFunction}
              toggleSelectAllFunction={toggleSelectAllFunction}
              setSelectedRows={setSelectedRows}
              selectedRows={selectedRows}
              buttonFunction={(val) => {
                navigate(`/inventory/${val.id}`);
                console.log(val);
              }}
              showCheckBox={false}
            />
          }
        </div>
        <div className="flex items-center justify-between mt-4">
          <p className="text-14px text-brandBlack">
            {startItem} - {endItem} of {data?.data?.pagination?.count} Items
          </p>
          <PaginationRounded
            defaultPage={data?.data?.pagination?.page}
            count={data?.data?.pagination?.total_pages}
            onChange={(page) => {
              setCurrentPage(page);
            }}
          />
        </div>
      </div>
    </BaseDashboardNavigation>
  );
};

export default ItemsByProject;
