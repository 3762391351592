import { searchIcon } from "../assets/icons";

const SearchField = ({value, onChange, placeholder, className}) => {
  return (
    <div className="relative">
      <span className="absolute inset-y-0 left-0 flex items-center justify-center pl-2 ">
        <img src={searchIcon} alt="Search Icon" />
      </span>
      <input
      value={value}
      onChange={onChange}
        type="text"
        placeholder={placeholder ? placeholder : "Search here"}
        className={`${className} input input-bordered pl-8 h-[36px] text-[14px] font-[#8A919E] w-[254px] max-w-xs border border-[#667185] rounded-r-md`}
      />
    </div>
  );
};

export default SearchField;
