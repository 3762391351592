import React from "react";
import {
  blPattern,
  brPattern,
  tlPattern,
  trPattern,
} from "../../../assets/icons";

const Subscribe = () => {
  return (
    <div className="flex flex-col items-center justify-center text-center gap-8 p-16">
      <div className="relative rounded-2xl w-full md:w-[70%] bg-[#358619] text-white overflow-hidden">
        {/* Top Left Pattern */}
        <img
          src={tlPattern}
          alt="Top Left Pattern"
          className="absolute top-4 left-4 w-16 h-16" // Adjusted inward
        />
        {/* Top Right Pattern */}
        <img
          src={trPattern}
          alt="Top Right Pattern"
          className="absolute top-4 right-4 w-16 h-16" // Adjusted inward
        />
        {/* Bottom Left Pattern */}
        <img
          src={blPattern}
          alt="Bottom Left Pattern"
          className="absolute bottom-4 left-4 w-16 h-16" // Adjusted inward
        />
        {/* Bottom Right Pattern */}
        <img
          src={brPattern}
          alt="Bottom Right Pattern"
          className="absolute bottom-4 right-4 w-16 h-16" // Adjusted inward
        />

        {/* Card Content */}
        <div className="p-12">
          {/* Description */}
          <p className="font-clash font-medium text-[48px]">
            Unlock Efficiency with <br />
            e-MMS Today!
          </p>
          <p className="font-instrument font-regular text-16px mb-6">
            Subscribe for news and events updates today
          </p>

          {/* Input Field with Button */}
          <div className="relative flex items-center w-full max-w-md mx-auto">
            <input
              type="email"
              placeholder="Enter your email"
              className="w-full px-6 py-3 rounded-md bg-white text-black text-[16px] focus:outline-none"
            />
            <button
              type="submit"
              className="absolute right-1 top-1 bottom-1 px-6 py-2 bg-[#358619] text-white rounded-md text-[16px] hover:bg-[#2d7014] focus:outline-none"
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Subscribe;
