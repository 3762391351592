import React from "react";
import { backgroundPattern, thumbtack } from "../../../assets/icons";

const Testimonials = () => {
  const cardContent = [
    {
      cardDescription:
        "Our goal is to be a globally competitive e-procurement platform which provides fast, efficient, and transparent world-class supply chain activities in order to optimize the cost of doing business in Nigeria and, to make transparent, the activities in the oil and gas commercial space thus meeting the needs of all stakeholders and keeping faith with international transparency standards.",
      fullImage: "https://via.placeholder.com/600x200", // Replace with your full-width image URL
      profileImage: thumbtack, // Replace with actual profile image
      name: "Henry Orton",
      position: "CEO of NipeX",
    },
  ];

  return (
    <div className="my-6 py-12 w-full items-center justify-center">
      {/* Section Header */}
      <p className="font-clash font-medium text-[14px] text-center text-brandGreen mb-2">
        FROM THE HEAD'S DESK
      </p>
      <h3 className="font-clash font-medium text-[48px] text-center text-blackColor mb-8">
        Our Head has a word for you
      </h3>

      {/* Card Container */}
      <div className="flex flex-col items-center justify-center gap-8 px-16">
        {cardContent.map((item, index) => (
          <div
            key={index}
            className="rounded-[10px] w-full md:w-[70%] bg-[#1E6846] text-white overflow-hidden"
          >
            {/* Full-width Image */}
            <div className="w-full h-[200px]">
              <img
                src={backgroundPattern}
                alt="Full width"
                className="w-full h-full object-cover"
              />
            </div>

            {/* Card Content */}
            <div className="p-12">
              {/* Description */}
              <p className="font-instrument font-medium text-24px mb-6">
                {item.cardDescription}
              </p>

              {/* Profile Section */}
              <div className="flex items-center gap-4">
                {/* Profile Image */}
                <div className="w-12 h-12 rounded-full bg-white flex items-center justify-center">
                  <img
                    src={backgroundPattern}
                    alt="Profile"
                    className="w-10 h-10 rounded-full object-cover"
                  />
                </div>

                {/* Name and Position */}
                <div>
                  <h4 className="font-instrument font-medium text-16px">
                    {item.name}
                  </h4>
                  <p className="font-instrument font-regular text-14px">
                    {item.position}
                  </p>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Testimonials;
