import { useQuery } from "react-query";
import AxiosWithToken from "../../../../constants/api_management/MyHttpHelperWithToken";

const useGetAuditLogsById = ({id}) => {
  return useQuery([id, 'logbyId'], async () => {
    try {
      const response = await AxiosWithToken.get(`/users/activities?user_id=${id}`);
      //console.log(`i am checking this ${response.status}`);
      return response.data;
    } catch (error) {
      //console.log(error.response.data);
      throw new Error(`Sorry: ${error.response.data.message}`);
    }
  },     {
    enabled: !!id
  });
};

export default useGetAuditLogsById;
