import React, { useState } from "react";
import PaginationRounded from "../../../generalComponents/Pagination";
import TablesComponent from "../../../generalComponents/TablesComponent";
import { useParams } from "react-router-dom";
import StatusButton from "../../../generalComponents/StatusButton";
import useGetInspectionsManager from "../controllers/getGetInspectionsController";
import usePatchAcceptInspectionManager from "../controllers/patchAcceptInspectionController";
import usePatchRejectInspectionManager from "../controllers/patchRejectInspectionController";
import RescheduleModal from "../../Requests/components/RescheduleModal";
import DropdownandSearch from "../../../generalComponents/DropdownandSearch";
import { calculatePaginationRange } from "../../../utils/calculatePaginationRange";

const IncomingInspections = () => {
  const { id } = useParams();
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [statusFilter, setStatusFilter] = useState("");
  const [inspectionId, setInspectionId] = useState("");
  const [assignedStaff, setAssignedStaff] = useState("");

  const { data, isLoading } = useGetInspectionsManager({
    isStockOwnerClient: true,
    page: currentPage,
    assetName: searchTerm,
    status: statusFilter,
  });

  const { approvalCaller } = usePatchAcceptInspectionManager({ id: id });
  const { rejectionCaller } = usePatchRejectInspectionManager({ id: id });

  const { startItem, endItem } = calculatePaginationRange(
    data?.data?.pagination
  );

  const headers = [
    "S/N",
    "Title",
    "User",
    "Inspection Date",
    "Status",
    "Action",
  ];

  const options = [
    {
      label: "Accept Inspection",
      permissions: ["accept_inspection"],
    },
    { label: "Reschedule Inspection", permissions: ["reschedule_inspection"] },
    { label: "Reject Inspection", permissions: ["reject_inspection"] },
  ];

  const statusOptions = [
    { value: "", label: "All" },
    { value: "PENDING", label: "Pending" },
    { value: "ACCEPTED", label: "Accepted" },
    { value: "REJECTED", label: "Rejected" },
    { value: "REPORTED", label: "Reported" },
  ];

  const getFormattedValue = (el, index) => {
    return [
      index + 1,
      el?.title,
      el?.visiting_client,
      <span>{new Date(el?.scheduled_date).toLocaleDateString()}</span>,
      <span>
        <StatusButton status={el?.status} />
      </span>,
    ];
  };

  const handleApproveInspection = (id) => {
    approvalCaller({ id });
  };

  const handleRejectInspection = (id) => {
    rejectionCaller({ id });
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1);
  };

  const handleStatusChange = (e) => {
    setStatusFilter(e.target.value);
    setCurrentPage(1);
  };

  return (
    <div className="mt-2 md:mt-2 flex flex-col w-full gap-4">
      <div className="w-full flex flex-col gap-7 sticky top-0 bg-[#F8F9FC]">
        <div className="flex flex-col md:flex-row justify-between items-center">
          <div className="flex items-center gap-3">
            <DropdownandSearch
              dropdownplaceholder={"Filter by status"}
              options={statusOptions}
              onChange={handleSearchChange}
              value={searchTerm}
              selectedOption={statusFilter}
              onSelect={handleStatusChange}
            />
          </div>
        </div>
      </div>
      <div className="h-[67vh] w-full relative">
        {
          <TablesComponent
            isLoading={isLoading}
            data={data?.data?.results}
            getFormattedValue={getFormattedValue}
            headers={headers}
            popUpFunction={(option, index, val) => {
              console.log(option, index, val);

              if (index === 0) {
                handleApproveInspection(val.id);
              }
              if (index === 1) {
                setInspectionId(val?.id);
                setAssignedStaff(null);
                document.getElementById("reschedule_inspection").showModal();
              }
              if (index === 2) {
                handleRejectInspection(val.id);
              }
            }}
            options={options}
          />
        }
      </div>
      <div className="flex items-center justify-between mt-4">
        <p className="text-14px text-brandBlack">
          {" "}
          {startItem} - {endItem} of {data?.data?.pagination?.count} Inspections
        </p>
        <PaginationRounded
          defaultPage={data?.data?.pagination?.page}
          count={data?.data?.pagination?.total_pages}
          onChange={(page) => {
            setCurrentPage(page);
          }}
        />
      </div>
      <RescheduleModal
        inspectionId={inspectionId}
        assignedStaff={assignedStaff}
      />
    </div>
  );
};
export default IncomingInspections;
