"use client";
import React from "react";
import NavBar from "./NavBar";
import Footer from "./Footer";
import { ToastContainer } from "react-toastify";

const HeaderFooter = ({ children, className }) => {
  return (
    <div className={className ?? "bg-whiteColor"}>
      <ToastContainer />
      <NavBar />
      {children}
      <Footer />
    </div>
  );
};

export default HeaderFooter;
