export const formatNumber = (value) => {
    const number = parseFloat(value); // Convert the input to a float
    if (isNaN(number)) {
      return value; // Return as is if it's not a number
    }
    return number.toLocaleString(undefined, {
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    });
  };
  