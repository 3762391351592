import { useQuery } from "react-query";
import AxiosWithToken from "../../../../constants/api_management/MyHttpHelperWithToken";

const DownloadStockBatchManager = ({batch_id}) => {
  return useQuery(['stock', batch_id], async () => {
    try {
      const response = await AxiosWithToken.get(`/stock/uploads/batches/${batch_id}/export/`, {
        responseType: 'blob'
      });
      
      //console.log(`i am checking this ${response.status}`);
      return response.data;
    } catch (error) {
      //console.log(error.response.data);
      throw new Error(`Sorry: ${error.response.data.message}`);
    }
    
  },
  {
    enabled: !!batch_id,
  });
};

export default DownloadStockBatchManager;

