import React, { useEffect, useState } from "react";
import ModalManagement from "../../../generalComponents/ModalManagement";
import CustomButton from "../../../generalComponents/Button";
import { UploadReceiptManager } from "./controllers/UploadReceiptController";
import { useParams } from "react-router-dom";
import InputWithFullBoarder from "../../../generalComponents/InputWithFullBoarder";
import { formatNumber } from "../../../utils/formatNumber";

const ReceiptUploadModal = ({ entryFee }) => {
  const { id } = useParams();
  const [file, setFile] = useState(null);
  const [dragging, setDragging] = useState(false);
  const [bankName, setBankName] = useState("");
  const [receiptId, setReceiptId] = useState("");
  const [depositorName, setDepositorName] = useState("");
  const [paymentDate, setPaymentDate] = useState("");
  const [amount, setAmount] = useState(0);

  const { submitReceipt, isSuccess } = UploadReceiptManager(id);

  const handleFileChange = (event) => {
    setFile(event?.target?.files[0]);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    setDragging(true);
  };

  const handleDragLeave = () => {
    setDragging(false);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    setDragging(false);
    setFile(event?.dataTransfer?.files[0]);
  };

  // const handleUpload = async () => {
  //   if (file) {
  //     try {
  //       await uploadReceipt(file);
  //     } catch (uploadError) {
  //       console.error("Upload error:", uploadError);
  //     }
  //   } else {
  //     alert("Please select a file first.");
  //   }
  // };

  const handleSubmitReceipt = async () => {
    const payload = {
      receipt: file,
      amount: amount,
      payment_date: paymentDate,
      sender_bank_name: bankName,
      sender_name: depositorName,
      reference: receiptId,
    };
    submitReceipt(payload);
  };

  useEffect(() => {
    if (isSuccess) {
      document.getElementById("receipt_upload").close();
      setFile(null);
    }
  }, [isSuccess]);

  return (
    <ModalManagement id={"receipt_upload"} title={"Transfer/Receipt Upload"}>
      <div className="border border-gray-300 p-4 mt-2 rounded-md">
        <h3 className="font-semibold mb-2">Make payment to this account:</h3>
        <div className="flex justify-between py-4">
          <p>Bank Name:</p>
          <p className="font-medium">GTBank</p>
        </div>
        <div className="border-t border-[0.5px] border-gray-200 justify-center" />
        <div className="flex justify-between py-4">
          <p>Account Number:</p>
          <p className="font-medium">0047292870 </p>
        </div>
        <div className="border-t border-[0.5px] border-gray-200 justify-center" />
        <div className="flex justify-between py-4">
          <p>Account Name:</p>
          <p className="font-medium">NNPC UIMS Main IBTC </p>
        </div>
        <div className="border-t border-[0.5px] border-gray-200 justify-center" />
        <div className="flex justify-between pt-4">
          <p>Amount to be Paid:</p>
          <p className="font-semibold">₦{formatNumber(entryFee)}</p>
        </div>
      </div>

      <div className="flex items-center justify-center my-4">
        <div className="flex-grow border-t border-gray-300"></div>
        <span className="px-2 text-gray-500 text-[14px]">
          Please fill out the form below after making payment
        </span>
        <div className="flex-grow border-t border-gray-300"></div>
      </div>

      <div className="flex flex-col md:flex-row md:space-x-2">
        <div className="flex-1">
          <InputWithFullBoarder
            label={"Bank Name"}
            placeholder={"Enter Name"}
            value={bankName}
            onChange={(e) => setBankName(e.target.value)}
            type={"text"}
            required
          />
        </div>
      </div>
      <div className="flex flex-col md:flex-row md:space-x-2">
        <div className="flex-1">
          <InputWithFullBoarder
            label={"Amount Paid"}
            placeholder={"Enter Amount Paid"}
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            type={"number"}
            required
          />
        </div>
        <div className="flex-1">
          <InputWithFullBoarder
            label={"Payment Date"}
            placeholder={"Payment Date"}
            value={paymentDate}
            onChange={(e) => setPaymentDate(e.target.value)}
            type={"date"}
            required
          />
        </div>
      </div>
      <div className="flex flex-col md:flex-row md:space-x-2">
        <div className="flex-1">
          <InputWithFullBoarder
            label={"Depositor's Name"}
            placeholder={"Enter Depositor's Name"}
            value={depositorName}
            onChange={(e) => setDepositorName(e.target.value)}
            type={"text"}
            required
          />
        </div>
        <div className="flex-1">
          <InputWithFullBoarder
            label={"Receipt ID"}
            placeholder={"Enter Receipt ID"}
            value={receiptId}
            onChange={(e) => setReceiptId(e.target.value)}
            type={"text"}
            required
          />
        </div>
      </div>

      <div
        className={`w-auto h-[[auto]] rounded-[6px] relative bg-white flex flex-col pt-6 ${
          dragging ? "bg-gray-100" : ""
        }`}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
      >
        <p className="text-[14px] pb-2">
          Upload Receipt <span className="text-red-700 text-[14px]">*</span>
        </p>
        <div className="flex flex-col items-center justify-center h-auto">
          <div className="w-[473px] h-[100px] text-center border border-dashed border-gray-400 mb-4 rounded-md flex flex-col items-center justify-center">
            <input
              type="file"
              id="fileInput"
              className="hidden"
              onChange={handleFileChange}
              accept=".pdf, .png, .jpg, .jpeg"
            />
            <label
              htmlFor="fileInput"
              className="cursor-pointer flex flex-col items-center"
            >
              <p className="text-[14px]">
                <span className="font-medium text-brandGreen">
                  Click to Upload
                </span>{" "}
                or drag and drop{" "}
              </p>
              <p className="text-[12px] text-[#98A2B3]">
                File format: .pdf, .png, .jpg, .jpeg
              </p>
            </label>
            {file && (
              <p className="mt-2 text-[14px]">Selected file: {file?.name}</p>
            )}
          </div>
        </div>
        <div className="flex justify-end space-x-2 mt-4 w-full">
          <CustomButton
            buttonText={"Cancel"}
            textColor={"#344054"}
            className="bg-white border border-gray-600"
            onClick={() => {
              document.getElementById("receipt_upload").close();
            }}
          />
          <CustomButton
            buttonText={"Submit Receipt"}
            onClick={handleSubmitReceipt}
          />
        </div>
      </div>
    </ModalManagement>
  );
};

export default ReceiptUploadModal;
