const config = {
  development: {
    BASE_URL: "https://dev.v2.emmsapis.com/api/v1",
  },
  staging: {
    BASE_URL: "https://stage.v2.emmsapis.com/api/v1",
  },
  production: {
    BASE_URL: "https://dev.v2.emmsapis.com/api/v1",
  },
  local: {
    BASE_URL: "https://emms-v2-0cd54c2c1466.herokuapp.com/api/v1",
  },
};

export default config[process.env.REACT_APP_ENV || "local"];
