import { useMutation } from "react-query";

import { toast } from "react-toastify";
import Axios from "../../../constants/api_management/MyHttpHelper";

const useLoginManager = (email) => {
  const loginController = async (details) => {
    try {
      const response = await Axios.post(`/auth/login`, details);

      return response?.data;
    } catch (error) {
      throw new Error(`${error?.response?.data?.message}`);
    }
  };

  const mutation = useMutation(loginController, {
    onSuccess: async (data) => {
      if (data?.data?.token) {
        // If a token is found, store it and navigate to the dashboard
        localStorage.setItem("token", data?.data?.token);
        window.location.href = "/dashboard";
      } else {
        // Otherwise, navigate to the OTP page
        window.location.href = `/otp?email=${email}`;
      }
    },

    onError: (error) => {
      // Handle error if necessary
      toast.error(error?.message);
      console.error("Login error:", error);
    },
  });

  const login = async (details) => {
    try {
      await mutation.mutateAsync(details);
    } catch (error) {
      // Handle error if necessary
      console.error("Login error:", error);
    }
  };

  return {
    login,
    data: mutation?.data,
    isLoading: mutation?.isLoading,
    isSuccess: mutation?.isSuccess,
    error: mutation?.error,
  };
};

export default useLoginManager;
