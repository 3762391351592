import { useQuery } from "react-query";
import AxiosWithToken from "../../../../constants/api_management/MyHttpHelperWithToken";

const useGetBidByLotId = ({id, enabled}) => {
  return useQuery([id, 'bid'], async () => {
    try {
      const [response] = [await AxiosWithToken.get(`/lots/${id}/bids/`)];
      //console.log(`i am checking this ${response.status}`);
      return response.data;
    } catch (error) {
      //console.log(error.response.data);
      throw new Error(`Sorry: ${error.response.data.message}`);
    }
  },     {
    enabled: !!id
  });
};

export default useGetBidByLotId;
