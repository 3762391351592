import React, { useEffect, useState } from "react";
import ModalManagement from "../../../../generalComponents/ModalManagement";
import InputWithFullBoarder from "../../../../generalComponents/InputWithFullBoarder";
import ButtonWithIcon from "../../../../generalComponents/ButtonWithIcon";
import { BiPlus } from "react-icons/bi";
import { DeclareDisposalManager } from "../controllers/declareDisposalController";

const DeclareItemModal = ({ selectedStock }) => {
  const [disposalQuantity, setDisposalQuantity] = useState(0);
  const [requestId, setRequestId] = useState("");
  const [selectedTransferOption] = useState("");
  const [selectedProject] = useState("");

  const { declareDisposal, isSuccess } = DeclareDisposalManager(selectedStock);

  const handleTransferAsset = () => {
    const payload = {
      allocation: selectedTransferOption,
      quantity: disposalQuantity,
      project: selectedProject,
    };

    declareDisposal(payload);
  };

  useEffect(() => {
    if (isSuccess) {
      document.getElementById("declare_asset").close();
    }
  }, [isSuccess]);

  return (
    <div>
      <ModalManagement id={"declare_asset"} title={"Declare for Disposal"}>
        <div className="md:w-[599px] h-auto rounded-[6px] relative bg-white flex flex-col pt-6">
          <div className="flex flex-col md:flex-row md:space-x-2">
            <div className="flex-1">
              <InputWithFullBoarder
                label={"Request ID"}
                placeholder={"Enter Request ID"}
                type={"text"}
                value={requestId}
                onChange={(e) => setRequestId(e.target.value)}
                disabled
              />
            </div>
          </div>
          <div className="flex flex-col md:flex-row md:space-x-2">
            <div className="flex-1">
              <InputWithFullBoarder
                label={"Quantity"}
                placeholder={"Enter Quantity"}
                type={"number"}
                value={disposalQuantity}
                onChange={(e) => setDisposalQuantity(e.target.value)}
                required
              />
            </div>
          </div>
          <div className="flex flex-col md:flex-row md:space-x-2 justify-end">
            <ButtonWithIcon
              icon={BiPlus}
              buttonText={"Cancel"}
              textColor={"#344054"}
              className={"bg-white border border-gray-600 h-[40px] w-auto"}
              onClick={() => {
                document.getElementById("declare_asset").close();
              }}
            />
            <ButtonWithIcon
              icon={BiPlus}
              buttonText={"Transfer Asset"}
              onClick={handleTransferAsset}
            />
          </div>
        </div>
      </ModalManagement>
    </div>
  );
};

export default DeclareItemModal;
