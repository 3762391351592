import React, { useState } from "react";
import { logo } from "../../../assets/images";
import CustomButton from "../../../generalComponents/Button";
import LeftSideComponent from "../components/LeftSideComponent";
import InputWithFullBoarder from "../../../generalComponents/InputWithFullBoarder";
import usePasswordResetManager from "../controller/getPasswordResetController";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";

const ForgotPassword = () => {
  const [email, setEmail] = useState();
  const navigate = useNavigate();

  const { login, isLoading, isSuccess } = usePasswordResetManager(email);

  const handleSubmission = async (e) => {
    e.preventDefault();
    const details = {
      email: email,
    };

    await login(details);

    if (isSuccess) {
      navigate("/reset-password", { state: { email } });
    }
  };

  return (
    <div className="flex flex-col md:flex-row sm:h-screen">
      {/* Left side with image and overlay */}
      <ToastContainer />
      <LeftSideComponent />

      {/* Right side with login form */}
      <div className="w-full md:w-1/2 flex flex-col justify-center items-center bg-white p-8">
        <div className="w-full max-w-md">
          <img
            src={logo}
            alt="EMMS Logo"
            className="mb-[105px] mx-auto h-[62px] w-auto object-contain"
          />
          <h2 className="text-2xl md:text-3xl font-bold mb-2 mt-3 text-center">
            Forgot Password
          </h2>
          <p className="text-gray-600 mb-6 text-center">
            Enter your email address to reset your password
          </p>

          <form onSubmit={handleSubmission}>
            <InputWithFullBoarder
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="h-[56px]"
              required
            />

            <CustomButton
              buttonText={"Reset Password"}
              isLoading={isLoading}
              radius={"md"}
              className="w-full"
              type="submit"
            />
          </form>
        </div>
        <div className="flex items-center justify-center mt-4 w-full max-w-md">
          <a
            className="inline-block align-baseline text-sm font-semibold text-brandGreen underline"
            href="/login"
          >
            Back to Login
          </a>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
