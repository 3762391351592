import React, { useEffect, useRef, useState } from "react";
import ModalManagement from "../../../../generalComponents/ModalManagement";
import CustomButton from "../../../../generalComponents/Button";
import { uploadIcon } from "../../../../assets/icons";
import { DisposalDetailsUpdate } from "../controllers/DisposalDetailsUpdateController";
import { DisposalDocumentsUpdate } from "../controllers/DisposalDocumentsUpdateController";

const LetterUploadModal = ({ itemId, letterType }) => {
  const fileInputRef = useRef(null);
  const [file, setFile] = useState(null);
  const [dragging, setDragging] = useState(false);

  const modalId = `${letterType}`;
  const modalTitle = `Upload ${
    letterType === "request_letter"
      ? "Request Letter"
      : letterType === "approval_letter"
      ? "Approval Letter"
      : letterType === "other_documents"
      ? "Other Documents"
      : "None"
  }`;

  const {
    updateDisposal,
    isSuccess,
  } = DisposalDetailsUpdate(itemId);

  const {
    uploadDocuments,
    uploaded,
  } = DisposalDocumentsUpdate(itemId);

  const handleFileChange = (event) => {
    setFile(event?.target?.files[0]);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    setDragging(true);
  };

  const handleDragLeave = () => {
    setDragging(false);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    setDragging(false);
    setFile(event?.dataTransfer?.files[0]);
  };

  const handleUpload = async () => {
    if (file) {
      try {
        if (letterType === "other_documents") {
          await uploadDocuments(file);
        } else {
          await updateDisposal(file, letterType);
        }
        setFile(null);
      } catch (uploadError) {
        console.error("Upload error:", uploadError);
      }
    } else {
      alert("Please select a file first.");
    }
  };
  

  useEffect(() => {
    if (isSuccess || uploaded) {
      document.getElementById(modalId).close();
    }
  }, [isSuccess, uploaded, modalId]);

  return (
    <ModalManagement id={modalId} title={modalTitle}>
      <div
        className={`md:w-[599px] h-auto rounded-[6px] relative bg-white flex flex-col pt-6 ${
          dragging ? "bg-gray-100" : ""
        }`}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
      >
        <div className="flex flex-col items-center justify-center h-auto">
          <div className="w-[473px] h-[264px] text-center border border-dashed border-gray-400 mb-4 rounded-md flex flex-col items-center justify-center">
            <img src={uploadIcon} className="mb-4" alt="Upload Icon" />
            <input
              type="file"
              id="fileInput"
              className="hidden"
              onChange={handleFileChange}
              accept=".pdf, .png, .jpg, .jpeg"
            />
            <label
              htmlFor="fileInput"
              className="cursor-pointer flex flex-col items-center"
            >
              <p className="text-[14px]">
                <span className="font-medium text-brandGreen">
                  Click to Upload
                </span>{" "}
                or drag and drop
              </p>
              <p className="text-[12px] text-[#98A2B3]">File format: .png, .jpeg, .jpg, .pdf</p>
            </label>
            {file && (
              <p className="mt-2 text-[14px]">Selected file: {file?.name}</p>
            )}
            <div className="w-[90%] text-center border-t border-[#F0F2F5] relative my-6"></div>
            <div className="">
              <CustomButton
                buttonText={"Browse Files"}
                onClick={() => fileInputRef?.current?.click()}
              />
              <input
                ref={fileInputRef}
                type="file"
                id="fileInput"
                className="hidden"
                onChange={handleFileChange}
                accept=".pdf, .png, .jpg, .jpeg"
              />{" "}
            </div>
          </div>
        </div>
        <div className="flex justify-end space-x-2 mt-4 w-full">
          <CustomButton
            buttonText={"Cancel"}
            textColor={"#344054"}
            className="bg-white border border-gray-600"
            onClick={() => {
              document.getElementById(modalId).close();
            }}
          />
          <CustomButton buttonText={"Upload"} onClick={handleUpload} />
        </div>
      </div>
    </ModalManagement>
  );
};

export default LetterUploadModal;
