import React, { useState } from "react";
import PaginationRounded from "../../../../generalComponents/Pagination";
import TablesComponent from "../../../../generalComponents/TablesComponent";
import useGetStockActivityLogsManager from "../../stockRequests/controllers/getStockActivityLogsController";
import { calculatePaginationRange } from "../../../../utils/calculatePaginationRange";

const StockActivityLogs = ({ stock_id }) => {
  const [currentPage, setCurrentPage] = useState(1);

  const { data, isLoading } = useGetStockActivityLogsManager({
    enabled: true,
    page: currentPage,
    stock_id: stock_id,
  });

  const { startItem, endItem } = calculatePaginationRange(
    data?.data?.pagination
  );

  const headers = ["Activity", "Date"];

  const getFormattedValue = (el, index) => {
    return [
      el?.description,
      <span>{new Date(el?.created_datetime).toLocaleDateString()}</span>,
    ];
  };


  return (
    <div className="mt-0 md:mt-2 flex flex-col w-full gap-4">
      <div className="h-[67vh] w-full relative">
        {
          <TablesComponent
            isLoading={isLoading}
            data={data?.data?.results}
            getFormattedValue={getFormattedValue}
            headers={headers}
            hideActionButton={true}
            showCheckBox={false}
          />
        }
      </div>
      <div className="flex items-center justify-between mt-4">
        <p className="text-14px text-brandBlack">
          {" "}
          {startItem} - {endItem} of {data?.data?.pagination?.count} Items
        </p>
        <PaginationRounded
          defaultPage={data?.data?.pagination?.page}
          count={data?.data?.pagination?.total_pages}
          onChange={(page) => {
            setCurrentPage(page);
          }}
        />
      </div>
    </div>
  );
};
export default StockActivityLogs;
