import React from "react";
import GoBackButton from "../../../generalComponents/GoBackButton";
import { noImage } from "../../../assets/images";
import StatusButton from "../../../generalComponents/StatusButton";
import { useParams } from "react-router-dom";
import Countdown from "../../../generalComponents/CountDown";
import { formatDateAndTime } from "../../../utils/formatDateAndTime";
import { formatDateTimeAndSeconds } from "../../../utils/formatDateTimeAndSeconds";
import { formatNumber } from "../../../utils/formatNumber";
import { firstIcon, secondIcon, thirdIcon } from "../../../assets/icons";
import useGetBidByLotId from "../../events/components/controllers/getBidByLotIdController";
import BaseDashboardNavigation from "../../../generalComponents/BaseDashboardNavigation";
import useGetAuctionByIdManager from "../controllers/getAuctionByIdController";

const AuctionDetails = () => {
  const { id } = useParams();
  const { data: bids } = useGetBidByLotId({ id });

  const { data } = useGetAuctionByIdManager(id);

  console.log("data", data?.data);

  const highestBid = bids?.data?.results[0]?.amount;

  const lotItems = data?.data?.lots;

  const bidHistory = bids?.data?.results;

  const totalBids = bids?.data?.results?.length;

  return (
    <BaseDashboardNavigation>
      <div
        className={`max-w-[1240px] w-full flex flex-col text-blackColor  md:mx-auto mx-4 relative`}
      >
        {/* Header Section */}
        <div
          className={`justify-between items-center flex flex-col mt-10 mb-2 w-full top-0 z-10 backdrop-blur-sm`}
        >
          <GoBackButton />
          <h3 className="text-3xl font-semibold w-full text-left leading-tight mt-4">
            {data?.data?.event_name}
          </h3>
        </div>{" "}
        {/* Header Section */}
        <div className="flex flex-col gap-8 mt-4">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 mx-auto w-full ">
            {/* Left Column - Lot Information */}
            <div className="bg-white p-0 rounded-lg border border-gray-200 h-fit ">
              <div className="p-4 rounded-t-lg bg-[#F5F6F8]">
                <h2 className="text-20px font-semibold mb-0">Lot Details:</h2>
              </div>
              <div>
                {lotItems?.map((item, index) => (
                  <div
                    key={index}
                    className="flex p-4 hover:bg-gray-100 transition-colors items-center justify-between border bottom-1 py-6"
                  >
                    <div className="flex items-center">
                      {/* <img
                        src={item?.image_url || noImage}
                        className="w-16"
                        alt="Item"
                      /> */}
                      <span className="ml-4">
                        <p className="font-semibold text-lg">{item?.name}</p>
                        <p className="text-sm text-gray-500">{item?.details}</p>
                      </span>
                    </div>
                    <div className="space-y-2">
                      <div className="flex items-center gap-1">
                        <div className="w-2 h-2 bg-blue-400 rounded-full"></div>
                        <p className="text-16px text-gray-600">
                          Quantity: {item?.quantity}
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            {/* Right Column - Auction Details */}
            <div className="bg-white p-0 rounded-lg border border-gray-200 h-fit">
              <div className="p-4 rounded-t-lg bg-[#F5F6F8]">
                <h2 className="text-20px font-semibold mb-0">
                  Auction Details:
                </h2>
              </div>
              <div className="flex justify-between p-6 scale-100">
                <div className="text-3xl font-semibold text-brandGreen">
                  <Countdown endDatetime={data?.data?.end_datetime} />
                </div>
                <StatusButton
                  status={data?.data?.is_closed ? "Closed" : "Active"}
                />
              </div>
              <div className="px-4 mb-4">
                <p className="text-sm text-gray-500">
                  Ending on:{" "}
                  <span className="text-red-500">
                    {formatDateAndTime(data?.data?.end_datetime)}
                  </span>
                </p>
              </div>
              <div className="p-8 bg-[#F1FCEE] mx-4 rounded-md my-8 text-center border border-[#D4F6CB]">
                <span className="">Current Highest Bid</span>
                <p className="font-bold text-[38px] py-4 text-brandGreen">
                  ₦{highestBid}
                </p>
                <div className="space-x-4 py-3">
                  <span className="">Total Bidders: 24</span>
                  <span className="">Total Bids: {totalBids}</span>
                </div>
              </div>
              <div className="flex flex-wrap justify-center gap-4 my-4">
                <div className="flex items-center gap-2 bg-[#EEF7E7] px-3 py-2 rounded-full shadow-sm hover:shadow-md transition-shadow">
                  <span className="text-sm text-gray-700">Reserve Price:</span>
                  <span className="text-sm font-semibold">
                    ₦{formatNumber(lotItems?.reserve_amount)}
                  </span>
                </div>
                <div className="flex items-center gap-2 bg-[#EEF7E7] px-3 py-2 rounded-full shadow-sm hover:shadow-md transition-shadow">
                  <span className="text-sm text-gray-700">Starting Price:</span>
                  <span className=" text-sm font-semibold">
                    ₦{formatNumber(data?.data?.starting_amount)}
                  </span>
                </div>
              </div>
            </div>

            {/* Right Column - Live Bid History */}
          </div>
          {/* Live Bid History Card */}
          <div className="bg-white p-0 rounded-lg border border-gray-200 h-fit mb-6">
            <div className="p-4 rounded-t-lg bg-[#F5F6F8]">
              <h2 className="text-20px font-semibold mb-0">Live Bid History</h2>
            </div>
            <div className="px-36 mt-4">
              <div className="overflow-y-auto max-h-[500px] rounded-xl">
                <table className="w-full">
                  <thead className="bg-[#F5F6F8]">
                    <tr className="text-gray-600">
                      <th className="text-left p-4 rounded-tl-lg text-16px semi-bold">
                        Bidder
                      </th>
                      <th className="text-right p-4 text-16px semi-bold">
                        Amount
                      </th>
                      <th className="text-right p-4 rounded-tr-lg text-[16px] semi-bold">
                        Date Time
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y">
                    {bidHistory?.map((bid, index) => (
                      <tr
                        key={index}
                        className={`
                        ${
                          index === 0
                            ? "bg-brandGreen text-white"
                            : "hover:bg-gray-50"
                        } 
                        transition-colors
                      `}
                      >
                        <td className="p-6 font-medium flex gap-2 items-center">
                          {index < 3 ? (
                            <img
                              src={
                                index === 0
                                  ? firstIcon
                                  : index === 1
                                  ? secondIcon
                                  : thirdIcon
                              }
                              alt={`${index + 1}st place`}
                              className="w-10 h-10"
                            />
                          ) : (
                            <span className="w-5 text-gray-500 text-sm">
                              {index + 1}
                            </span>
                          )}
                          <img
                            src={bid?.created_by?.profile_image_url || noImage}
                            className="w-8 h-8 rounded-full object-cover aspect-square"
                            alt={"Profile"}
                          />
                          {bid?.created_by?.first_name +
                            " " +
                            bid?.created_by?.last_name}
                        </td>
                        <td className="text-right p-4 font-semibold">
                          {bid.amount}
                        </td>
                        <td className="text-right p-4">
                          {formatDateTimeAndSeconds(bid?.created_datetime)}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          {/* Bottom Grid for Lot Details and Bid History */}
        </div>
      </div>
    </BaseDashboardNavigation>
  );
};

export default AuctionDetails;
