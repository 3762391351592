import React, { useState } from "react";

import PaginationRounded from "../../../generalComponents/Pagination";
import TablesComponent from "../../../generalComponents/TablesComponent";

import { useNavigate } from "react-router-dom";
import useGetBorrowRequestsManager from "../controllers/getBorrowRequestsController";
import StatusButton from "../../../generalComponents/StatusButton";
import { ApproveBorrowManager } from "../controllers/approveBorrowController";
import { RejectBorrowManager } from "../controllers/rejectBorrowController";
import useDebounce from "../../../utils/UseDebounce";
import { calculatePaginationRange } from "../../../utils/calculatePaginationRange";
import SearchField from "../../../generalComponents/SearchField";

const BorrowRequests = ({ details }) => {
  const [selected, setSelected] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedRows, setSelectedRows] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const debouncedSearchValue = useDebounce(`&search=${searchValue}`, 1000);
  const navigate = useNavigate();

  const { data, isLoading } = useGetBorrowRequestsManager({
    is_lender_client: true,
    enabled: true,
    searchQuery: debouncedSearchValue,
    page: currentPage,
  });

  const { approveBorrow } = ApproveBorrowManager({ id: selected });
  const { rejectBorrow } = RejectBorrowManager({ id: selected });

  const { startItem, endItem } = calculatePaginationRange(
    data?.data?.pagination
  );

  const headers = [
    "S/N",
    "Item Name",
    "Quantity",
    "Request Date",
    "Status",
    "Action",
  ];

  const options = [
    {
      label: "View Request",
      permissions: ["view_stockborrow"],
    },
    { label: "Accept Request", permissions: ["accept_stockborrow"] },
    { label: "Reject Request", permissions: ["reject_stockborrow"] },
  ];

  const getFormattedValue = (el, index) => {
    return [
      index + 1,
      el?.stock,
      el?.quantity,
      <span>{new Date(el?.created_datetime).toLocaleDateString()}</span>,
      <span>
        <StatusButton status={el?.status} />
      </span>,
    ];
  };

  const handleApproval = async () => {
    approveBorrow();
  };

  const handleRejection = () => {
    rejectBorrow();
  };

  return (
    <div className="mt-2 md:mt-6 flex flex-col w-full gap-4">
      <div className="flex flex-col md:flex-row justify-between items-center">
        <div className="flex items-center gap-3">
          <SearchField
            onChange={(e) => setSearchValue(e.target.value)}
            value={searchValue}
          />{" "}
        </div>
      </div>
      <div className="h-[67vh] w-full relative">
        {
          <TablesComponent
            isLoading={isLoading}
            data={data?.data?.results}
            getFormattedValue={getFormattedValue}
            headers={headers}
            setSelectedRows={setSelectedRows}
            selectedRows={selectedRows}
            toggleRowFunction={() => {}}
            toggleSelectAllFunction={() => {}}
            popUpFunction={(option, index, val) => {
              console.log(option, index, val);
              if (index === 0) {
                navigate(`/borrow/request/${val.id}`, {
                  state: { fromIncomingRequests: true },
                });
              }
              if (index === 1) {
                setSelected(val.id);
                handleApproval();
              }
              if (index === 2) {
                setSelected(val.id);
                handleRejection(val.id);
              }
            }}
            options={options}
            showCheckBox={false}
            // Close popup function
          />
        }
      </div>
      <div className="flex items-center justify-between mt-4">
        <p className="text-14px text-brandBlack">
          {" "}
          {startItem} - {endItem} of {data?.data?.pagination?.count} Items
        </p>
        <PaginationRounded
          defaultPage={data?.data?.pagination?.page}
          count={data?.data?.pagination?.total_pages}
          onChange={(page) => {
            setCurrentPage(page);
          }}
        />
      </div>
    </div>
  );
};
export default BorrowRequests;
