import React from "react";
import CustomButton from "../../../generalComponents/Button";
import InputWithFullBoarder from "../../../generalComponents/InputWithFullBoarder";
import ModalManagement from "../../../generalComponents/ModalManagement";

const LocationsModals = () => {
  return (
    <div>
      {/* ADD LOCATION MODAL */}
      <ModalManagement id={"add_location"} title={"Add Location"}>
        <div className="md:w-[599px] h-auto rounded-[6px] relative bg-white flex flex-col pt-6">
          <div className="flex flex-col md:flex-row md:space-x-2">
            <div className="flex-1">
              <InputWithFullBoarder
                label={"Location Name"}
                placeholder={"Name"}
              />
            </div>
            <div className="flex-1">
              <InputWithFullBoarder
                label={"Parent"}
                placeholder={"Parent"}
              />
            </div>
          </div>
          
          
          <div className="flex justify-end space-x-2 mt-4 w-full">
            <CustomButton
              buttonText={"Cancel"}
              textColor={"#344054"}
              className="bg-white border border-gray-600"
            />
            <CustomButton buttonText={"Save"} />
          </div>
        </div>
      </ModalManagement>


      {/* DELETE USER MODAL */}
      <ModalManagement id={"delete_location"} title={"Delete Location"}>
        <div className="w-[456px] h-auto rounded-[6px] relative bg-white flex flex-col py-3">
          <p className="text=[14px]">
            Are you sure you want to delete this location?
          </p>

          <div className="flex justify-end space-x-2 mt-4 w-full">
            <CustomButton
              buttonText={"Cancel"}
              textColor={"#344054"}
              className="bg-white border border-gray-600"
            />
            <CustomButton buttonText={"Continue"} />
          </div>
        </div>
      </ModalManagement>
    </div>
  );
};

export default LocationsModals;
