import React, { useState } from "react";
import BaseDashboardNavigation from "../../../generalComponents/BaseDashboardNavigation";
import SearchField from "../../../generalComponents/SearchField";
import ButtonWithIcon from "../../../generalComponents/ButtonWithIcon";
import PaginationRounded from "../../../generalComponents/Pagination";
import { useNavigate } from "react-router-dom";
import TablesComponent from "../../../generalComponents/TablesComponent";
import { operatorsData } from "../../../data/operatorsData";
import AvatarWithName from "../../../generalComponents/AvatarWithName";
import PageHeading from "../../../generalComponents/PageHeading";
import CustomDropdown from "../../../generalComponents/CustomDropDown";
import SoldItemsModals from "../components/SoldItemsModals";

const SoldBidItemsPage = () => {
  const [ setCurrentPage] = useState(1);
  const navigate = useNavigate();

  const headers = [
    "S/N",
    "Event Name",
    "Lot",
    "Product",
    "Sold Amount",
    "Won By",
    "Date",
    "Action",
  ];

  const getFormattedValue = () => {
    return [
      "001",
      <AvatarWithName title={"NIPEX Event"} />,
      "NXT Lot",
      "NXT Lot",
      "₦42,0000",
      "Testbidder5",
      "Jan 11, 2024",
    ];
  };

  const options = ["View Certificate", "View Items", "Delete"];

  return (
    <BaseDashboardNavigation>
      <div className="flex flex-col w-full gap-7">
        <PageHeading title={"Sold Bid Items"} />
        <div className="flex md:flex-row flex-col justify-between items-center">
          <div className="flex items-center gap-3">
            <SearchField placeholder={"Search"} />
          </div>
          <div className="flex items-center space-x-[10px] md:my-0 my-3">
            <CustomDropdown placeholder={"Select Action"} />
            <ButtonWithIcon buttonText={"Download Report"} radius={"md"} />
          </div>
        </div>
        <div className="h-[67vh] w-full relative">
          <TablesComponent
            // isLoading={isLoading}
            data={operatorsData}
            getFormattedValue={getFormattedValue}
            headers={headers}
            options={options}
            buttonFunction={(val) => {
              console.log(val);
            }}
            popUpFunction={(option, index, value) => {
              console.log(option, index, value);
              if (index === 0) {
                document.getElementById("certificate").showModal();
              }
              if (index === 1) {
                navigate('/lots/lot/item');
              }
              if (index === 2) {
                document.getElementById("delete_sold_items").showModal();
              }
            }}
            // Close popup function
          />
        </div>
        <div className="flex items-center justify-between mt-4">
          <p className="text-14px text-brandBlack">1-10 of 195 items</p>
          <PaginationRounded
            defaultPage={1}
            count={100}
            onChange={(page) => {
              setCurrentPage(page);
            }}
          />
        </div>
      </div>
      <SoldItemsModals />
    </BaseDashboardNavigation>
  );
};

export default SoldBidItemsPage;
