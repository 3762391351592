import React from "react";
import CustomButton from "../../../generalComponents/Button";
import InputWithFullBoarder from "../../../generalComponents/InputWithFullBoarder";
import ModalManagement from "../../../generalComponents/ModalManagement";

const ProductsModals = () => {
  return (
    <div>
      {/* ADD PRODUCT MODAL */}
      <ModalManagement id={"add_products"} title={"Add Products"}>
        <div className="md:w-[599px] h-auto rounded-[6px] relative bg-white flex flex-col pt-6">
          <div className="flex flex-col md:flex-row md:space-x-2">
            <div className="flex-1">
              <InputWithFullBoarder
                label={"Category"}
                placeholder={"Category"}
              />
            </div>
            <div className="flex-1">
              <InputWithFullBoarder
                label={"Product Name"}
                placeholder={"Product Name"}
              />
            </div>
          </div>
          <div className="flex flex-col md:flex-row md:space-x-2">
            <div className="flex-1">
              <InputWithFullBoarder
                label={"Product Code"}
                placeholder={"Product Code"}
              />
            </div>
            <div className="flex-1">
              <InputWithFullBoarder
                label={"Description"}
                placeholder={"Description"}
              />
            </div>
          </div>
          
          <div className="flex justify-end space-x-2 mt-4 w-full">
            <CustomButton
              buttonText={"Cancel"}
              textColor={"#344054"}
              className="bg-white border border-gray-600"
            />
            <CustomButton buttonText={"Save"} />
          </div>
        </div>
      </ModalManagement>

      {/* ADD PRODUCT CATEGORY MODAL */}
      <ModalManagement id={"add_category"} title={"Add Product Category"}>
        <div className="md:w-[599px] h-auto rounded-[6px] relative bg-white flex flex-col pt-6">
          <div className="flex flex-col md:flex-row md:space-x-2">
            <div className="flex-1">
              <InputWithFullBoarder
                label={"Name"}
                placeholder={"Name"}
              />
            </div>
            <div className="flex-1">
              <InputWithFullBoarder
                label={"Parent"}
                placeholder={"Parent"}
              />
            </div>
          </div>
          <div className="flex flex-col md:flex-row md:space-x-2">
            <div className="flex-1">
              <InputWithFullBoarder
                label={"Minimum Information Requirement"}
                placeholder={"Select"}
              />
            </div>
            <div className="flex-1">
              <InputWithFullBoarder
                label={"Category Image"}
                placeholder={"Image"}
              />
            </div>
          </div>
          <div className="flex flex-col md:flex-row md:space-x-2">
            <div className="flex-1">
              <InputWithFullBoarder
                label={"Description"}
                placeholder={"Description"}
              />
            </div>
            
          </div>
          
          <div className="flex justify-end space-x-2 mt-4 w-full">
            <CustomButton
              buttonText={"Cancel"}
              textColor={"#344054"}
              className="bg-white border border-gray-600"
            />
            <CustomButton buttonText={"Save"} />
          </div>
        </div>
      </ModalManagement>


      {/* DELETE USER MODAL */}
      <ModalManagement id={"delete_product"} title={"Delete Product"}>
        <div className="w-[456px] h-auto rounded-[6px] relative bg-white flex flex-col py-3">
          <p className="text=[14px]">
            Are you sure you want to delete this product?
          </p>

          <div className="flex justify-end space-x-2 mt-4 w-full">
            <CustomButton
              buttonText={"Cancel"}
              textColor={"#344054"}
              className="bg-white border border-gray-600"
            />
            <CustomButton buttonText={"Continue"} />
          </div>
        </div>
      </ModalManagement>

      {/* DELETE USER MODAL */}
      <ModalManagement id={"delete_category"} title={"Delete Category"}>
        <div className="w-[456px] h-auto rounded-[6px] relative bg-white flex flex-col py-3">
          <p className="text=[14px]">
            Are you sure you want to delete this category?
          </p>

          <div className="flex justify-end space-x-2 mt-4 w-full">
            <CustomButton
              buttonText={"Cancel"}
              textColor={"#344054"}
              className="bg-white border border-gray-600"
            />
            <CustomButton buttonText={"Continue"} />
          </div>
        </div>
      </ModalManagement>
    </div>
  );
};

export default ProductsModals;
